import { useState } from "react";
import { ComputedDatum, ResponsivePie } from "@nivo/pie";
import { useTranslation } from "react-i18next";
import { byStateTheme, useNivoTheme } from "../../../../common/theme";
import VehiclesModal from "./VehiclesModal";
import { ApproveState } from "../../../../redux/api/VehiclesApi";
import { CountByState } from "../../../../common/pagination";
import { Box } from "@mui/material";

interface VehicleByStateChartDatum {
  id: string;
  label: string;
  value: number;
  state: ApproveState;
}

const VehiclesByState = (props: { data?: CountByState }) => {
  const { t } = useTranslation();
  const titleActive = t("dashboard.vehicles.approval.active");
  const titleDeclined = t("dashboard.vehicles.approval.declined");
  const titlePending = t("dashboard.vehicles.approval.pending");
  const countActive = props.data?.active ?? 0;
  const countDeclined = props.data?.declined ?? 0;
  const countPending = props.data?.pending ?? 0;

  const chartData: VehicleByStateChartDatum[] = [
    {
      id: titleActive,
      label: `${titleActive} (${countActive})`,
      value: countActive,
      state: ApproveState.ACTIVE,
    },
    {
      id: titleDeclined,
      label: `${titleDeclined} (${countDeclined})`,
      value: countDeclined,
      state: ApproveState.DECLINED,
    },
    {
      id: titlePending,
      label: `${titlePending} (${countPending})`,
      value: countPending,
      state: ApproveState.PENDING,
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState<ApproveState>(
    ApproveState.PENDING
  );

  const openModal = (id: String) => {
    const state = chartData.find((value) => value.id === id)!!.state;
    setModalState(state);
    setModalOpen(true);
  };

  return (
    <>
      <Box textAlign={"center"}>{t("dashboard.vehicles.approval.title")}</Box>
      <ResponsivePie
        margin={{ top: 10, right: 0, bottom: 80, left: 0 }}
        data={chartData}
        animate={true}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        activeInnerRadiusOffset={6}
        layers={["arcs", "legends"]}
        colors={byStateTheme.palette.pie}
        theme={useNivoTheme()}
        onClick={(datum: ComputedDatum<VehicleByStateChartDatum>) => {
          openModal(datum.data.id);
        }}
        legends={[
          {
            anchor: "bottom-left",
            direction: "column",
            symbolShape: "circle",
            itemWidth: 100,
            itemHeight: 20,
            translateY: 70,
            translateX: 30,
            onClick: (d) => {
              openModal(d.id.toString());
            },
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 0.5,
                },
              },
            ],
          },
        ]}
      />
      {modalOpen && (
        <VehiclesModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          subTitle={chartData.find((value) => value.state === modalState)!!.id}
          filterField="state"
          filterValue={modalState}
        />
      )}
    </>
  );
};

export default VehiclesByState;
