import { GridColumnVisibilityModel } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";

export const reducedAssociatesVisibilityModel: GridColumnVisibilityModel = {
  name: false,
  activated: false,
  status: false,
  deny: false,
};

export const reducedVehiclesVisibilityModel: GridColumnVisibilityModel = {
  detail: false,
  licensePlate: false,
  state: false,
  status: false,
  deny: false,
};

export const reducedApprovalVehiclesVisibilityModel: GridColumnVisibilityModel =
  {
    organizationName: false,
  };

export const reducedApprovalHistoryVisibilityModel: GridColumnVisibilityModel =
  {
    organizationName: false,
    technicalCardLink: false,
    reason: false,
    createdAt: false,
  };

export const shouldReturnReducedModel = (
  isSmall: boolean,
  displayAll: boolean
) => {
  return isSmall && !displayAll;
};
