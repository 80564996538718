import { getI18n } from "react-i18next";
import { FC } from "react";
import { Typography } from "@mui/material";
import { calculateTrend } from "../calculateTrend";

interface TrendComponentProps {
  current: number;
  previous: number;
}

const TrendComponent: FC<TrendComponentProps> = ({ current, previous }) => {
  const i18n = getI18n();
  const formatter = new Intl.NumberFormat(i18n.language, {
    style: "decimal",
    signDisplay: "always",
  });
  const trend = calculateTrend(current, previous);
  const color = trend > 0 ? "green" : trend < 0 ? "red" : "primary";

  return (
    <Typography display="inline">
      {" ("}
      <Typography component="span" sx={{ color: color }}>
        {formatter.format(trend)}
      </Typography>
      {"%)"}
    </Typography>
  );
};

export default TrendComponent;
