import {
  GridApi,
  gridColumnDefinitionsSelector,
  gridVisibleColumnDefinitionsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Button, Fade, Tooltip } from "@mui/material";
import { ViewColumn as ViewColumnIcon } from "@mui/icons-material";
import React from "react";

const ResetColumnVisibilityButton = () => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext<GridApi>();

  const displayButton =
    gridVisibleColumnDefinitionsSelector(apiRef).filter(
      // Consider columns which provide access to the column menu
      (column) => column.disableColumnMenu !== true
    ).length === 0;

  const onClick = () => {
    const columns = gridColumnDefinitionsSelector(apiRef).map((column) =>
      column.hideable ? { field: column.field, hide: false } : column
    );
    apiRef.current.updateColumns(columns);
  };

  return displayButton ? (
    <Fade in>
      <Tooltip title={t("table.show.columns.tooltip")}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onClick}
          startIcon={<ViewColumnIcon />}
        >
          {t("table.show.columns")}
        </Button>
      </Tooltip>
    </Fade>
  ) : (
    <></>
  );
};

export default ResetColumnVisibilityButton;
