import { SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, AutocompleteValue } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AssociateGroup,
  useGetAssociateGroupsQuery,
} from "../../../../redux/api/AssociateGroupsApi";
import { useSelectedOrganization } from "../../../../hooks/useSelectedOrganization";
import { TableTextField } from "../../../../common/styles";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import LinkOffIcon from "@mui/icons-material/LinkOff";

function AssociateGroupSelector(params: GridRenderEditCellParams) {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();
  const { data: groups } = useGetAssociateGroupsQuery({
    organizationId: organization.publicId,
    sortOrder: "asc",
    sortField: "name",
  });

  const { id, value: valueProp, field } = params;
  const [value, setValue] = useState(valueProp);
  const apiRef = useGridApiContext();

  const handleChange = (
    _: SyntheticEvent,
    newValue: AutocompleteValue<AssociateGroup, undefined, undefined, undefined>
  ) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    setValue(newValue);
  };

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  return (
    <Autocomplete
      blurOnSelect
      fullWidth
      value={value || null}
      options={groups?.content || []}
      getOptionLabel={(option) => option?.name || ""}
      isOptionEqualToValue={(option, value) => {
        return option.publicId === value.publicId;
      }}
      onChange={handleChange}
      renderInput={(params) => <TableTextField {...params} />}
      clearText={t("associates.group.unassign")}
      noOptionsText={t("autocomplete.nooptions")}
      openText={t("autocomplete.open")}
      closeText={t("autocomplete.close")}
      clearIcon={<LinkOffIcon fontSize="small" />}
    />
  );
}

export default AssociateGroupSelector;
