import {
  Box,
  DialogContentText,
  InputAdornment,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreateOrganizationMutation } from "../../redux/api/OrganizationApi";
import { validateSpecialCharacters } from "../validateSpecialCharacters";
import { validateEmailDomain } from "../validateEmailDomain";

interface CreateOrganizationFormProps {
  onClose?: () => void;
}

interface FormValues {
  name: string;
  domain: string;
}

const CreateOrganizationForm: FC<CreateOrganizationFormProps> = ({
  onClose: onCloseProp,
}) => {
  const { t } = useTranslation();

  const router = useNavigate();

  const [createOrganization] = useCreateOrganizationMutation();

  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<FormValues>();

  const onClose = () => {
    reset();
    if (onCloseProp) {
      onCloseProp();
    }
  };

  const onSubmit = async (data: FormValues) => {
    const request = {
      organizationName: data.name.trim(),
      emailDomain: data.domain.trim(),
    };

    try {
      await createOrganization(request).unwrap();
      onClose();
      enqueueSnackbar(t("menu.new.organization.success"), {
        variant: "success",
      });
      router("/dashboard");
    } catch (error: any) {
      handleError(error);
    }
  };

  const handleError = (error: any) => {
    if (error.status === 409) {
      if (error.data.message === "conflict.organization.name.exists") {
        setError("name", {
          type: "custom",
          message: t(`${error.data.message}`),
        });
      } else if (error.data.message === "conflict.organization.domain.exists") {
        setError("domain", {
          type: "custom",
          message: t(`${error.data.message}`),
        });
      }
    } else {
      enqueueSnackbar(t("menu.new.organization.error.other"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <DialogTitle>{t("menu.new.organization")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("menu.new.organization.description")}
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          margin="normal"
          required
          label={t("organization.name")}
          type="text"
          multiline
          error={!!errors.name}
          helperText={errors.name?.message}
          {...register("name", {
            validate: validateSpecialCharacters("name"),
            required: { value: true, message: t("validation.name.blank") },
          })}
          inputProps={{ maxLength: 255 }}
        />
        <TextField
          fullWidth
          margin="normal"
          required
          label={t("organization.domain")}
          type="text"
          error={!!errors.domain}
          helperText={errors.domain?.message}
          {...register("domain", {
            validate: validateEmailDomain,
            required: { value: true, message: t("validation.domain.blank") },
          })}
          inputProps={{ maxLength: 63 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" disableTypography>
                @
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          size="medium"
          color="secondary"
        >
          {t("cancel")}
        </Button>
        <Button variant="contained" size="medium" color="primary" type="submit">
          {t("create")}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default CreateOrganizationForm;
