import React, { Dispatch, FC, SetStateAction } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UploadButton from "./UploadButton";
import DownloadAssociatesButton from "./DownloadAssociatesButton";
import ExpandContainerButton from "../../../common/small-screen/ExpandContainerButton";
import AssociatesTableToggle from "../AssociatesTableToggle";
import { TableActions } from "../../../common/styles";
import ResetColumnVisibilityButton from "../../table/ResetColumnVisibilityButton";

interface ToolbarProps {
  setFilterButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  setDisplayAll: Dispatch<SetStateAction<boolean>>;
  displayAll: boolean;
}

const Toolbar: FC<ToolbarProps> = ({
  setFilterButtonEl,
  displayAll,
  setDisplayAll,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Box>
        <AssociatesTableToggle />
      </Box>
      <TableActions>
        <ResetColumnVisibilityButton />
        <GridToolbarFilterButton ref={setFilterButtonEl} />
        <UploadButton />
        <DownloadAssociatesButton />
        <Link to="/associates/create">
          <Tooltip title={t("associates.create.tooltip")}>
            <Button data-cy="create.associate" variant="contained">
              {t("associates.create")}
            </Button>
          </Tooltip>
        </Link>
        <ExpandContainerButton
          setDisplayAll={setDisplayAll}
          displayAll={displayAll}
        />
      </TableActions>
    </Wrapper>
  );
};

const Wrapper = styled(GridToolbarContainer)(({ theme }) => ({
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    // Always position the buttons to the top (especially if wrapping)
    alignItems: "flex-start",
  },
}));

export default Toolbar;
