import { Dispatch, FC, SetStateAction } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { vehiclesGroupsRoute } from "../../../navbar/routes";
import UploadButton from "./UploadButton";
import { TableActions } from "../../../../common/styles";
import VehiclesTableToggle from "../../../vehicles/VehiclesTableToggle";
import ResetColumnVisibilityButton from "../../../table/ResetColumnVisibilityButton";

interface ToolbarProps {
  setFilterButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}

const Toolbar: FC<ToolbarProps> = ({ setFilterButtonEl }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Box>
        <VehiclesTableToggle />
      </Box>
      <TableActions>
        <ResetColumnVisibilityButton />
        <GridToolbarFilterButton ref={setFilterButtonEl} />
        <UploadButton />
        <Link to={`${vehiclesGroupsRoute.path}/create`}>
          <Tooltip title={t("vehicles.groups.create.tooltip")}>
            <Button data-cy="create.group" variant="contained">
              {t("vehicles.groups.create")}
            </Button>
          </Tooltip>
        </Link>
      </TableActions>
    </Wrapper>
  );
};

const Wrapper = styled(GridToolbarContainer)(({ theme }) => ({
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  marginBottom: "1rem",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));

export default Toolbar;
