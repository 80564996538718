import { ExternalAuthorizationReport } from "../../../../redux/api/ReportsApi";
import Grid from "@mui/material/Grid";
import React from "react";
import TrendComponent from "../TrendComponent";
import { useTranslation } from "react-i18next";
import GridItemLabel from "../GridItemLabel";

const AuthorizationReportComponent = (props: {
  data: ExternalAuthorizationReport;
}) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.authorization.total")}
      />
      <Grid item xs={6}>
        {props.data.total}
        {!!props.data.previousTotal && (
          <TrendComponent
            current={props.data.total}
            previous={props.data.previousTotal}
          />
        )}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.authorization.unique")}
      />
      <Grid item xs={6}>
        {props.data.uniqueTotal}
        {!!props.data.previousUniqueTotal && (
          <TrendComponent
            current={props.data.uniqueTotal}
            previous={props.data.previousUniqueTotal}
          />
        )}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.authorization.authorized")}
      />
      <Grid item xs={6}>
        {props.data.authorized}
        {!!props.data.previousAuthorized && (
          <TrendComponent
            current={props.data.authorized}
            previous={props.data.previousAuthorized}
          />
        )}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.authorization.rejected")}
      />
      <Grid item xs={6}>
        {props.data.rejected}
        {!!props.data.previousRejected && (
          <TrendComponent
            current={props.data.rejected}
            previous={props.data.previousRejected}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AuthorizationReportComponent;
