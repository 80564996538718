import { baseApi, OrganizationRequest } from "./baseApi";
import { Page, PageRequest, toPageSearchParams } from "../../common/pagination";

export interface OrganizationReportListing {
  id: string;
  /** Report creation time. Timestamp format. */
  created: number;
  period: ReportPeriod;
}

export interface OrganizationReportDetail {
  id: string;
  /** Report creation time. Timestamp format. */
  created: number;
  period: ReportPeriod;
  associates: AssociatesReport;
  vehicles: VehiclesReport;
  externalAuthorization: ExternalAuthorizationReport;
}

export enum ReportPeriod {
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  WEEK = "WEEK",
  DAY = "DAY",
}

export interface AssociatesReport {
  total: number;
  previousTotal?: number;
  created: number;
  deleted: number;
  automaticallyCreated: number;
  previousAutomaticallyCreated?: number;
  automaticallyCreated5days: number;
  automaticallyCreated30days: number;
  active: number;
  denied: number;
  allowed: number;
  previousAllowed?: number;
}

export interface VehiclesReport {
  total: number;
  previousTotal?: number;
  created: number;
  deleted: number;
  pendingNoTC: number;
  pendingNoTC5Days: number;
  pendingNoTC30Days: number;
  pendingWithTC: number;
  declined: number;
  active: number;
  denied: number;
  allowed: number;
  previousAllowed?: number;
}

export interface ExternalAuthorizationReport {
  total: number;
  previousTotal?: number;
  uniqueTotal: number;
  previousUniqueTotal?: number;
  rejected: number;
  previousRejected?: number;
  authorized: number;
  previousAuthorized?: number;
}

interface OrganizationReportIdRequest extends OrganizationRequest {
  id: string;
}

export const reportsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationReports: builder.query<
      Page<OrganizationReportListing[]>,
      PageRequest<OrganizationReportListing>
    >({
      query: (request) => {
        return `/reports/organization/${
          request.organizationId
        }/report?${toPageSearchParams(request)}`;
      },
      providesTags: (_) => ["OrganizationReport"],
    }),
    getOrganizationReportDetail: builder.query<
      OrganizationReportDetail,
      OrganizationReportIdRequest
    >({
      query: ({ id, organizationId }) =>
        `/reports/organization/${organizationId}/report/${id}`,
      providesTags: (_) => ["OrganizationReport"],
    }),
  }),
});

export const {
  useGetOrganizationReportsQuery,
  useGetOrganizationReportDetailQuery,
  useLazyGetOrganizationReportDetailQuery,
} = reportsApi;
