import { Tooltip } from "@mui/material";
import { ReactElement } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TooltipTable } from "../../common/styles";
import * as React from "react";

const columnNames = ["vin", "description", "licensePlate"] as const;
type ColumnName = typeof columnNames[number];
const columns: Record<ColumnName, { translation: string; required: boolean }> =
  {
    vin: {
      translation: "vin",
      required: true,
    },
    description: {
      translation: "vehicles.detail",
      required: false,
    },
    licensePlate: {
      translation: "vehicles.licensePlate",
      required: false,
    },
  };

interface UploadTooltipProps {
  children: ReactElement;
}

const UploadTooltip: FC<UploadTooltipProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        <>
          {t("vehicles.upload.tooltip")}
          <TooltipTable>
            <tbody>
              <tr>
                {columnNames.map((name) => (
                  <th key={name}>
                    {name}
                    {columns[name].required ? "*" : ""}
                  </th>
                ))}
              </tr>
              <tr>
                {columnNames.map((name) => (
                  <td key={name}>{t(columns[name].translation)}</td>
                ))}
              </tr>
            </tbody>
          </TooltipTable>
        </>
      }
      placement="top"
    >
      {children}
    </Tooltip>
  );
};

export default UploadTooltip;
