import {
  createSlice,
  PayloadAction,
  ListenerEffect,
  Action,
  isAllOf,
  AnyAction,
  isAnyOf,
} from "@reduxjs/toolkit";
import { userApi } from "../api/UserApi";
import { organizationApi } from "../api/OrganizationApi";
import { organizationSettingsApi } from "../api/OrganizationSettingsApi";
import { vehiclesApi } from "../api/VehiclesApi";
import { associatesApi } from "../api/AssociatesApi";
import { AppDispatch, AppStartListening, RootState } from "../store";
import { CheckAdminResponse, adminsApi } from "../api/AdminsApi";
import {
  allRoles,
  isTechnicalUser,
  Role,
} from "../../components/navbar/routes";
import { statisticsApi } from "../api/StatisticsApi";
import { vehicleApprovalsApi } from "../api/VehicleApprovalApi";
import { organizationBrandingApi } from "../api/OrganizationBrandingApi";

export type Identity = "pending" | "verified" | "unverified";

export interface AuthState {
  identity?: Identity;
  technicalUser?: boolean;
  client: {
    initialized: boolean;
    authenticated: boolean;
    error?: boolean;
    errorDescription?: string;
  };
  role: { selected?: Role; granted: Role[]; preferencesLoaded?: boolean };
}

const emptyState: AuthState = {
  client: {
    initialized: false,
    authenticated: false,
  },
  role: {
    granted: [],
  },
};

const loadInitialState = (): AuthState => ({
  ...emptyState,
  role: {
    granted: [],
  },
});

export const getValidatedRole = (
  grantedRoles: string[] = [],
  currentRole?: string
) => {
  // Granted role by IdP
  const grantedRole = grantedRoles.find((r) => r === currentRole);
  // Role matches with the local role dictionary
  return allRoles.find((r) => r === grantedRole);
};

const getValidRoles = (grantedRoles: string[] = []) =>
  allRoles.filter((role: string) => grantedRoles.includes(role));

const resetApis = () => {
  adminsApi.util.resetApiState();
  associatesApi.util.resetApiState();
  organizationApi.util.resetApiState();
  organizationSettingsApi.util.resetApiState();
  organizationBrandingApi.util.resetApiState();
  statisticsApi.util.resetApiState();
  userApi.util.resetApiState();
  vehicleApprovalsApi.util.resetApiState();
  vehiclesApi.util.resetApiState();
};

const authSlice = createSlice({
  name: "auth",
  initialState: loadInitialState(),
  reducers: {
    clientStateChange: (
      state,
      {
        payload: { initialized, authenticated },
      }: PayloadAction<{
        initialized: boolean;
        authenticated: boolean;
      }>
    ) => {
      state.client.initialized = initialized;
      state.client.authenticated = authenticated;
    },
    clientReady: (
      state,
      { payload: grantedRoles }: PayloadAction<string[]>
    ) => {
      const validRole = getValidatedRole(grantedRoles, state.role.selected);
      const technicalUser = isTechnicalUser(validRole);
      state.client.error = undefined;
      state.client.initialized = true;
      state.client.error = false;
      state.identity = technicalUser ? "verified" : "pending";
      state.technicalUser = technicalUser;
      state.role.granted = getValidRoles(grantedRoles);
      if (state.role.granted.length === 1) {
        state.role.selected = state.role.granted[0];
      } else {
        state.role.selected = validRole;
      }
      return state;
    },
    clientError: (state, action: PayloadAction<string | undefined>) => {
      state.client.initialized = false;
      state.client.error = true;
      state.client.errorDescription = action.payload;
      state.identity = "unverified";
      return state;
    },
    resetAuth: () => {
      resetApis();
      return emptyState;
    },
    authSuccess: (
      state,
      { payload: grantedRoles }: PayloadAction<string[]>
    ) => {
      const validRole = getValidatedRole(grantedRoles, state.role.selected);
      const technicalUser = isTechnicalUser(validRole);
      state.technicalUser = technicalUser;
      if (technicalUser) {
        state.identity = "verified";
      }
      state.role.granted = getValidRoles(grantedRoles);
      if (state.role.granted.length === 1) {
        state.role.selected = state.role.granted[0];
      } else {
        state.role.selected = validRole;
      }
    },
    selectRole: (
      state,
      { payload: { role } }: PayloadAction<{ role: Role }>
    ) => {
      const technicalUser = isTechnicalUser(role);
      state.technicalUser = technicalUser;
      if (technicalUser) {
        state.identity = "verified";
      }

      state.role.selected = role;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        adminsApi.endpoints.checkAdminExistence.matchFulfilled,
        (state, { payload }) => {
          state.identity = payload.exists ? "verified" : "unverified";
        }
      )
      .addMatcher(
        organizationApi.endpoints.createOrganization.matchFulfilled,
        (state) => {
          state.identity = "verified";
        }
      )
      .addMatcher(
        userApi.endpoints.getUser.matchFulfilled,
        (state, { payload: { preferences } }) => {
          if (preferences?.login?.enabled) {
            const role = getValidatedRole(
              state.role.granted,
              preferences?.login?.role
            );
            if (role) {
              state.role.selected = role;
            }
          }
          state.role.preferencesLoaded = true;
        }
      );
  },
});

function matchesIdentityExists() {
  return (action: any): action is PayloadAction<CheckAdminResponse> =>
    action.payload.exists;
}

const isCheckIdentity = isAnyOf(
  isAllOf(
    adminsApi.endpoints.checkAdminExistence.matchFulfilled,
    matchesIdentityExists()
  ),
  organizationApi.endpoints.createOrganization.matchFulfilled
);

const checkIdentityFulfilledEffect: ListenerEffect<
  Action,
  RootState,
  AppDispatch
> = (_, listenerApi) => {
  listenerApi.dispatch(adminsApi.endpoints.checkAdminExistence.initiate());
};

const identityCheckedEffect: ListenerEffect<Action, RootState, AppDispatch> = (
  _,
  listenerApi
) => {
  listenerApi.dispatch(userApi.endpoints.getUser.initiate());
  listenerApi.dispatch(adminsApi.endpoints.getIntroState.initiate());
};

const isNonTechnicalUserRoleSelected = (
  action: AnyAction,
  currentState: RootState
) => {
  const successfulAuthentication =
    action.type === authSuccess.type && currentState.auth.role !== undefined;
  const successfulRoleSelection = action.type === selectRole.type;
  const actionMatch = successfulAuthentication || successfulRoleSelection;
  return actionMatch && !isTechnicalUser(currentState.auth.role.selected);
};

export const addAuthListeners = (startListening: AppStartListening) => {
  startListening({
    predicate: isNonTechnicalUserRoleSelected,
    effect: checkIdentityFulfilledEffect,
  });
  startListening({
    matcher: isCheckIdentity,
    effect: identityCheckedEffect,
  });
};

export default authSlice;
export const {
  resetAuth,
  clientReady,
  clientError,
  authSuccess,
  selectRole,
  clientStateChange,
} = authSlice.actions;
