import EditIcon from "@mui/icons-material/Edit";
import { Dialog, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import { CloseModalIconButton } from "../../CloseModalIconButton";
import RenameDialogContent from "./RenameForm";

const RenameOrganization: FC = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();

  const [renameModalOpen, setRenameModalOpen] = useState(false);

  const onClose = () => {
    setRenameModalOpen(false);
  };

  const onRenameClick = () => {
    if (organization) {
      setRenameModalOpen(true);
    }
  };

  return (
    <>
      <Tooltip title={t("settings.organization.rename.title")}>
        <IconButton onClick={onRenameClick}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      {organization && (
        <Dialog
          open={renameModalOpen}
          onClose={onClose}
          maxWidth="xs"
          fullWidth
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            {t("settings.organization.rename.title")}
            <CloseModalIconButton onClick={onClose} />
          </DialogTitle>
          <RenameDialogContent
            defaultOrganizationName={organization.name}
            onClose={onClose}
          />
        </Dialog>
      )}
    </>
  );
};

export default RenameOrganization;
