import { FC, MouseEvent, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import {
  useUpdateVehicleDetailMutation,
  VehicleDetail,
} from "../../../redux/api/VehiclesApi";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { getTechnicalCard } from "../../../redux/api/VehiclesApi";
import { downloadBlob, open as openFnc } from "../../../redux/api/util";
import { Attachment as AttachmentIcon } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { validateSpecialCharacters } from "../../validateSpecialCharacters";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";

interface VehicleInfoProps {
  data: VehicleDetail;
}

interface FormValues {
  licensePlate: string;
  description: string;
}

const VehicleInfo: FC<VehicleInfoProps> = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateVehicle] = useUpdateVehicleDetailMutation();
  const organization = useSelectedOrganization();

  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [downloading, setDownloading] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: data,
  });

  const onMenuClick = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onEditClick = () => {
    setEdit(true);
    onMenuClose();
  };

  const onCancelEdit = () => {
    reset({ licensePlate: data.licensePlate, description: data.description });
    setEdit(false);
  };

  const onSubmit = async (values: FormValues) => {
    const request = {
      publicId: data.publicId,
      licensePlate: values.licensePlate.trim(),
      description: values.description.trim(),
    };
    try {
      await updateVehicle({
        vehicle: request,
        organizationId: organization.publicId,
      }).unwrap();
      reset(request);
    } catch (error: any) {
      enqueueSnackbar(t("vehicleDetail.update.error"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    } finally {
      setEdit(false);
    }
  };

  const renderLabelAndValue = (
    label: string | undefined,
    value: string | undefined
  ) => (
    <Stack direction="row" spacing={2}>
      <Typography color={"text.secondary"}>{label}:</Typography>
      <Typography color={"text.primary"}>{value}</Typography>
    </Stack>
  );

  const renderVin = () => renderLabelAndValue(t("vehicles.vin"), data.vin);

  const renderLicensePlate = () =>
    renderLabelAndValue(t("vehicles.licensePlate"), getValues("licensePlate"));

  const renderDescription = () =>
    renderLabelAndValue(t("vehicles.description"), getValues("description"));

  const onOpenTechnicalCardClick = (id: string) => (e: MouseEvent) => {
    e.preventDefault();
    if (id && organization) {
      getTechnicalCard(id, organization.publicId)
        .then(openFnc)
        .catch((e) => {
          enqueueSnackbar(t("vehicles.technicalCard.open.error"), {
            variant: "error",
            preventDuplicate: true,
            key: e.status,
          });
        });
    }
  };

  const onDownloadTechnicalCardClick = async () => {
    setDownloading(true);
    if (data.publicId && organization) {
      getTechnicalCard(data.publicId, organization.publicId)
        .then((tc) => downloadBlob(tc, "technical-card"))
        .catch((e) => {
          enqueueSnackbar(t("vehicles.technicalCard.download.error"), {
            variant: "error",
            preventDuplicate: true,
            key: e.status,
          });
        })
        .finally(() => {
          setDownloading(false);
        });
    }
  };

  const renderTechnicalCard = () => (
    <Stack direction="row" spacing={2}>
      <StyledTypography color={"text.secondary"}>
        {t("vehicles.technicalCard")}:
      </StyledTypography>
      <Tooltip title={t("vehicles.technicalCard.open")}>
        <IconButton
          size="medium"
          onClick={onOpenTechnicalCardClick(data.publicId)}
        >
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("vehicles.technicalCard.download")}>
        <IconButton
          size="medium"
          onClick={onDownloadTechnicalCardClick}
          disabled={downloading}
        >
          {downloading ? <CircularProgress size={22.5} /> : <AttachmentIcon />}
        </IconButton>
      </Tooltip>
    </Stack>
  );

  const renderView = () => (
    <StyledCardContent>
      <Stack direction="column" spacing={4}>
        {renderVin()}
        {renderLicensePlate()}
        {renderDescription()}
        {renderTechnicalCard()}
      </Stack>
    </StyledCardContent>
  );

  const renderEditForm = () => (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <StyledCardContent>
        <TextField
          margin="dense"
          fullWidth
          label={t("vehicles.licensePlate")}
          variant="standard"
          size="small"
          error={!!errors.licensePlate}
          helperText={errors.licensePlate?.message}
          {...register("licensePlate", {
            validate: validateSpecialCharacters("licensePlate"),
          })}
          inputProps={{ maxLength: 20 }}
        />
        <TextField
          margin="dense"
          fullWidth
          label={t("vehicles.description")}
          variant="standard"
          size="small"
          error={!!errors.description}
          helperText={errors.description?.message}
          {...register("description", {
            validate: validateSpecialCharacters("description"),
          })}
          inputProps={{ maxLength: 1000 }}
        />
      </StyledCardContent>
      <CardActions>
        <Button size="small" type="submit">
          {t("save")}
        </Button>
        <Button color="secondary" size="small" onClick={onCancelEdit}>
          {t("cancel")}
        </Button>
      </CardActions>
    </Box>
  );

  return (
    <>
      <StyledCard>
        <CardHeader
          action={
            <Tooltip title={t("menu.tooltip")}>
              <IconButton
                aria-label="settings"
                size="small"
                onClick={onMenuClick}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          }
          title={<Typography color="text.secondary">Vehicle detail</Typography>}
        />
        {edit ? renderEditForm() : renderView()}
      </StyledCard>
      <Menu anchorEl={anchorEl} open={open} onClose={onMenuClose}>
        <MenuItem onClick={onEditClick} disabled={edit}>
          {t("edit")}
        </MenuItem>
      </Menu>
    </>
  );
};

const StyledCard = styled((props: any) => <Card elevation={0} {...props} />)(
  () => ({
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  })
);

const StyledCardContent = styled(CardContent)(() => ({
  flex: 1,
}));

const StyledTypography = styled(Typography)(() => ({
  paddingTop: "0.5em",
}));

export default VehicleInfo;
