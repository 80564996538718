import { DialogContentText } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface ActivateModalProps {
  onConfirm: () => void;
  onClose: () => void;
  isModalOpen: boolean;
  itemsCount: number;
}

const ActivateModal: FC<ActivateModalProps> = ({
  onClose,
  onConfirm,
  isModalOpen,
  itemsCount,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        {itemsCount > 1
          ? t("associates.activation.title.multiple")
          : t("associates.activation.title.single")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {itemsCount > 1
            ? t("associates.activation.modal.multiple")
            : t("associates.activation.modal.single")}
        </DialogContentText>
        {itemsCount > 1 && (
          <DialogContentText>
            {`${t("selection.associate.count")}: ${itemsCount}`}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          size="medium"
          color="secondary"
        >
          {t("cancel")}
        </Button>
        <Button
          data-cy="modal.activate.confirmation"
          onClick={onConfirm}
          variant="contained"
          size="medium"
          color="primary"
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivateModal;
