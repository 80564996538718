import Grid from "@mui/material/Grid";
import React from "react";
import { styled } from "@mui/material";

const GridItemLabel = styled(({ label, ...props }: any) => (
  <Grid item {...props}>
    {`${label}:`}
  </Grid>
))(() => ({
  textAlign: "right",
}));

export default GridItemLabel;
