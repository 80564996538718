import { GridSelectionModel } from "@mui/x-data-grid";
import { Page, PageRequest, toPageSearchParams } from "../../common/pagination";
import { baseApi, OrganizationRequest } from "./baseApi";
import { toSearchParams } from "./util";

export interface VehicleGroup {
  publicId: string;
  name: string;
}

export interface VehicleGroupListing extends VehicleGroup {
  itemsCount: number;
}

interface GroupBodyRequest extends OrganizationRequest {
  group: Partial<VehicleGroup>;
}

interface DeleteGroupsRequest extends OrganizationRequest {
  ids: GridSelectionModel;
}

export const groupsVehicleApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getVehicleGroups: builder.query<
      Page<VehicleGroupListing[]>,
      PageRequest<VehicleGroup>
    >({
      query: (request) => {
        return `/vehicle-groups?${toPageSearchParams(request)}`;
      },
      providesTags: (_) => ["VehicleGroup"],
    }),
    createVehicleGroup: builder.mutation<void, GroupBodyRequest>({
      query: ({ group, organizationId }) => ({
        url: `/vehicle-groups?${toSearchParams({ organizationId })}`,
        method: "POST",
        body: group,
      }),
      invalidatesTags: (_) => ["VehicleGroup"],
    }),
    updateVehicleGroup: builder.mutation<void, GroupBodyRequest>({
      query: ({ group: { publicId, ...patch }, organizationId }) => {
        return {
          url: `/vehicle-groups/${publicId}?${toSearchParams({
            organizationId,
          })}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (_) => ["VehicleGroup", "Vehicle"],
    }),
    deleteVehicleGroupsByIds: builder.mutation<void, DeleteGroupsRequest>({
      query: ({ ids, organizationId }) => ({
        url: `/vehicle-groups?${toSearchParams({
          ids,
          organizationId,
        })}`,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["VehicleGroup", "Vehicle"],
    }),
  }),
});

export const {
  useGetVehicleGroupsQuery,
  useCreateVehicleGroupMutation,
  useUpdateVehicleGroupMutation,
  useDeleteVehicleGroupsByIdsMutation,
} = groupsVehicleApi;
