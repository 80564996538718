import {
  Block as BlockIcon,
  Check as CheckIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Chip, LinearProgress } from "@mui/material";
import {
  DataGrid,
  getGridDateOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridCellParams,
  GridColumns,
  GridFilterModel,
  GridRowsProp,
  GridSortModel,
} from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import ExternalLink from "../../../common/external-link/ExternalLink";
import { Operator } from "../../../common/pagination";
import {
  tableComponentProps,
  TableContentWrapper,
} from "../../../common/styles";
import { open } from "../../../redux/api/util";
import {
  ApprovalHistory,
  getApprovalTechnicalCard,
  useGetApprovalHistoryQuery,
} from "../../../redux/api/VehicleApprovalApi";
import Pagination from "../../pagination/Pagination";
import { VehiclesContext } from "../VehiclesComponent";
import Toolbar from "./Toolbar";
import { getTableLocaleText } from "../../table/getTableLocaleText";
import useSmallScreenProperties from "../../../common/small-screen/useSmallScreenProperties";
import { reducedApprovalHistoryVisibilityModel } from "../../../common/small-screen/visibilityModel";
import { formatTimestamp } from "../../formatTimestamp";

interface ApprovalHistoryRow {
  id: string;
  organizationName: string;
  vin: string;
  technicalCardLink: string;
  result: string;
}

export const mapToGridRow = (
  vehicles: ApprovalHistory[]
): GridRowsProp<ApprovalHistoryRow> => {
  return vehicles.map((vehicle) => {
    return {
      id: vehicle.publicId,
      ...vehicle,
    };
  });
};

const ApprovalHistoryComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [
    displayAll,
    setDisplayAll,
    columnVisibilityModel,
    setColumnVisibilityModel,
  ] = useSmallScreenProperties(reducedApprovalHistoryVisibilityModel);

  const [queryOptions, setQueryOptions] =
    useOutletContext<VehiclesContext<ApprovalHistory>>();
  const { data, isFetching, isLoading } =
    useGetApprovalHistoryQuery(queryOptions);

  const onDownloadClick = (id: string) => (e: MouseEvent) => {
    e.preventDefault();
    getApprovalTechnicalCard(id)
      .then(open)
      .catch((error) => {
        enqueueSnackbar(t("verification.download.error"), {
          variant: "error",
          preventDuplicate: true,
          key: error.status,
        });
      });
  };

  const renderTechnicalCardLinkCell = (
    params: GridCellParams<unknown, ApprovalHistoryRow>
  ) => {
    return (
      <ExternalLink
        href="#"
        text={t("vehicle.approval.open")}
        onClick={onDownloadClick(params.row.id)}
      />
    );
  };

  const renderApprovalStatus = ({
    row: { result },
  }: GridCellParams<unknown, ApprovalHistoryRow>) => (
    <Chip
      label={t(`vehicles.approval.${result}`)}
      size="small"
      icon={result ? <CheckIcon /> : <BlockIcon />}
    />
  );

  const columns: GridColumns = [
    {
      field: "vin",
      headerName: t("vehicles.vin"),
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
    },
    {
      field: "organizationName",
      headerName: t("vehicles.approval.organizationName"),
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
    },
    {
      field: "technicalCardLink",
      headerName: t("vehicles.approval.technicalCardLink"),
      flex: 1,
      renderCell: renderTechnicalCardLinkCell,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
      filterable: false,
      sortable: false,
    },
    {
      field: "result",
      headerName: t("vehicles.approval.result"),
      flex: 1,
      valueOptions: ["APPROVED", "DECLINED"],
      filterOperators: getGridSingleSelectOperators().filter(
        (op) => op.value === "is"
      ),
      renderCell: renderApprovalStatus,
      type: "singleSelect",
    },
    {
      field: "reason",
      headerName: t("vehicles.approval.reason"),
      flex: 1,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
    },
    {
      field: "createdAt",
      headerName: t("vehicles.approval.timestamp"),
      flex: 1,
      valueFormatter: (params) => formatTimestamp(params.value),
      filterOperators: getGridDateOperators().filter((op) => op.value === "is"),
      type: "date",
    },
  ];

  const localeText = getTableLocaleText();

  const rows = useMemo(
    () => (data?.content ? mapToGridRow(data.content) : []),
    [data]
  );

  const rowCount = useMemo(() => data?.totalElements || 0, [data]);

  const onPageChange = (page: number) => {
    setQueryOptions({ ...queryOptions, page });
  };

  const onPageSizeChange = (size: number) => {
    setQueryOptions({ ...queryOptions, size });
  };

  const onSortModelChange = (sortModel: GridSortModel) => {
    const sort = sortModel.length === 0 ? undefined : sortModel[0];
    setQueryOptions({
      ...queryOptions,
      sortField: sort?.field as keyof ApprovalHistory,
      sortOrder: sort?.sort,
    });
  };

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    const filter =
      filterModel.items.length === 0 ? undefined : filterModel.items[0];
    setQueryOptions({
      ...queryOptions,
      filterField: filter?.columnField as keyof ApprovalHistory,
      filterOperator: filter?.operatorValue as Operator,
      filterValue: filter?.value,
    });
  };

  const sortModel = useMemo(() => {
    return queryOptions.sortField
      ? [
          {
            sort: queryOptions.sortOrder,
            field: queryOptions.sortField,
          },
        ]
      : [];
  }, [queryOptions.sortField, queryOptions.sortOrder]);

  const filterModel = useMemo(() => {
    const filter = queryOptions.filterField
      ? [
          {
            columnField: queryOptions.filterField,
            operatorValue: queryOptions.filterOperator,
            value: queryOptions.filterValue,
          },
        ]
      : [];
    return {
      items: filter,
    };
  }, [
    queryOptions.filterField,
    queryOptions.filterOperator,
    queryOptions.filterValue,
  ]);

  return (
    <TableContentWrapper displayAll={displayAll}>
      <DataGrid
        localeText={localeText}
        columns={columns}
        rows={rows}
        components={{
          Toolbar,
          LoadingOverlay: LinearProgress,
          Pagination,
          OpenFilterButtonIcon: SearchIcon,
        }}
        rowCount={rowCount}
        page={queryOptions.page}
        onPageChange={onPageChange}
        pageSize={queryOptions.size}
        onPageSizeChange={onPageSizeChange}
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        sortingMode="server"
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        filterMode="server"
        loading={isFetching || isLoading}
        componentsProps={{
          ...tableComponentProps,
          toolbar: { displayAll, setDisplayAll },
        }}
        disableSelectionOnClick
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
      />
    </TableContentWrapper>
  );
};

export default ApprovalHistoryComponent;
