import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import { useUpdateOrganizationMutation } from "../../../redux/api/OrganizationApi";
import { validateEmailDomain } from "../../validateEmailDomain";
import { useDispatch } from "react-redux";
import { updateOrganizationDomain } from "../../../redux/slice/UserSettingsSlice";

interface FormValues {
  domain: string;
}

interface DialogFormProps {
  defaultOrganizationDomain?: string;
  onClose: () => void;
}

const ChangeDomainForm: FC<DialogFormProps> = ({
  defaultOrganizationDomain,
  onClose,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>();
  const [updateOrganization] = useUpdateOrganizationMutation();
  const { enqueueSnackbar } = useSnackbar();
  const organization = useSelectedOrganization();
  const dispatch = useDispatch();

  const onSubmit = async (data: FormValues) => {
    const trimmedDomain = data.domain.trim();
    try {
      await updateOrganization({
        organization: { name: organization.name, emailDomain: trimmedDomain },
        organizationId: organization.publicId,
      }).unwrap();
      onClose();

      dispatch(updateOrganizationDomain(trimmedDomain));
      enqueueSnackbar(t("settings.organization.changedomain.success"), {
        variant: "success",
      });
    } catch (error: any) {
      handleError(error);
    }
  };

  const handleError = (error: any) => {
    if (error.status === 409) {
      setError("domain", {
        type: "custom",
        message: t(`${error.data.message}`),
      });
    } else {
      enqueueSnackbar(t("settings.organization.changedomain.error"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <DialogContent>
        <TextField
          autoFocus
          focused
          fullWidth
          required
          label={t("organization.domain")}
          type="text"
          variant="standard"
          defaultValue={defaultOrganizationDomain}
          multiline
          error={!!errors.domain}
          helperText={errors.domain?.message}
          {...register("domain", {
            validate: validateEmailDomain,
            required: { value: true, message: t("validation.domain.blank") },
          })}
          inputProps={{ maxLength: 63 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" disableTypography>
                @
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          size="medium"
          color="secondary"
        >
          {t("cancel")}
        </Button>
        <Button
          type="submit"
          data-cy="modal.rename.confirmation"
          variant="contained"
          size="medium"
          color="primary"
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default ChangeDomainForm;
