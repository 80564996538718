import { GridSelectionModel } from "@mui/x-data-grid";
import { Page, PageRequest, toPageSearchParams } from "../../common/pagination";
import { baseApi, OrganizationRequest } from "./baseApi";
import { toSearchParams } from "./util";

export interface AssociateGroup {
  publicId: string;
  name: string;
}

export interface AssociateGroupListing extends AssociateGroup {
  itemsCount: number;
}

interface AssociateGroupBodyRequest extends OrganizationRequest {
  group: Partial<AssociateGroup>;
}

interface DeleteGroupsRequest extends OrganizationRequest {
  ids: GridSelectionModel;
}

export const associateGroupsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAssociateGroups: builder.query<
      Page<AssociateGroupListing[]>,
      PageRequest<AssociateGroup>
    >({
      query: (request) => {
        return `/associate-groups?${toPageSearchParams(request)}`;
      },
      providesTags: (_) => ["AssociateGroup"],
    }),
    createAssociateGroup: builder.mutation<void, AssociateGroupBodyRequest>({
      query: ({ group, organizationId }) => ({
        url: `/associate-groups?${toSearchParams({ organizationId })}`,
        method: "POST",
        body: group,
      }),
      invalidatesTags: (_) => ["AssociateGroup"],
    }),
    updateAssociateGroup: builder.mutation<void, AssociateGroupBodyRequest>({
      query: ({ group: { publicId, ...patch }, organizationId }) => {
        return {
          url: `/associate-groups?${publicId}?${toSearchParams({
            organizationId,
          })}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (_) => ["AssociateGroup", "Associate"],
    }),
    deleteAssociateGroupsByIds: builder.mutation<void, DeleteGroupsRequest>({
      query: ({ ids, organizationId }) => ({
        url: `/associate-groups?${toSearchParams({
          ids,
          organizationId,
        })}`,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["AssociateGroup", "Associate"],
    }),
  }),
});

export const {
  useGetAssociateGroupsQuery,
  useCreateAssociateGroupMutation,
  useUpdateAssociateGroupMutation,
  useDeleteAssociateGroupsByIdsMutation,
} = associateGroupsApi;
