import { ResponsivePie } from "@nivo/pie";
import { Box } from "@mui/material";
import {
  byAuthorizationSuccessTheme,
  useNivoTheme,
} from "../../../../common/theme";
import { useTranslation } from "react-i18next";

export interface CountByAuthorizationSuccess {
  authorized: number;
  rejected: number;
}

interface AuthorizationBySuccessChartDatum {
  id: string;
  label: string;
  value: number;
  deny: boolean;
}

const AuthorizationBySuccess = (props: {
  data: CountByAuthorizationSuccess;
}) => {
  const { t } = useTranslation();
  const titleAuthorized = t(
    "reports.organization.authorization.success.authorized.title"
  );
  const titleRejected = t(
    "reports.organization.authorization.success.rejected.title"
  );

  const chartData: AuthorizationBySuccessChartDatum[] = [
    {
      id: titleAuthorized,
      label: `${titleAuthorized} (${props.data.authorized})`,
      value: props.data.authorized,
      deny: false,
    },
    {
      id: titleRejected,
      label: `${titleRejected} (${props.data.rejected})`,
      value: props.data.rejected,
      deny: true,
    },
  ];

  return (
    <>
      <Box textAlign={"center"}>
        {t("reports.organization.authorization.success.title")}
      </Box>
      <ResponsivePie
        margin={{ top: 10, right: 0, bottom: 80, left: 0 }}
        data={chartData}
        animate={true}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        activeInnerRadiusOffset={6}
        layers={["arcs", "legends"]}
        colors={byAuthorizationSuccessTheme.palette.pie}
        theme={useNivoTheme()}
        legends={[
          {
            anchor: "bottom-left",
            direction: "column",
            symbolShape: "circle",
            itemWidth: 100,
            itemHeight: 20,
            translateY: 50,
            translateX: 30,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 0.5,
                },
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default AuthorizationBySuccess;
