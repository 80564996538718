import {
  Article as DetailIcon,
  Check as CheckIcon,
  Circle as CircleIcon,
  DeleteForever as DeleteIcon,
  FactCheck as VerificationIcon,
  Flaky as DenyAllIcon,
  Link as AssignGroupIcon,
  LinkOff as UnassignGroupIcon,
  Pending as BulkMenuIcon,
  PendingActions as PendingActionsIcon,
  Search as SearchIcon,
  UploadFile as UploadTcIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  Grow,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridActionsCellItem,
  GridApi,
  GridCellParams,
  GridColumns,
  GridFilterModel,
  GridRenderEditCellParams,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridSelectionModel,
  GridSortModel,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Operator, Page } from "../../../common/pagination";
import {
  ApproveState,
  useDeleteVehicleByIdMutation,
  useDeleteVehiclesByIdsMutation,
  useDenyVehicleMutation,
  useDenyVehiclesMutation,
  useGetVehiclesQuery,
  useUpdateVehicleMutation,
  Vehicle,
} from "../../../redux/api/VehiclesApi";
import DeleteModal from "./DeleteModal";
import Toolbar from "./Toolbar";
import { useSnackbar } from "notistack";
import Pagination from "../../pagination/Pagination";
import {
  paintToWebColor,
  tableComponentProps,
  TableContentWrapper,
} from "../../../common/styles";
import { VehiclesContext } from "../VehiclesComponent";
import VerificationModal from "./VerificationModal";
import ConfirmUpdateModal from "../../confirm-update-modal/ConfirmUpdateModal";
import {
  ExternalAuthorizeEventResult,
  ExternalAuthorizeEventStatusReason,
} from "../../../redux/api/ExternalAuthorizeEvent";
import { EditButton } from "./EditButton";
import { TableTextEditComponent } from "../../table/TableTextEditComponent";
import { SaveButton } from "./SaveButton";
import { CancelButton } from "./CancelButton";
import { getTableLocaleText } from "../../table/getTableLocaleText";
import { reducedVehiclesVisibilityModel } from "../../../common/small-screen/visibilityModel";
import useSmallScreenProperties from "../../../common/small-screen/useSmallScreenProperties";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import { renderStatusColumn } from "../../table/renderStatusColumn";
import { validateSpecialCharacters } from "../../validateSpecialCharacters";
import InputErrorModal from "../../modal/InputErrorModal";
import VehicleGroupSelector from "../vehicle-groups/table/VehicleGroupSelector";
import AssignGroupModal from "./AssignGroupModal";
import {
  useGetVehicleGroupsQuery,
  VehicleGroup,
} from "../../../redux/api/VehicleGroupsApi";
import {
  useAssignVehicleGroupMutation,
  useUnassignVehicleGroupMutation,
} from "../../../redux/api/VehiclesApi";
import UnassignGroupModal from "./UnassignGroupModal";
import DenyModal from "./DenyModal";

interface VehiclesTableProps {
  vehicles?: Page<Vehicle[]>;
}

export interface VehicleRow {
  id: string;
  vin?: string;
  deny?: boolean;
  licensePlate?: string;
  externalLastLoginResult?: ExternalAuthorizeEventResult;
  externalLastLoginReason?: ExternalAuthorizeEventStatusReason;
  modelType?: string;
  paintColor?: string;
  state?: ApproveState;
  reasonOfDecline?: string;
  technicalCardUploaded?: boolean;
  group?: VehicleGroup;
}

export interface ActionButtonProps {
  rowId: string;
}

interface UpdatePromise {
  newRow: GridRowModel<VehicleRow>;
  oldRow: GridRowModel<VehicleRow>;
  reject: (value: GridRowModel<VehicleRow>) => void;
  resolve: (value: GridRowModel<VehicleRow>) => void;
}

class InputError extends Error {}

const mapDataToTable = (vehicles: Vehicle[]): GridRowsProp<VehicleRow> =>
  vehicles?.map(({ publicId, externalLastLoginStatus, ...rest }) => ({
    ...rest,
    id: publicId,
    externalLastLoginResult: externalLastLoginStatus?.result,
    externalLastLoginReason: externalLastLoginStatus?.failReason,
  }));

const mapToEntity = (row: GridRowModel<VehicleRow>): Vehicle => ({
  publicId: row.id,
  licensePlate: row.licensePlate,
  state: row.state,
});

export const mapGroup = (groups: VehicleGroup[]) => {
  return groups?.map((group) => {
    return { label: group.name, value: group.publicId };
  });
};

const VehiclesTable: React.FC<VehiclesTableProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [
    displayAll,
    setDisplayAll,
    columnVisibilityModel,
    setColumnVisibilityModel,
  ] = useSmallScreenProperties(reducedVehiclesVisibilityModel);

  const bulkMenuButtonId = "bulk-menu-button";
  const bulkMenuId = "bulk-menu";

  const [updatePromise, setUpdatePromiseArguments] = useState<UpdatePromise>();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [inputErrorModalOpen, setInputErrorModalOpen] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const [selectedRow, setSelectedRow] = useState<VehicleRow>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [queryOptions, setQueryOptions] = useOutletContext<VehiclesContext>();
  const [assignGroupModalOpen, setAssignGroupModalOpen] = useState(false);
  const [unassignGroupModalOpen, setUnassignGroupModalOpen] = useState(false);
  const [assignGroup] = useAssignVehicleGroupMutation();
  const [unassignGroup] = useUnassignVehicleGroupMutation();
  const [denyModalOpen, setDenyModalOpen] = useState(false);
  const organization = useSelectedOrganization();

  const { data: groups } = useGetVehicleGroupsQuery({
    organizationId: organization.publicId,
    sortOrder: "asc",
    sortField: "name",
  });

  const { data, isFetching, isLoading } = useGetVehiclesQuery(queryOptions);
  const [deleteVehicle] = useDeleteVehicleByIdMutation();
  const [deleteVehicles] = useDeleteVehiclesByIdsMutation();
  const [denyVehicles] = useDenyVehiclesMutation();

  const [updateVehicle] = useUpdateVehicleMutation();

  const [deny] = useDenyVehicleMutation();

  const specialCharactersValidator = validateSpecialCharacters("licensePlate");

  const processRowUpdate = (
    newRow: GridRowModel<VehicleRow>,
    oldRow: GridRowModel<VehicleRow>
  ) =>
    new Promise<GridRowModel<VehicleRow>>((resolve, reject) => {
      const validationResult = newRow.licensePlate
        ? specialCharactersValidator(newRow.licensePlate)
        : true;
      if (validationResult === true) {
        setInputError(false);
      } else {
        throw new InputError(validationResult);
      }
      setUpdatePromiseArguments({ resolve, reject, newRow, oldRow });
    });
  const onProcessRowUpdateError = (error: any) => {
    if (error instanceof InputError) {
      setInputError(true);
      setInputErrorMessage(error.message);
      setInputErrorModalOpen(true);
    }
  };

  const handleOpenVerifyModal = (row: VehicleRow) => {
    setSelectedRow(row);
    setVerificationModalOpen(true);
  };

  const onDeleteClick = (id?: string) => {
    onBulkMenuClose();
    setIsDeleteOpen(true);
    if (id) {
      setSelectionModel([id]);
    }
  };

  const onAssignGroupClick = () => {
    onBulkMenuClose();
    setAssignGroupModalOpen(true);
  };

  const onUnassignGroupClick = () => {
    onBulkMenuClose();
    setUnassignGroupModalOpen(true);
  };

  const DetailsSection = (params: GridRowParams<VehicleRow>) => {
    const verificationLabel = t("verification");
    const detailLabel = t("detail");
    const deleteLabel = t("delete");
    const apiRef = useGridApiContext<GridApi>();
    return apiRef.current.getRowMode(params.row.id) === "edit"
      ? [
          <SaveButton rowId={params.row.id} />,
          <CancelButton rowId={params.row.id} />,
        ]
      : [
          <EditButton rowId={params.row.id} />,
          params.row.state === ApproveState.ACTIVE ? (
            <GridActionsCellItem
              id={`edit-vehicle-${params.row.vin}-button`}
              className="edit-vehicle-button"
              icon={
                <Tooltip title={detailLabel}>
                  <DetailIcon />
                </Tooltip>
              }
              label={detailLabel}
              onClick={() => navigate(`/vehicles/detail/${params.row.id}`)}
              color="primary"
            />
          ) : (
            <GridActionsCellItem
              id={`edit-vehicle-${params.row.vin}-button`}
              className="edit-vehicle-button"
              icon={
                <Tooltip title={verificationLabel}>
                  <VerificationIcon />
                </Tooltip>
              }
              label={verificationLabel}
              onClick={() => handleOpenVerifyModal(params.row)}
              color="primary"
            />
          ),
          <GridActionsCellItem
            icon={
              <Tooltip title={deleteLabel}>
                <DeleteIcon onClick={() => onDeleteClick()} />
              </Tooltip>
            }
            label={deleteLabel}
            onClick={() => {
              setIsDeleteOpen(!isDeleteOpen);
              setSelectionModel([params.row.id]);
            }}
            color="primary"
          />,
        ];
  };

  const onConfirmDelete = () => {
    setIsDeleteOpen(false);
    try {
      if (selectionModel.length > 0) {
        deleteVehicles({
          ids: selectionModel,
          organizationId: organization.publicId,
        }).unwrap();
      } else if (selectionModel.length === 1) {
        deleteVehicle({
          id: `${selectionModel[0]}`,
          organizationId: organization.publicId,
        }).unwrap();
      }
    } catch (error: any) {
      enqueueSnackbar(t("delete.fail"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
    setSelectionModel([]);
  };

  const onCancelDeleteDialog = () => {
    setIsDeleteOpen(false);
  };

  const onCancelVerifyDialog = () => {
    setVerificationModalOpen(false);
  };

  const onConfirmUpdate = async () => {
    const { newRow, oldRow, reject, resolve } = updatePromise!;
    newRow.licensePlate = newRow?.licensePlate?.trim();
    try {
      if (newRow.licensePlate !== oldRow.licensePlate) {
        await updateVehicle({
          vehicle: mapToEntity(newRow),
          organizationId: organization.publicId,
        }).unwrap();
      }

      if (newRow.group !== oldRow.group) {
        if (newRow.group) {
          await assignGroup({
            organizationId: organization.publicId,
            groupId: newRow.group.publicId,
            ids: [newRow.id],
          }).unwrap();
        } else {
          await unassignGroup({
            organizationId: organization.publicId,
            ids: [newRow.id],
          }).unwrap();
        }
      }

      resolve(newRow);
    } catch (error: any) {
      reject(oldRow);
      enqueueSnackbar(t("table.failure.update"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    } finally {
      setUpdatePromiseArguments(undefined);
    }
  };

  const onCancelUpdate = () => {
    const { oldRow, resolve } = updatePromise!;
    resolve(oldRow);
    setUpdatePromiseArguments(undefined);
  };

  const onChangeDeny =
    (id: string) => async (_: ChangeEvent, allowAccessChecked: boolean) => {
      try {
        await deny({
          publicId: id,
          deny: !allowAccessChecked,
          organizationId: organization.publicId,
        });
      } catch (error: any) {
        enqueueSnackbar("Failed to switch deny state", {
          variant: "error",
          preventDuplicate: true,
          key: error.status,
        });
      }
    };

  const renderDetailColumn = (params: GridCellParams<unknown, VehicleRow>) => {
    const color =
      params.row.paintColor && paintToWebColor(params.row.paintColor);
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <div>{params.row.modelType}</div>
        {color && <CircleIcon sx={{ color }} />}
      </Stack>
    );
  };

  const renderApprovalStatus = (
    params: GridCellParams<unknown, VehicleRow>
  ) => {
    const label = t("vehicles.approve.state." + params.row.state);
    let tooltip = "";
    let icon = undefined;

    switch (params.row.state) {
      case ApproveState.ACTIVE:
        tooltip = t("vehicles.approval.approved.tooltip");
        icon = <CheckIcon />;
        break;
      case ApproveState.DECLINED:
        tooltip = `${t("vehicles.approval.reason")}: ${
          params.row.reasonOfDecline ?? ""
        }`;
        icon = <WarningIcon />;
        break;
      case ApproveState.PENDING:
        if (params.row.technicalCardUploaded) {
          tooltip = t("vehicles.approval.tcUploaded.tooltip");
          icon = <PendingActionsIcon />;
        } else {
          tooltip = t("vehicles.approval.tcNotUploaded.tooltip");
          icon = <UploadTcIcon />;
        }
        break;
      default:
        break;
    }

    return (
      <Tooltip title={tooltip}>
        <Chip label={label} icon={icon} size="small" />
      </Tooltip>
    );
  };

  const renderDenyToggle = (params: GridCellParams<boolean, VehicleRow>) => {
    const tooltip = t(
      params.value
        ? "vehicles.access.denied.tooltip"
        : "vehicles.access.allowed.tooltip"
    );
    return (
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Tooltip title={tooltip}>
          <Switch
            checked={!params.value}
            onChange={onChangeDeny(params.row.id)}
          />
        </Tooltip>
      </Box>
    );
  };

  const onDenyAllClick = () => {
    onBulkMenuClose();
    setDenyModalOpen(true);
  };

  const onDenySelected = async (deny: boolean) => {
    setDenyModalOpen(false);
    try {
      await denyVehicles({
        organizationId: organization.publicId,
        ids: selectionModel as string[],
        deny: deny,
      }).unwrap();
      enqueueSnackbar(
        t("vehicles.deny.set.success", { count: selectionModel.length }),
        {
          variant: "success",
        }
      );
      setSelectionModel([]);
    } catch (error: any) {
      enqueueSnackbar(t("vehicles.deny.set.error"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const bulkMenuOpen = Boolean(anchorEl);
  const onBulkMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onBulkMenuClose = () => {
    setAnchorEl(undefined);
  };

  const groupOptions = useMemo(
    () => (groups ? mapGroup(groups?.content) : []),
    [groups]
  );

  const renderTableActions = () => {
    return (
      <>
        <Grow in={selectionModel.length > 0}>
          <Tooltip title={t("table.bulkActions")}>
            <IconButton
              id={bulkMenuButtonId}
              aria-controls={bulkMenuOpen ? bulkMenuId : undefined}
              aria-haspopup="true"
              aria-expanded={bulkMenuOpen ? "true" : undefined}
              onClick={onBulkMenuClick}
            >
              <BulkMenuIcon />
            </IconButton>
          </Tooltip>
        </Grow>
        <Menu
          id={bulkMenuId}
          anchorEl={anchorEl}
          open={bulkMenuOpen}
          onClose={onBulkMenuClose}
          MenuListProps={{
            "aria-labelledby": bulkMenuButtonId,
          }}
        >
          <MenuItem onClick={onDenyAllClick}>
            <ListItemIcon>
              <DenyAllIcon />
            </ListItemIcon>
            <ListItemText>{t("vehicles.access.set")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={onAssignGroupClick}>
            <ListItemIcon>
              <AssignGroupIcon />
            </ListItemIcon>
            <ListItemText>{t("vehicles.group.assign")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={onUnassignGroupClick}>
            <ListItemIcon>
              <UnassignGroupIcon />
            </ListItemIcon>
            <ListItemText>{t("bulkUnassignGroup")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => onDeleteClick()}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>{t("bulkDelete")}</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  };

  const columns: GridColumns = [
    {
      field: "vin",
      headerName: t("vehicles.vin"),
      flex: 0.8,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
    },
    {
      field: "detail",
      headerName: t("vehicles.detail"),
      flex: 1,
      editable: false,
      renderCell: renderDetailColumn,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
    },
    {
      field: "groupId",
      headerName: t("vehicles.group"),
      flex: 1,
      minWidth: 100,
      editable: true,
      filterOperators: getGridSingleSelectOperators().filter(
        (op) => op.value === "is"
      ),
      valueOptions: groupOptions,
      valueGetter: (params) => params.row.group,
      valueSetter: (params) => {
        const group = params.value;
        return { ...params.row, group };
      },
      valueFormatter: (params) => params.value?.name,
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <VehicleGroupSelector {...params} />;
      },
    },
    {
      field: "licensePlate",
      headerName: t("vehicles.licensePlate"),
      flex: 0.7,
      editable: true,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
      renderEditCell: (props: GridRenderEditCellParams) => (
        <TableTextEditComponent renderParams={props} maxLength={20} />
      ),
    },
    {
      field: "state",
      headerName: t("vehicles.approval"),
      flex: 0.6,
      valueOptions: Object.values(ApproveState),
      valueFormatter: ({ value }) => t("vehicles.approve.state." + value),
      filterOperators: getGridSingleSelectOperators().filter(
        (s) => s.value === "is"
      ),
      editable: false,
      renderCell: renderApprovalStatus,
      type: "singleSelect",
    },
    {
      field: "status",
      headerName: t("vehicles.last.evaluation.result"),
      flex: 0.7,
      editable: false,
      filterable: true,
      valueOptions: Object.values(ExternalAuthorizeEventResult),
      valueFormatter: ({ value }) =>
        t("vehicles.external.access.state." + value),
      filterOperators: getGridSingleSelectOperators().filter(
        (s) => s.value === "is"
      ),
      sortable: false,
      renderCell: renderStatusColumn,
    },
    {
      field: "deny",
      headerName: t("vehicles.allowAccess"),
      flex: 0.6,
      renderCell: renderDenyToggle,
      valueOptions: ["false", "true"],
      valueFormatter: ({ value }) =>
        value === "true"
          ? t("vehicles.allowAccess.denied")
          : t("vehicles.allowAccess.allowed"),
      filterOperators: getGridSingleSelectOperators().filter(
        (s) => s.value === "is"
      ),
    },
    {
      field: "actions",
      headerName: t("table.columns.actions"),
      type: "actions",
      hideable: true,
      minWidth: 80,
      getActions: DetailsSection,
      // renderHeader: renderBulkDeleteHeader,
      renderHeader: renderTableActions,
    },
  ];

  const rows = useMemo(() => {
    return data ? mapDataToTable(data?.content) : [];
  }, [data]);

  const localeText = getTableLocaleText();

  const onPageChange = (page: number) => {
    setQueryOptions({ ...queryOptions, page });
  };

  const onPageSizeChange = (size: number) => {
    setQueryOptions({ ...queryOptions, size });
  };

  const onSortModelChange = (sortModel: GridSortModel) => {
    const sort = sortModel.length === 0 ? undefined : sortModel[0];
    setQueryOptions({
      ...queryOptions,
      sortField: sort?.field as keyof Vehicle,
      sortOrder: sort?.sort,
    });
  };

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    const filter =
      filterModel.items.length === 0 ? undefined : filterModel.items[0];
    setQueryOptions({
      ...queryOptions,
      filterField: filter?.columnField as keyof Vehicle,
      filterOperator: filter?.operatorValue as Operator,
      filterValue: filter?.value,
    });
  };

  const sortModel = useMemo(() => {
    return queryOptions.sortField
      ? [
          {
            sort: queryOptions.sortOrder,
            field: queryOptions.sortField,
          },
        ]
      : [];
  }, [queryOptions.sortField, queryOptions.sortOrder]);

  const filterModel = useMemo(() => {
    const filter = queryOptions.filterField
      ? [
          {
            columnField: queryOptions.filterField,
            operatorValue: queryOptions.filterOperator,
            value: queryOptions.filterValue,
          },
        ]
      : [];
    return {
      items: filter,
    };
  }, [
    queryOptions.filterField,
    queryOptions.filterOperator,
    queryOptions.filterValue,
  ]);

  const onGroupSelected = async (group: VehicleGroup) => {
    setAssignGroupModalOpen(false);
    try {
      await assignGroup({
        organizationId: organization.publicId,
        groupId: group.publicId,
        ids: selectionModel as string[],
      }).unwrap();
      enqueueSnackbar(
        t("vehicles.group.assign.success", { count: selectionModel.length }),
        {
          variant: "success",
        }
      );
      setSelectionModel([]);
    } catch (error: any) {
      enqueueSnackbar(t("vehicles.group.assign.error"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
  };
  const onCancelUnassignGroup = () => {
    setUnassignGroupModalOpen(false);
  };

  useEffect(() => {
    if (selectedRow && data) {
      const selectedVehicleRow = rows.find((row) => row.id === selectedRow.id);
      if (selectedVehicleRow) {
        setSelectedRow(selectedVehicleRow);
      }
    }
  }, [rows, selectedRow]);
  const onConfirmUnassignGroup = () => {
    setUnassignGroupModalOpen(false);
    try {
      unassignGroup({
        ids: selectionModel as string[],
        organizationId: organization.publicId,
      }).unwrap();
      enqueueSnackbar(
        t("vehicles.unassignGroup.success", { count: selectionModel.length }),
        {
          variant: "success",
        }
      );
    } catch (error: any) {
      enqueueSnackbar(t("vehicles.unassignGroup.fail"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
    setSelectionModel([]);
  };

  useEffect(() => {
    if (organization) {
      setQueryOptions({
        ...queryOptions,
        organizationId: organization.publicId,
      });
    }
  }, [organization]);

  return (
    <TableContentWrapper displayAll={displayAll}>
      <DataGrid
        localeText={localeText}
        rows={rows}
        rowCount={data?.totalElements || 0}
        columns={columns}
        page={queryOptions.page}
        onPageChange={onPageChange}
        pageSize={queryOptions.size}
        onPageSizeChange={onPageSizeChange}
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        sortingMode="server"
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        filterMode="server"
        components={{
          Toolbar,
          LoadingOverlay: LinearProgress,
          Pagination,
          OpenFilterButtonIcon: SearchIcon,
        }}
        loading={isFetching || isLoading}
        disableSelectionOnClick
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={setSelectionModel}
        editMode="row"
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        componentsProps={{
          ...tableComponentProps,
          toolbar: { displayAll, setDisplayAll },
        }}
        keepNonExistentRowsSelected
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
      />
      <DeleteModal
        isDialogOpened={isDeleteOpen}
        count={selectionModel.length}
        handleCancelDialog={onCancelDeleteDialog}
        handleDeleteDialog={onConfirmDelete}
      />
      <AssignGroupModal
        modalOpen={assignGroupModalOpen}
        onGroupSelected={onGroupSelected}
        onClose={() => setAssignGroupModalOpen(false)}
      />
      <UnassignGroupModal
        isDialogOpened={unassignGroupModalOpen}
        count={selectionModel.length}
        handleCancelDialog={onCancelUnassignGroup}
        handleConfirmDialog={onConfirmUnassignGroup}
      />
      {selectedRow && (
        <VerificationModal
          isDialogOpened={verificationModalOpen}
          handleCancelDialog={onCancelVerifyDialog}
          row={selectedRow}
        />
      )}
      <ConfirmUpdateModal
        open={!!updatePromise && !inputError}
        onClose={onCancelUpdate}
        onConfirm={onConfirmUpdate}
      />
      <InputErrorModal
        open={inputErrorModalOpen}
        onClose={() => setInputErrorModalOpen(false)}
        message={inputErrorMessage}
      />
      <DenyModal
        modalOpen={denyModalOpen}
        onDenySelected={onDenySelected}
        onClose={() => setDenyModalOpen(false)}
      />
    </TableContentWrapper>
  );
};

export default VehiclesTable;
