import {
  Count,
  CountByActivated,
  CountByDeny,
  Page,
  PageRequest,
  toPageSearchParams,
} from "../../common/pagination";
import { baseApi, OrganizationRequest } from "./baseApi";
import { GridSelectionModel } from "@mui/x-data-grid";
import {
  ExternalAuthorizeEventResult,
  ExternalAuthorizeEventStatusReason,
} from "./ExternalAuthorizeEvent";
import { toSearchParams } from "./util";

export interface Associate {
  publicId: string;
  name?: string;
  emailAddress?: string;
  deny?: boolean;
  externalLastLoginStatus?: LastLoginStatus;
  activated: boolean;
  admin?: boolean;
  group?: Group;
}

interface Group {
  publicId: string;
  name: string;
}

export interface LastLoginStatus {
  result?: ExternalAuthorizeEventResult;
  failReason?: ExternalAuthorizeEventStatusReason;
}

export enum AssociateValidationError {
  UNKNOWN = "UNKNOWN",
  DUPLICATE_VIN = "DUPLICATE_VIN",
  INVALID_VIN = "INVALID_VIN",
  MISSING_VIN = "MISSING_VIN",
  LICENSE_PLATE_SIZE = "LICENSE_PLATE_SIZE",
  DESCRIPTION_SIZE = "DESCRIPTION_SIZE",
}

export interface AssociateValidationErrors {
  [email: string]: AssociateValidationError[];
}

interface DenyRequest extends OrganizationRequest {
  publicId?: string;
  deny: boolean;
}

interface AssociateIdRequest extends OrganizationRequest {
  id: string;
}

interface AssociateIdsRequest extends OrganizationRequest {
  ids: GridSelectionModel;
}

interface DenyAssociatesRequest extends OrganizationRequest {
  ids: string[];
  deny: boolean;
}

interface AssociateBodyRequest extends OrganizationRequest {
  associate: Partial<Associate>;
  groupId?: string;
}

interface ImportAssociatesRequest extends OrganizationRequest {
  file: File;
  groupId?: string;
}

interface AssignGroupRequest extends OrganizationRequest {
  groupId?: string;
  ids: string[];
}

interface UnassignGroupRequest extends OrganizationRequest {
  ids: string[];
}

export const associatesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAssociates: builder.query<Page<Associate[]>, PageRequest<Associate>>({
      query: (request) => {
        return `/associates?${toPageSearchParams(request)}`;
      },
      providesTags: (_) => ["Associate"],
    }),
    deleteAssociateById: builder.mutation<void, AssociateIdRequest>({
      query: ({ id, organizationId }) => ({
        url: `/associates/${id}?${toSearchParams({ organizationId })}`,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["Associate", "AssociateStats", "AssociateGroup"],
    }),
    deleteAssociatesByIds: builder.mutation<void, AssociateIdsRequest>({
      query: ({ ids, organizationId }) => ({
        url: `/associates?${toSearchParams({
          ids,
          organizationId,
        })}`,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["Associate", "AssociateStats", "AssociateGroup"],
    }),
    createAssociate: builder.mutation<void, AssociateBodyRequest>({
      query: ({ associate, organizationId, groupId }) => ({
        url: `/associates?${toSearchParams({ organizationId })}`,
        method: "POST",
        body: {
          ...associate,
          groupId,
        },
      }),
      invalidatesTags: (_) => ["Associate", "AssociateStats", "Admin"],
    }),
    updateAssociate: builder.mutation<void, AssociateBodyRequest>({
      query: ({ associate: { publicId, ...patch }, organizationId }) => {
        return {
          url: `/associates/${publicId}?${toSearchParams({ organizationId })}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (_) => ["Associate", "AssociateStats", "Admin"],
    }),
    activateAssociate: builder.mutation<void, AssociateIdRequest>({
      query: ({ id, organizationId }) => ({
        url: `/associates/${id}/activate?${toSearchParams({ organizationId })}`,
        method: "PUT",
      }),
      invalidatesTags: (_) => ["Associate", "AssociateStats"],
    }),
    activateAssociates: builder.mutation<void, AssociateIdsRequest>({
      query: ({ ids, organizationId }) => ({
        url: `/associates/activate?${toSearchParams({
          ids,
          organizationId,
        })}`,
        method: "PUT",
      }),
      invalidatesTags: (_) => ["Associate", "AssociateStats"],
    }),
    denyAssociate: builder.mutation<void, DenyRequest>({
      query: ({ publicId, deny, organizationId }) => {
        return {
          url: `/associates/${publicId}?${toSearchParams({ organizationId })}`,
          method: "PATCH",
          body: {
            deny,
          },
        };
      },
      invalidatesTags: (_) => ["Associate", "AssociateStats"],
    }),
    denyAssociates: builder.mutation<void, DenyAssociatesRequest>({
      query: ({ ids, organizationId, deny }) => ({
        url: `/associates/deny?${toSearchParams({
          organizationId,
          ids,
          deny,
        })}`,
        method: "PUT",
      }),
      invalidatesTags: (_) => ["Associate", "AssociateStats"],
    }),
    importAssociates: builder.mutation<Associate[], ImportAssociatesRequest>({
      query: ({ file, organizationId, groupId }) => {
        const formData = new FormData();
        formData.append("data", file);
        return {
          url: `/associates/import?${toSearchParams({
            organizationId,
            groupId,
          })}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (_) => ["Associate", "AssociateStats", "AssociateGroup"],
    }),
    assignAssociateGroup: builder.mutation<void, AssignGroupRequest>({
      query: ({ groupId, ids, organizationId }) => ({
        url: `/associates/group/${groupId}?${toSearchParams({
          organizationId,
          ids,
        })}`,
        method: "PUT",
      }),
      invalidatesTags: (_) => ["Associate", "AssociateGroup"],
    }),
    unassignAssociateGroup: builder.mutation<void, UnassignGroupRequest>({
      query: ({ ids, organizationId }) => ({
        url: `/associates/group?${toSearchParams({
          organizationId,
          ids,
        })}`,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["Associate", "AssociateGroup"],
    }),
    countAssociates: builder.query<Count, string>({
      query: (organizationId) =>
        `/associates/count?${toSearchParams({ organizationId })}`,
      providesTags: (_) => ["AssociateStats"],
    }),
    countAssociatesByDeny: builder.query<CountByDeny, string>({
      query: (organizationId) =>
        `/associates/count-by-deny?${toSearchParams({ organizationId })}`,
      providesTags: (_) => ["AssociateStats"],
    }),
    countAssociatesByActivated: builder.query<CountByActivated, string>({
      query: (organizationId) =>
        `/associates/count-by-activated?${toSearchParams({
          organizationId,
        })}`,
      providesTags: (_) => ["AssociateStats"],
    }),
  }),
});

export const {
  useGetAssociatesQuery,
  useLazyGetAssociatesQuery,
  useCreateAssociateMutation,
  useDeleteAssociateByIdMutation,
  useDeleteAssociatesByIdsMutation,
  useUpdateAssociateMutation,
  useActivateAssociateMutation,
  useActivateAssociatesMutation,
  useDenyAssociateMutation,
  useDenyAssociatesMutation,
  useImportAssociatesMutation,
  useAssignAssociateGroupMutation,
  useUnassignAssociateGroupMutation,
  useCountAssociatesQuery,
  useCountAssociatesByDenyQuery,
  useCountAssociatesByActivatedQuery,
} = associatesApi;
