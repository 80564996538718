import { DeleteForever as DeleteIcon } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ActionButtonProps } from "./VehicleGroupsTable";

export const DeleteButton: FC<ActionButtonProps> = ({
  id,
  onClick: onClickProp,
}) => {
  const { t } = useTranslation();
  const label = t("delete");

  const onClick = () => {
    if (onClickProp) {
      onClickProp(id);
    }
  };

  return (
    <GridActionsCellItem
      icon={
        <Tooltip title={label}>
          <DeleteIcon />
        </Tooltip>
      }
      label={label}
      onClick={onClick}
    />
  );
};
