import { FC, MouseEvent } from "react";
import {
  GridActionsCellItem,
  useGridApiContext,
  GridApi,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Edit as EditIcon } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { ActionButtonProps } from "./VehicleGroupsTable";

export const EditButton: FC<ActionButtonProps> = ({ id }) => {
  const apiRef = useGridApiContext<GridApi>();
  const { t } = useTranslation();
  const label = t("vehicles.groups.edit.tooltip");

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    apiRef.current.startRowEditMode({ id });
    apiRef.current.setCellFocus(id, "name");
  };

  return (
    <GridActionsCellItem
      icon={
        <Tooltip title={label}>
          <EditIcon />
        </Tooltip>
      }
      label={label}
      onClick={onClick}
    />
  );
};
