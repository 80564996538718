import { AssociatesReport } from "../../../../redux/api/ReportsApi";
import Grid from "@mui/material/Grid";
import React from "react";
import TrendComponent from "../TrendComponent";
import { useTranslation } from "react-i18next";
import GridItemLabel from "../GridItemLabel";

const AssociatesReportComponent = (props: { data: AssociatesReport }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.associates.total")}
      />
      <Grid item xs={6}>
        {props.data.total}
        {!!props.data.previousTotal && (
          <TrendComponent
            current={props.data.total}
            previous={props.data.previousTotal}
          />
        )}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.associates.created")}
      />
      <Grid item xs={6}>
        {props.data.created}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.associates.deleted")}
      />
      <Grid item xs={6}>
        {props.data.deleted}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.associates.active")}
      />
      <Grid item xs={6}>
        {props.data.active}
      </Grid>
      <GridItemLabel xs={6} label={t("reports.organization.associates.auto")} />
      <Grid item xs={6}>
        {props.data.automaticallyCreated}
        {!!props.data.previousAutomaticallyCreated && (
          <TrendComponent
            current={props.data.automaticallyCreated}
            previous={props.data.previousAutomaticallyCreated}
          />
        )}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.associates.auto5days")}
      />
      <Grid item xs={6}>
        {props.data.automaticallyCreated5days}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.associates.auto30days")}
      />
      <Grid item xs={6}>
        {props.data.automaticallyCreated30days}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.associates.allowed")}
      />
      <Grid item xs={6}>
        {props.data.allowed}
        {!!props.data.previousAllowed && (
          <TrendComponent
            current={props.data.allowed}
            previous={props.data.previousAllowed}
          />
        )}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.associates.denied")}
      />
      <Grid item xs={6}>
        {props.data.denied}
      </Grid>
    </Grid>
  );
};

export default AssociatesReportComponent;
