import { baseApi, OrganizationRequest } from "./baseApi";
import {
  VERIFY_AUTO_ACCESS_KEY,
  VERIFY_VIN_KEY,
} from "../slice/OrganizationSettingsSlice";
import { toSearchParams } from "./util";

export interface OrganizationSetting {
  publicId?: string;
  key?: string;
  value?: string;
}

export interface SettingUpdate extends OrganizationRequest {
  publicId: string;
  shouldVerify: boolean;
}

export type Period = "MONTH" | "QUARTER";

export interface OrganizationReporting {
  period: Period;
  recipientEmails: string[];
  enabled: boolean;
}

type UpdateOrganizationReporting = OrganizationReporting & OrganizationRequest;

export const organizationSettingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationSettings: builder.query<OrganizationSetting[], string>({
      query: (organizationId) => ({
        url: `/organization-settings?${toSearchParams({ organizationId })}`,
      }),
      providesTags: (_) => ["OrganizationSetting"],
    }),
    //an attempt to make api call less ugly, since BE api is poorly designed.
    setVerifyVinSetting: builder.mutation<void, SettingUpdate>({
      query: (update) => {
        const shouldVerify = update.shouldVerify ? "Y" : "N";
        const updatedSetting: OrganizationSetting = {
          publicId: update.publicId,
          key: VERIFY_VIN_KEY,
          value: shouldVerify,
        };

        return {
          url: `/organization-settings?${toSearchParams({
            organizationId: update.organizationId,
          })}`,
          method: "PUT",
          body: [updatedSetting],
        };
      },
      invalidatesTags: (_) => ["OrganizationSetting"],
    }),
    setVerifyAutoAccess: builder.mutation<void, SettingUpdate>({
      query: (update) => {
        const shouldVerify = update.shouldVerify ? "Y" : "N";
        const updatedSetting: OrganizationSetting = {
          publicId: update.publicId,
          key: VERIFY_AUTO_ACCESS_KEY,
          value: shouldVerify,
        };

        return {
          url: `/organization-settings?${toSearchParams({
            organizationId: update.organizationId,
          })}`,
          method: "PUT",
          body: [updatedSetting],
        };
      },
      invalidatesTags: (_) => ["OrganizationSetting"],
    }),
    getReporting: builder.query<OrganizationReporting, string>({
      query: (organizationId) => ({
        url: `/organization-settings/report?${toSearchParams({
          organizationId,
        })}`,
      }),
      providesTags: (_) => ["OrganizationReporting"],
    }),
    updateReporting: builder.mutation<void, UpdateOrganizationReporting>({
      query: ({ organizationId, enabled, period, recipientEmails }) => {
        return {
          url: `/organization-settings/report?${toSearchParams({
            organizationId,
          })}`,
          method: "PUT",
          body: {
            enabled,
            period,
            recipientEmails,
          },
        };
      },
      invalidatesTags: (_) => ["OrganizationReporting"],
    }),
  }),
});

export const {
  useGetOrganizationSettingsQuery,
  useSetVerifyVinSettingMutation,
  useSetVerifyAutoAccessMutation,
  useGetReportingQuery,
  useUpdateReportingMutation,
} = organizationSettingsApi;
