import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";
import { PaletteMode, ThemeProvider } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { resolveTheme } from "./theme";
import { SnackbarProvider } from "notistack";
import { resolveBranding } from "./branding";

interface ColorsContextType {
  mode: PaletteMode;
  changeMode: () => void;
  changeColors: (primaryColor?: string) => void;
}

const initialState: ColorsContextType = {
  mode: "light",
  changeMode: () => null,
  changeColors: () => null,
};
const ApplicationColorsContext = createContext<ColorsContextType>(initialState);

export function useApplicationColors() {
  return useContext(ApplicationColorsContext);
}

export default function ApplicationColorsProvider({
  children,
}: PropsWithChildren<any>) {
  const isSystemDark = useMediaQuery("(prefers-color-scheme: dark)");
  const systemMode = useMemo(
    () => (isSystemDark ? "dark" : "light"),
    [isSystemDark]
  );
  const sessionMode = sessionStorage.getItem("mode") as PaletteMode;
  const [selectedMode, setSelectedMode] = useState<PaletteMode | null>(null);
  const applicationMode = useMemo(
    () => selectedMode || sessionMode || systemMode,
    [selectedMode, sessionMode, systemMode]
  );

  const initialColor =
    sessionStorage.getItem("primaryColor") || resolveBranding().primaryColor;
  const [applicationColor, setApplicationColor] = useState(initialColor);

  const theme = useMemo(() => {
    return resolveTheme(applicationColor, applicationMode);
  }, [applicationMode, applicationColor]);

  const changeMode = () => {
    const newMode = applicationMode === "light" ? "dark" : "light";
    setSelectedMode(newMode);
    sessionStorage.setItem("mode", newMode);
  };
  const changeColors = (primaryColor?: string) => {
    const resolvedColor = primaryColor || resolveBranding().primaryColor;
    setApplicationColor(resolvedColor);
    sessionStorage.setItem("primaryColor", resolvedColor);
  };

  return (
    <ApplicationColorsContext.Provider
      value={{
        mode: applicationMode,
        changeColors,
        changeMode,
      }}
    >
      <ThemeProvider theme={theme}>
        <SnackbarProvider>{children}</SnackbarProvider>
      </ThemeProvider>
    </ApplicationColorsContext.Provider>
  );
}
