import { styled } from "@mui/system";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Dispatch, FC, SetStateAction } from "react";
import ResetColumnVisibilityButton from "../../../table/ResetColumnVisibilityButton";

interface ToolbarProps {
  setFilterButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}

const Toolbar: FC<ToolbarProps> = ({ setFilterButtonEl }) => (
  <Wrapper>
    <ResetColumnVisibilityButton />
    <GridToolbarFilterButton ref={setFilterButtonEl} />
  </Wrapper>
);

const Wrapper = styled(GridToolbarContainer)(({ theme }) => ({
  justifyContent: "flex-end",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));

export default Toolbar;
