import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { associatesGroupsRoute } from "../navbar/routes";

const AssociatesTableToggle = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const view = pathname === "/associates" ? "associates" : "groups";

  return (
    <ToggleButtonGroup color="primary" value={view} exclusive size="medium">
      <ToggleButton component={Link as any} value="associates" to="/associates">
        {t("associates")}
      </ToggleButton>
      <ToggleButton
        component={Link as any}
        value="groups"
        to={associatesGroupsRoute.path}
      >
        {t("associates.groups")}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default AssociatesTableToggle;
