import { Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  SettingUpdate,
  useSetVerifyAutoAccessMutation,
  useSetVerifyVinSettingMutation,
} from "../../redux/api/OrganizationSettingsApi";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";
import { GridSwitch } from "../../common/styles";
import RenameOrganization from "./input/RenameOrganization";
import ChangeDomain from "./input/ChangeDomain";
import { useGetOrganizationQuery } from "../../redux/api/OrganizationApi";

const OrganizationSettings = () => {
  const { t } = useTranslation();
  const [updateVerifyVinSetting] = useSetVerifyVinSettingMutation();
  const [updateVerifyAutoAccessSetting] = useSetVerifyAutoAccessMutation();
  const verifyVin = useSelector(
    (state: RootState) => state.organizationSettings.verifyVin
  );

  const verifyAutoAccess = useSelector(
    (state: RootState) => state.organizationSettings.verifyAutoAccess
  );
  const verifyVinSettingPublicId = useSelector(
    (state: RootState) => state.organizationSettings.verifyVinSettingPublicId
  );
  const verifyAutoAccessSettingPublicId = useSelector(
    (state: RootState) => state.organizationSettings.verifyAutoAccessPublicId
  );
  const { publicId: organizationId } = useSelectedOrganization();
  const { data: organization } = useGetOrganizationQuery(organizationId);

  const toggleVerifyVin = () => {
    const update: SettingUpdate = {
      shouldVerify: !verifyVin,
      publicId: verifyVinSettingPublicId || "",
      organizationId: organizationId,
    };
    updateVerifyVinSetting(update).unwrap();
  };

  const toggleAutoAccess = () => {
    const update: SettingUpdate = {
      shouldVerify: !verifyAutoAccess,
      publicId: verifyAutoAccessSettingPublicId || "",
      organizationId: organizationId,
    };
    updateVerifyAutoAccessSetting(update).unwrap();
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        {t("settings.organization.name")}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid
          container
          sx={{
            flexDirection: "row",
            alignItems: "center",
            wrap: "nowrap",
          }}
          spacing={2}
        >
          <Grid item>{organization?.name}</Grid>
          <Grid item>
            <RenameOrganization />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        {t("organization.domain")}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid
          container
          sx={{
            flexDirection: "row",
            alignItems: "center",
            wrap: "nowrap",
          }}
          spacing={2}
        >
          <Grid item>{organization?.emailDomain}</Grid>
          <Grid item>
            <ChangeDomain />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <span>{t("settings.verifyVin")}</span>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title={t("settings.verifyVin.description")}>
          <GridSwitch
            data-cy={"verifyVinToggle"}
            checked={verifyVin}
            onChange={toggleVerifyVin}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <span>{t("settings.autoAccess")}</span>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title={t("settings.autoAccess.description")}>
          <GridSwitch
            data-cy={"AutoAccesToggle"}
            checked={verifyAutoAccess}
            onChange={toggleAutoAccess}
          />
        </Tooltip>
      </Grid>
    </>
  );
};

export default OrganizationSettings;
