import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

export const useSelectedOrganization = () => {
  const organization = useSelector(
    (state: RootState) => state.userSettings.organization
  );

  if (!organization) {
    throw new Error("An organization must be preselected to use this hook");
  }

  return organization;
};
