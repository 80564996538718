import { OrganizationReportDetail } from "../../../redux/api/ReportsApi";
import XlsxPopulate, { Cell, Workbook } from "xlsx-populate";
import template from "./template_report_organization.xlsx";
import { calculateTrend } from "../calculateTrend";
import { getI18n } from "react-i18next";

export function exportReport(report: OrganizationReportDetail) {
  return XlsxPopulate.fromDataAsync(template, { base64: true })
    .then((workbook) => supplyValues(workbook, report))
    .then((workbook) => workbook.outputAsync() as Promise<Blob>);
}

function supplyValues(workbook: Workbook, report: OrganizationReportDetail) {
  const t = getI18n().t;
  const associates = report.associates;
  const vehicles = report.vehicles;
  const authorization = report.externalAuthorization;

  // Associates
  supplyValue(
    workbook,
    "translation_associates_title",
    t("reports.organization.associates.title")
  );

  supplyValue(
    workbook,
    "translation_associates_total",
    t("reports.organization.associates.total")
  );
  supplyValue(workbook, "data_associates_total", associates.total);
  if (associates.previousTotal) {
    const trend = calculateTrend(associates.total, associates.previousTotal);
    supplyValue(workbook, "data_associates_total_trend", trend);
  }

  supplyValue(
    workbook,
    "translation_associates_created",
    t("reports.organization.associates.created")
  );
  supplyValue(workbook, "data_associates_created", associates.created);

  supplyValue(
    workbook,
    "translation_associates_deleted",
    t("reports.organization.associates.deleted")
  );
  supplyValue(workbook, "data_associates_deleted", associates.deleted);

  supplyValue(
    workbook,
    "translation_associates_active",
    t("reports.organization.associates.active")
  );
  supplyValue(workbook, "data_associates_active", associates.active);

  supplyValue(
    workbook,
    "translation_associates_auto",
    t("reports.organization.associates.auto")
  );
  supplyValue(
    workbook,
    "data_associates_auto",
    associates.automaticallyCreated
  );
  if (associates.previousAutomaticallyCreated) {
    const trend = calculateTrend(
      associates.automaticallyCreated,
      associates.previousAutomaticallyCreated
    );
    supplyValue(workbook, "data_associates_auto_trend", trend);
  }

  supplyValue(
    workbook,
    "translation_associates_auto5days",
    t("reports.organization.associates.auto5days")
  );
  supplyValue(
    workbook,
    "data_associates_auto5days",
    associates.automaticallyCreated5days
  );

  supplyValue(
    workbook,
    "translation_associates_auto30days",
    t("reports.organization.associates.auto30days")
  );
  supplyValue(
    workbook,
    "data_associates_auto30days",
    associates.automaticallyCreated30days
  );

  supplyValue(
    workbook,
    "translation_associates_allowed",
    t("reports.organization.associates.allowed")
  );
  supplyValue(workbook, "data_associates_allowed", associates.allowed);
  if (associates.previousAllowed) {
    const trend = calculateTrend(
      associates.allowed,
      associates.previousAllowed
    );
    supplyValue(workbook, "data_associates_allowed_trend", trend);
  }

  supplyValue(
    workbook,
    "translation_associates_denied",
    t("reports.organization.associates.denied")
  );
  supplyValue(workbook, "data_associates_denied", associates.denied);

  // Vehicles
  supplyValue(
    workbook,
    "translation_vehicles_title",
    t("reports.organization.vehicles.title")
  );

  supplyValue(
    workbook,
    "translation_vehicles_total",
    t("reports.organization.vehicles.total")
  );
  supplyValue(workbook, "data_vehicles_total", vehicles.total);
  if (vehicles.previousTotal) {
    const trend = calculateTrend(vehicles.total, vehicles.previousTotal);
    supplyValue(workbook, "data_vehicles_total_trend", trend);
  }

  supplyValue(
    workbook,
    "translation_vehicles_created",
    t("reports.organization.vehicles.created")
  );
  supplyValue(workbook, "data_vehicles_created", vehicles.created);

  supplyValue(
    workbook,
    "translation_vehicles_deleted",
    t("reports.organization.vehicles.deleted")
  );
  supplyValue(workbook, "data_vehicles_deleted", vehicles.deleted);

  supplyValue(
    workbook,
    "translation_vehicles_active",
    t("reports.organization.vehicles.active")
  );
  supplyValue(workbook, "data_vehicles_active", vehicles.active);

  supplyValue(
    workbook,
    "translation_vehicles_pending",
    t("reports.organization.vehicles.pending")
  );
  supplyValue(workbook, "data_vehicles_pending", vehicles.pendingNoTC);

  supplyValue(
    workbook,
    "translation_vehicles_pending5days",
    t("reports.organization.vehicles.pending5days")
  );
  supplyValue(
    workbook,
    "data_vehicles_pending5days",
    vehicles.pendingNoTC5Days
  );

  supplyValue(
    workbook,
    "translation_vehicles_pending30days",
    t("reports.organization.vehicles.pending30days")
  );
  supplyValue(
    workbook,
    "data_vehicles_pending30days",
    vehicles.pendingNoTC30Days
  );

  supplyValue(
    workbook,
    "translation_vehicles_declined",
    t("reports.organization.vehicles.declined")
  );
  supplyValue(workbook, "data_vehicles_declined", vehicles.declined);

  supplyValue(
    workbook,
    "translation_vehicles_allowed",
    t("reports.organization.vehicles.allowed")
  );
  supplyValue(workbook, "data_vehicles_allowed", vehicles.allowed);
  if (vehicles.previousAllowed) {
    const trend = calculateTrend(vehicles.allowed, vehicles.previousAllowed);
    supplyValue(workbook, "data_vehicles_allowed_trend", trend);
  }

  supplyValue(
    workbook,
    "translation_vehicles_denied",
    t("reports.organization.vehicles.denied")
  );
  supplyValue(workbook, "data_vehicles_denied", vehicles.denied);

  // Authorization
  supplyValue(
    workbook,
    "translation_authorization_title",
    t("reports.organization.authorization.title")
  );

  supplyValue(
    workbook,
    "translation_authorization_total",
    t("reports.organization.authorization.total")
  );
  supplyValue(workbook, "data_authorization_total", authorization.total);
  if (authorization.previousTotal) {
    const trend = calculateTrend(
      authorization.total,
      authorization.previousTotal
    );
    supplyValue(workbook, "data_authorization_total_trend", trend);
  }

  supplyValue(
    workbook,
    "translation_authorization_unique",
    t("reports.organization.authorization.unique")
  );
  supplyValue(workbook, "data_authorization_unique", authorization.uniqueTotal);
  if (authorization.previousUniqueTotal) {
    const trend = calculateTrend(
      authorization.uniqueTotal,
      authorization.previousUniqueTotal
    );
    supplyValue(workbook, "data_authorization_unique_trend", trend);
  }

  supplyValue(
    workbook,
    "translation_authorization_authorized",
    t("reports.organization.authorization.authorized")
  );
  supplyValue(
    workbook,
    "data_authorization_authorized",
    authorization.authorized
  );
  if (authorization.previousAuthorized) {
    const trend = calculateTrend(
      authorization.authorized,
      authorization.previousAuthorized
    );
    supplyValue(workbook, "data_authorization_authorized_trend", trend);
  }

  supplyValue(
    workbook,
    "translation_authorization_rejected",
    t("reports.organization.authorization.rejected")
  );
  supplyValue(workbook, "data_authorization_rejected", authorization.rejected);
  if (authorization.previousRejected) {
    const trend = calculateTrend(
      authorization.rejected,
      authorization.previousRejected
    );
    supplyValue(workbook, "data_authorization_rejected_trend", trend);
  }

  return workbook;
}

function supplyValue(
  workbook: Workbook,
  definedName: string,
  value: string | boolean | number | null | undefined
) {
  (workbook.definedName(definedName) as Cell).value(value);
}
