import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CloseModalIconButton } from "../../CloseModalIconButton";
import { Block as DenyIcon, Check as AllowIcon } from "@mui/icons-material";

interface DenyModalProps {
  modalOpen: boolean;
  onDenySelected: (deny: boolean) => void;
  onClose: () => void;
}

const DenyModal: FC<DenyModalProps> = ({
  modalOpen,
  onDenySelected,
  onClose,
}) => {
  const { t } = useTranslation();

  const onButtonClick = async (deny: boolean) => {
    onDenySelected(deny);
  };

  return (
    <Dialog open={modalOpen} onClose={onClose} maxWidth="xs">
      <DialogTitle>
        {t("associates.access.set")}
        <CloseModalIconButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
          <Button
            variant="outlined"
            startIcon={<AllowIcon sx={{ color: "green" }} />}
            onClick={() => onButtonClick(false)}
          >
            {t("associates.deny.set.allow")}
          </Button>
          <Button
            variant="outlined"
            startIcon={<DenyIcon sx={{ color: "red" }} />}
            onClick={() => onButtonClick(true)}
          >
            {t("associates.deny.set.deny")}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DenyModal;
