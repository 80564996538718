import { DialogContentText } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface DeleteModalProps {
  handleCancelDialog: () => void;
  handleDeleteDialog: () => void;
  isDialogOpened: boolean;
  count: number;
}

const DeleteModal: FC<DeleteModalProps> = ({
  handleCancelDialog,
  handleDeleteDialog,
  isDialogOpened,
  count,
}) => {
  const { t } = useTranslation();

  const getContent = () => {
    if (count > 1) {
      return t("vehicles.groups.delete.multiple");
    } else {
      return t("vehicles.groups.delete.single");
    }
  };

  return (
    <Dialog
      open={isDialogOpened}
      onClose={handleCancelDialog}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        {t("vehicles.groups.delete.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{getContent()}</DialogContentText>
        {count > 1 && (
          <DialogContentText>
            {t("vehicles.groups.selection.count", { count: count })}
          </DialogContentText>
        )}
        <DialogContentText>
          {t("vehicles.groups.delete.note")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancelDialog}
          variant="outlined"
          size="medium"
          color="secondary"
        >
          {t("cancel")}
        </Button>
        <Button
          data-cy="modal.delete.confirmation"
          onClick={handleDeleteDialog}
          variant="contained"
          size="medium"
          color="primary"
        >
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
