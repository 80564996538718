import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { ReactI18NextChild, Trans, useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";
import {
  useGetOrganizationQuery,
  useReactivateOrganizationMutation,
} from "../../redux/api/OrganizationApi";
import { dashboardRoute, isAdminUser } from "../navbar/routes";
import { formatTimestamp } from "../formatTimestamp";
import { baseApi } from "../../redux/api/baseApi";
import { store } from "../../redux/store";

const DeactivatedComponent = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();
  const { data, isLoading } = useGetOrganizationQuery(organization.publicId);
  const [reactivateOrganization] = useReactivateOrganizationMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { role } = useAuth();
  const admin = isAdminUser(role);

  const [restoreModalOpen, setRestoreModalOpen] = useState(false);

  const deletionTime = data?.deletionTime
    ? ` (${formatTimestamp(data.deletionTime)})`
    : "";

  const onRestore = () => {
    setRestoreModalOpen(true);
  };

  const onClose = () => {
    setRestoreModalOpen(false);
  };

  const onConfirm = async () => {
    setRestoreModalOpen(false);
    try {
      await reactivateOrganization(organization.publicId).unwrap();
      store.dispatch(baseApi.util.resetApiState());
      navigate(dashboardRoute.path);
      enqueueSnackbar(t("deactivated.organization.restore.success"), {
        variant: "success",
      });
    } catch (error: any) {
      enqueueSnackbar(t("deactivated.organization.restore.error"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
  };

  return !data?.deletionTime && !isLoading ? (
    <Navigate to={dashboardRoute.path} replace />
  ) : (
    <Wrapper>
      <Typography variant="h2">{t("deactivated.title")}</Typography>
      <Stack spacing={5}>
        <Typography>
          {t("deactivated.description", { date: deletionTime })}
        </Typography>
        {admin && (
          <Box>
            <Button onClick={onRestore} variant="contained" size="medium">
              {t("deactivated.organization.restore.button")}
            </Button>
          </Box>
        )}
      </Stack>
      {organization && (
        <Dialog
          open={restoreModalOpen}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            {t("deactivated.organization.restore.confirm.title")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Trans i18nKey="deactivated.organization.restore.confirm.description">
                <i>
                  {{ name: organization.name } as unknown as ReactI18NextChild}
                </i>
              </Trans>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              variant="outlined"
              size="medium"
              color="secondary"
            >
              {t("cancel")}
            </Button>
            <Button
              onClick={onConfirm}
              variant="contained"
              size="medium"
              color="primary"
            >
              {t("confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Wrapper>
  );
};

const Wrapper = styled((props: any) => <Container maxWidth="md" {...props} />)(
  () => ({
    marginTop: "3.5rem",
    textAlign: "center",
  })
);

export default DeactivatedComponent;
