import { Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { TablePageWrapper } from "../../../common/styles";
import { PageRequest } from "../../../common/pagination";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import { OrganizationReportListing } from "../../../redux/api/ReportsApi";

const pageSizeStorageKey = "organizationReportsTablePageSize";
const defaultPageSize = 10;

export type OrganizationReportsContext = [
  PageRequest<OrganizationReportListing>,
  (options: PageRequest<OrganizationReportListing>) => void
];

const ReportsComponent = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();
  const [queryOptions, setQueryOptions] = useState<
    PageRequest<OrganizationReportListing>
  >(() => {
    const preferredPageSize = Number(localStorage.getItem(pageSizeStorageKey));
    const size =
      isNaN(defaultPageSize) || preferredPageSize === 0
        ? defaultPageSize
        : preferredPageSize;
    return {
      page: 0,
      size,
      organizationId: organization.publicId,
    };
  });

  const onQueryOptionsChange = (
    options: PageRequest<OrganizationReportListing>
  ) => {
    setQueryOptions(options);
    localStorage.setItem(pageSizeStorageKey, `${options.size}`);
  };

  return (
    <TablePageWrapper>
      <Typography variant="h1">{t("reports")}</Typography>
      <Outlet context={[queryOptions, onQueryOptionsChange]} />
    </TablePageWrapper>
  );
};

export default ReportsComponent;
