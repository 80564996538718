import {
  ExternalAuthorizeEventResult,
  ExternalAuthorizeEventStatusReason,
} from "../../redux/api/ExternalAuthorizeEvent";
import { t } from "i18next";
import { Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { GridCellParams } from "@mui/x-data-grid";

interface StatusRow {
  externalLastLoginResult?: ExternalAuthorizeEventResult;
  externalLastLoginReason?: ExternalAuthorizeEventStatusReason;
}

export const renderStatusColumn = (
  params: GridCellParams<unknown, StatusRow>
) => {
  const { externalLastLoginResult, externalLastLoginReason } = params.row;

  let icon;
  let tooltip = "";

  switch (externalLastLoginResult) {
    case ExternalAuthorizeEventResult.FAIL:
      icon = <DoDisturbIcon color="error" />;
      tooltip = t("last.evaluation.result.denied");
      if (externalLastLoginReason) {
        const reason = t(`last.evaluation.result.${externalLastLoginReason}`);
        tooltip += `: ${reason}`;
      }
      break;
    case ExternalAuthorizeEventResult.SUCCESS:
      icon = <CheckCircleOutlineIcon color="success" />;
      tooltip = t("last.evaluation.result.allowed");
      break;
    case ExternalAuthorizeEventResult.UNUSED:
      icon = <HelpOutlineIcon />;
      tooltip = t("last.evaluation.result.unused");
      break;
    default:
      break;
  }

  return icon ? (
    <Tooltip title={tooltip}>
      <div>{icon}</div>
    </Tooltip>
  ) : (
    <></>
  );
};
