import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useFileUpload from "../../../../hooks/useFileUpload";
import {
  useImportVehiclesMutation,
  VehicleValidationErrors,
} from "../../../../redux/api/VehiclesApi";
import { useGetVehicleGroupsQuery } from "../../../../redux/api/VehicleGroupsApi";
import * as React from "react";
import ImportErrorDialog from "../../../modal/ImportErrorDialog";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useSelectedOrganization } from "../../../../hooks/useSelectedOrganization";
import UploadTooltip from "../../../vehicles/UploadTooltip";
import { Controller, useForm } from "react-hook-form";

interface FormValues {
  groupId: string;
  file: File;
}

const UploadButton = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [importVehicles, { isLoading }] = useImportVehiclesMutation();
  const organization = useSelectedOrganization();
  const { data } = useGetVehicleGroupsQuery({
    organizationId: organization.publicId,
    sortOrder: "asc",
    sortField: "name",
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<FormValues>();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [selectionModalOpen, setSelectionModalOpen] = useState(false);

  const [validationErrors, setValidationErrors] =
    useState<VehicleValidationErrors>({});

  const onErrorModalClose = () => {
    setErrorModalOpen(false);
  };

  const onSelectionModalClose = () => {
    setSelectionModalOpen(false);
  };

  const onUploadClick = () => {
    setSelectionModalOpen(true);
  };

  const onFileSelected = async (files: File[] | FileList) => {
    setValue("file", files[0]);
    handleSubmit(onSubmit)();
  };

  const onSubmit = async ({ groupId, file }: FormValues) => {
    try {
      const vehicles = await importVehicles({
        file,
        organizationId: organization.publicId,
        groupId,
      }).unwrap();
      onSelectionModalClose();
      enqueueSnackbar(
        t("upload.successful", {
          count: vehicles.length,
        }),
        {
          variant: "success",
        }
      );
    } catch (err: any) {
      let message = "upload.failed";
      if (err.data?.message) {
        if (err.data?.validationErrors) {
          setValidationErrors(err.data?.validationErrors);
          setErrorModalOpen(true);
        }
        message = err.data?.message;
      }
      enqueueSnackbar(t(message), {
        variant: "error",
        key: err.status,
        preventDuplicate: true,
      });
    }
  };

  const onFileUpload = async () => {
    const valid = await trigger();
    if (valid) {
      openDialog();
    }
  };

  const { openDialog } = useFileUpload({
    inputRef: fileInputRef,
    onFileSelected,
  });

  const groups = useMemo(() => data?.content || [], [data]);

  return (
    <div id="upload-groups-button">
      <UploadTooltip>
        <Button
          startIcon={<FileUploadIcon />}
          onClick={onUploadClick}
          variant="outlined"
          size="medium"
          color="secondary"
        >
          {t("upload.tooltip")}
        </Button>
      </UploadTooltip>
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv,.ldif"
        multiple={false}
        hidden
      />
      <ImportErrorDialog
        onClose={onErrorModalClose}
        open={errorModalOpen}
        entries={validationErrors}
      />
      <Dialog
        open={selectionModalOpen}
        onClose={onSelectionModalClose}
        aria-labelledby="upload-dialog-title"
      >
        <DialogTitle id="upload-dialog-title">
          {t("vehicles.groups.upload.title")}
        </DialogTitle>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent>
            <DialogContentText>
              {t("vehicles.groups.upload.description")}
            </DialogContentText>
            <Controller
              name="groupId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("validation.group.blank"),
                },
              }}
              render={({ field }) => (
                <FormControl
                  error={!!errors.groupId}
                  fullWidth
                  margin="normal"
                  required
                >
                  <InputLabel id="group-label">{t("vehicle.group")}</InputLabel>
                  <Select
                    id="group-select"
                    labelId="group-label"
                    label={t("vehicle.group")}
                    required
                    {...field}
                  >
                    {groups.map(({ publicId, name }) => (
                      <MenuItem key={publicId} value={publicId}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.groupId?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onSelectionModalClose}
              variant="outlined"
              size="medium"
              color="secondary"
            >
              {t("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              size="medium"
              onClick={onFileUpload}
              loading={isLoading}
              color="primary"
            >
              {t("upload.tooltip")}
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default UploadButton;
