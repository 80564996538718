import EditIcon from "@mui/icons-material/Edit";
import { Dialog, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import { CloseModalIconButton } from "../../CloseModalIconButton";
import ChangeDomainContent from "./ChangeDomainForm";
import { useCountAssociatesQuery } from "../../../redux/api/AssociatesApi";
import { useGetOrganizationQuery } from "../../../redux/api/OrganizationApi";

const ChangeDomain: FC = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();
  const { data: countData } = useCountAssociatesQuery(organization.publicId);
  const { data: organizationData } = useGetOrganizationQuery(
    organization.publicId
  );

  const [modalOpen, setModalOpen] = useState(false);

  const onClose = () => {
    setModalOpen(false);
  };

  const onEditClick = () => {
    if (organization) {
      setModalOpen(true);
    }
  };

  return (
    <>
      <Tooltip title={t("settings.organization.changedomain.title")}>
        <IconButton
          onClick={onEditClick}
          disabled={countData ? countData.count > 0 : true}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>

      {organizationData && (
        <Dialog
          open={modalOpen}
          onClose={onClose}
          maxWidth="xs"
          fullWidth
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            {t("settings.organization.changedomain.title")}
            <CloseModalIconButton onClick={onClose} />
          </DialogTitle>
          <ChangeDomainContent
            defaultOrganizationDomain={organizationData.emailDomain}
            onClose={onClose}
          />
        </Dialog>
      )}
    </>
  );
};

export default ChangeDomain;
