import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import {
  ExternalAuthorizeEventResult,
  ExternalAuthorizeEventStatusReason,
} from "../../redux/api/ExternalAuthorizeEvent";
import { Associate } from "../../redux/api/AssociatesApi";
import { TFunction } from "i18next";

export const translateAssociatesHeaders = (t: TFunction): LabelKeyObject[] => {
  return [
    { label: t("name"), key: "name" },
    { label: t("email.address"), key: "email" },
    {
      label: t("associate.activated"),
      key: "activated",
    },
    {
      label: t("associates.last.evaluation.result"),
      key: "evaluationResult",
    },
    { label: t("evaluation.reject.reason"), key: "evaluationResultReason" },
    { label: t("associates.allowAccess"), key: "deny" },
  ];
};

interface AssociateRow {
  name?: string;
  email?: string;
  activated?: string;
  evaluationResult?: ExternalAuthorizeEventResult;
  evaluationResultReason?: ExternalAuthorizeEventStatusReason;
  deny?: string;
}

export const mapAssociatesDataToCsv = (
  associates: Associate[],
  t: TFunction
): AssociateRow[] => {
  return associates.map((value): AssociateRow => {
    return {
      name: value.name,
      email: value.emailAddress,
      activated: t("associate.activatedStatus." + value.activated),
      evaluationResult: t(
        "associates.external.access.state." +
          value.externalLastLoginStatus?.result
      ),
      evaluationResultReason: t(
        (value.externalLastLoginStatus?.failReason &&
          "vehicle.calendarAccessRejection.reason." +
            value.externalLastLoginStatus?.failReason) ||
          ""
      ),
      deny: value.deny
        ? t("dashboard.associates.allowAccess.denied")
        : t("dashboard.associates.allowAccess.allowed"),
    };
  });
};
