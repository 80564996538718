import React, { FC } from "react";
import { VehicleDetail } from "../../../redux/api/VehiclesApi";
import { styled } from "@mui/system";
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "../../formatTimestamp";

interface VehicleCalendarAccessProps {
  data: VehicleDetail;
}

const VehicleCalendarAccess: FC<VehicleCalendarAccessProps> = ({ data }) => {
  const { t } = useTranslation();

  const renderRejections = () => (
    <Stack direction="column" spacing={2}>
      {data.vehicleRecentCalendarAccessRejections.map((rejection) => (
        <Stack direction="column">
          <Typography variant="body1" color={"text.primary"}>
            {formatTimestamp(rejection.time)}
            {" - "}
            {rejection.email}
          </Typography>
          <Typography variant="body1" color={"text.primary"}>
            {t(`vehicle.calendarAccessRejection.reason.${rejection.reason}`)}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );

  return (
    <StyledCard>
      <CardHeader
        title={
          <Typography color="text.secondary">
            {t("vehicleDetail.calendarAccessRecentRejections")}
          </Typography>
        }
      />
      <StyledCardContent>{renderRejections()}</StyledCardContent>
    </StyledCard>
  );
};

const StyledCard = styled((props: any) => <Card elevation={0} {...props} />)(
  () => ({
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  })
);

const StyledCardContent = styled(CardContent)(() => ({
  flex: 1,
}));

export default VehicleCalendarAccess;
