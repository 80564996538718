import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useMemo, useState } from "react";
import { CloseModalIconButton } from "../../../CloseModalIconButton";
import {
  DataGrid,
  getGridStringOperators,
  GridColumns,
  GridFilterModel,
  GridRowsProp,
  GridSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { getTableLocaleText } from "../../../table/getTableLocaleText";
import Pagination from "../../../pagination/Pagination";
import { Search as SearchIcon } from "@mui/icons-material";
import {
  Associate,
  useGetAssociatesQuery,
} from "../../../../redux/api/AssociatesApi";
import { Operator, PageRequest } from "../../../../common/pagination";
import { tableComponentProps } from "../../../../common/styles";
import Toolbar from "./Toolbar";
import { UserOrganization } from "../../../../redux/api/UserApi";
import { useRegisterAdminMutation } from "../../../../redux/api/AdminsApi";
import { useSnackbar } from "notistack";
import { useSelectedOrganization } from "../../../../hooks/useSelectedOrganization";

const pageSizeStorageKey = "addAdminTablePageSize";
const defaultPageSize = 10;

interface AssociateRow {
  id: string;
  name: string;
  emailAddress: string;
  isAdmin: boolean;
}

const AddAdmin: FC = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();

  const [modalOpen, setModalOpen] = useState(false);
  const onOpen = () => {
    setModalOpen(true);
  };
  const onClose = () => {
    setModalOpen(false);
  };

  const [selectedRow, setSelectedRow] = useState<AssociateRow>();

  const [registerAdmin] = useRegisterAdminMutation();
  const { enqueueSnackbar } = useSnackbar();
  const onRegisterAdmin = () => {
    if (selectedRow) {
      registerAdmin({
        email: selectedRow.emailAddress,
        name: selectedRow.name,
        organizationId: organization.publicId,
      })
        .unwrap()
        .then(() => {
          enqueueSnackbar(t("admin.add.success"), {
            variant: "success",
          });
          onClose();
        })
        .catch((error) => {
          if (error.status === 400) {
            enqueueSnackbar(t(`${error.data.message}`), {
              variant: "error",
            });
          }
        });
    }
  };

  return (
    <>
      <Tooltip title={t("admin.add.tooltip")}>
        <Button variant="outlined" color="secondary" onClick={onOpen}>
          {t("admin.add.title")}
        </Button>
      </Tooltip>

      <Dialog open={modalOpen} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {t("admin.add.title")}
          <CloseModalIconButton onClick={onClose} />
        </DialogTitle>
        <DialogContent>
          <Hint>{t("admin.add.hint")}</Hint>
          {selectedRow?.isAdmin && <Error>{t("admin.add.alreadyAdmin")}</Error>}
          <Content onRowSelected={setSelectedRow} organization={organization} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={!selectedRow || selectedRow.isAdmin}
            onClick={onRegisterAdmin}
          >
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Hint = styled(Typography)(() => ({
  marginBottom: "1rem",
}));

const Error = styled(Typography)(({ theme }) => ({
  marginBottom: "1rem",
  color: theme.palette.error.main,
}));

interface ContentProps {
  onRowSelected: (row?: AssociateRow) => void;
  organization: UserOrganization;
}

const Content: FC<ContentProps> = ({ onRowSelected, organization }) => {
  const { t } = useTranslation();

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  useEffect(() => {
    if (selectionModel.length > 0) {
      const row = rows.find((row) => row.id === selectionModel[0])!!;
      onRowSelected(row);
    } else {
      onRowSelected(undefined);
    }
  }, [selectionModel]);

  const [queryOptions, setQueryOptions] = useState<PageRequest<Associate>>(
    () => {
      const preferredPageSize = Number(
        localStorage.getItem(pageSizeStorageKey)
      );
      const size =
        isNaN(defaultPageSize) || preferredPageSize === 0
          ? defaultPageSize
          : preferredPageSize;
      return {
        page: 0,
        size,
        organizationId: organization.publicId,
      };
    }
  );

  const { data, isFetching, isLoading } = useGetAssociatesQuery(queryOptions);

  const mapDataToTable = (
    associates: Associate[]
  ): GridRowsProp<AssociateRow> =>
    associates?.map(({ publicId, name, emailAddress, admin, ...rest }) => ({
      ...rest,
      id: publicId,
      name: name!!,
      emailAddress: emailAddress!!,
      isAdmin: admin!!,
    }));

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "name",
        headerName: t("associate.name"),
        flex: 1,
        minWidth: 100,
        filterOperators: getGridStringOperators().filter(
          (op) => op.value === "contains"
        ),
      },
      {
        field: "emailAddress",
        headerName: t("associate.email"),
        flex: 1,
        minWidth: 100,
        filterOperators: getGridStringOperators().filter(
          (op) => op.value === "contains"
        ),
      },
    ],
    [t]
  );

  const rows = useMemo(() => {
    return data ? mapDataToTable(data?.content) : [];
  }, [data]);

  const localeText = getTableLocaleText();

  const onPageChange = (page: number) => {
    setQueryOptions({ ...queryOptions, page });
    setSelectionModel([]);
  };

  const onPageSizeChange = (size: number) => {
    setQueryOptions({ ...queryOptions, size });
    setSelectionModel([]);
    localStorage.setItem(pageSizeStorageKey, `${size}`);
  };

  const onSortModelChange = (sortModel: GridSortModel) => {
    const sort = sortModel.length === 0 ? undefined : sortModel[0];
    setQueryOptions({
      ...queryOptions,
      sortField: sort?.field as keyof Associate,
      sortOrder: sort?.sort,
    });
    setSelectionModel([]);
  };

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    const filter =
      filterModel.items.length === 0 ? undefined : filterModel.items[0];
    setQueryOptions({
      ...queryOptions,
      filterField: filter?.columnField as keyof Associate,
      filterOperator: filter?.operatorValue as Operator,
      filterValue: filter?.value,
    });
    setSelectionModel([]);
  };

  const sortModel = useMemo(() => {
    return queryOptions.sortField
      ? [
          {
            sort: queryOptions.sortOrder,
            field: queryOptions.sortField,
          },
        ]
      : [];
  }, [queryOptions.sortField, queryOptions.sortOrder]);

  const filterModel = useMemo(() => {
    const filter = queryOptions.filterField
      ? [
          {
            columnField: queryOptions.filterField,
            operatorValue: queryOptions.filterOperator,
            value: queryOptions.filterValue,
          },
        ]
      : [];
    return {
      items: filter,
    };
  }, [
    queryOptions.filterField,
    queryOptions.filterOperator,
    queryOptions.filterValue,
  ]);

  return (
    <SingleSelectionTable
      columns={columns}
      rows={rows}
      rowCount={data?.totalElements || 0}
      rowsPerPageOptions={[10, 20, 50]}
      page={queryOptions.page}
      onPageChange={onPageChange}
      pageSize={queryOptions.size}
      onPageSizeChange={onPageSizeChange}
      paginationMode="server"
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      sortingMode="server"
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      filterMode="server"
      selectionModel={selectionModel}
      onSelectionModelChange={setSelectionModel}
      components={{
        Toolbar,
        LoadingOverlay: LinearProgress,
        Pagination,
        OpenFilterButtonIcon: SearchIcon,
      }}
      localeText={localeText}
      loading={isFetching || isLoading}
      componentsProps={{
        ...tableComponentProps,
        panel: {
          placement: "top",
        },
      }}
      autoHeight
      hideFooterSelectedRowCount
    />
  );
};

const SingleSelectionTable = styled(DataGrid)(() => ({
  ".MuiDataGrid-cell:focus-within": {
    background: "none !important",
  },
}));

export default AddAdmin;
