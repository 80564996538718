import React from "react";
import { ReactComponent as SkodaLogo } from "../assets/brand/logo/skoda.svg";
import { ReactComponent as AudiLogo } from "../assets/brand/logo/audi.svg";
import { HOSTNAME_BRAND_MAPPING } from "./constants";

interface BrandingProps {
  name?: string;
  title?: string;
  primaryColor: string;
  logo?: React.FunctionComponent;
  bodyFont: string;
  headingFont: string;
  contacts: (code: string) => ContactsProps;
}

interface ContactsProps {
  phone?: string;
  email?: string;
  facebook: string;
  twitter: string;
  youtube: string;
  instagram: string;
}

interface LocalizedContacts {
  [language: string]: ContactsProps;
}

interface BrandingMap {
  [brand: string]: BrandingProps;
}

export const defaultBranding: BrandingProps = {
  primaryColor: "#555555",
  bodyFont: "'Roboto'",
  headingFont: "'Roboto'",
  contacts: (code) => skodaContacts[code],
};

const skodaBranding: BrandingProps = {
  name: "skoda",
  title: "Škoda",
  primaryColor: "#4ba72fff",
  logo: SkodaLogo,
  bodyFont: "'Skoda NEXT'",
  headingFont: "'Skoda NEXT'",
  contacts: (code) => skodaContacts[code],
};

const skodaContacts: LocalizedContacts = {
  "cs-CZ": {
    phone: "+420 800 600 000",
    email: "infoline@skoda-auto.cz",
    facebook: "https://www.facebook.com/skoda.cz",
    twitter: "https://twitter.com/skodacz",
    youtube: "https://www.youtube.com/user/SKODACeskarepublika",
    instagram: "https://www.instagram.com/skodacr/",
  },
  "en-GB": {
    phone: "03330 037 504",
    email: "customerservices@skoda.co.uk",
    facebook: "https://en-gb.facebook.com/Skoda.uk/",
    twitter: "https://twitter.com/skodauk?lang=en",
    youtube: "https://www.youtube.com/channel/UClzwtVyvEVZ5d29e0J1E5Zw",
    instagram: "https://www.instagram.com/skodauk/",
  },
};

const audiBranding: BrandingProps = {
  name: "audi",
  title: "Audi",
  primaryColor: "#f50537", // "Progressive Red"
  logo: AudiLogo,
  bodyFont: "'Audi Type'",
  headingFont: "'Audi Type Extended'",
  contacts: (code) => audiContacts[code],
};

const audiContacts: LocalizedContacts = {
  "cs-CZ": {
    phone: "800 19 00 50",
    email: "zakaznickecentrum@audi.cz",
    facebook: "https://www.facebook.com/AudiCzechRepublic",
    twitter: "https://twitter.com/AudiCzechRep",
    youtube: "https://www.youtube.com/AudiCzechRepublic",
    instagram: "https://instagram.com/audiczechrep",
  },
  "en-GB": {
    phone: "0800 279 2884",
    email: "digitalsupport.uk@audi.de",
    facebook: "https://www.facebook.com/audiofficial",
    twitter: "https://twitter.com/audiofficial?lang=en",
    youtube: "https://www.youtube.com/audi",
    instagram: "https://www.instagram.com/audiofficial/",
  },
};

const brandBrandingMapping: BrandingMap = {
  skoda: skodaBranding,
  audi: audiBranding,
};

export function resolveBranding() {
  if (HOSTNAME_BRAND_MAPPING) {
    const hostname = window.location.hostname;
    const hostnameBrandMapping = JSON.parse(HOSTNAME_BRAND_MAPPING);
    const resolvedBrand = hostnameBrandMapping[hostname];
    if (resolvedBrand) {
      const resolvedBranding = brandBrandingMapping[resolvedBrand];
      if (resolvedBranding) {
        return resolvedBranding as BrandingProps;
      } else {
        throw new Error(`Unsupported brand: ${resolvedBrand}`);
      }
    }
  }
  return defaultBranding;
}
