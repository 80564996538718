import {
  Box,
  Button,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import { useUpdateOrganizationMutation } from "../../../redux/api/OrganizationApi";
import { updateOrganizationName } from "../../../redux/slice/UserSettingsSlice";
import { validateSpecialCharacters } from "../../validateSpecialCharacters";

interface FormValues {
  name: string;
}

interface DialogFormProps {
  defaultOrganizationName?: string;
  onClose: () => void;
}

const RenameForm: FC<DialogFormProps> = ({
  defaultOrganizationName,
  onClose,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>();
  const [updateOrganization] = useUpdateOrganizationMutation();
  const { enqueueSnackbar } = useSnackbar();
  const organization = useSelectedOrganization();
  const dispatch = useDispatch();

  const onSubmit = async (data: FormValues) => {
    const trimmedOrganizationName = data.name.trim();
    try {
      await updateOrganization({
        organization: {
          name: trimmedOrganizationName,
          emailDomain: organization.emailDomain,
        },
        organizationId: organization.publicId,
      }).unwrap();
      onClose();

      dispatch(updateOrganizationName(trimmedOrganizationName));
      enqueueSnackbar(t("settings.organization.rename.success"), {
        variant: "success",
      });
    } catch (error: any) {
      handleError(error);
    }
  };

  const handleError = (error: any) => {
    if (error.status === 409) {
      setError("name", {
        type: "custom",
        message: t(`${error.data.message}`),
      });
    } else {
      enqueueSnackbar(t("settings.organization.rename.error"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <DialogContent>
        <TextField
          autoFocus
          focused
          fullWidth
          required
          label={t("settings.organization.name")}
          type="text"
          variant="standard"
          defaultValue={defaultOrganizationName}
          multiline
          error={!!errors.name}
          helperText={errors.name?.message}
          {...register("name", {
            validate: validateSpecialCharacters("name"),
            required: { value: true, message: t("validation.name.blank") },
          })}
          inputProps={{ maxLength: 255 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          size="medium"
          color="secondary"
        >
          {t("cancel")}
        </Button>
        <Button
          type="submit"
          data-cy="modal.rename.confirmation"
          variant="contained"
          size="medium"
          color="primary"
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default RenameForm;
