import { baseApi, OrganizationRequest } from "./baseApi";

export interface Organization {
  publicId: string;
  name: string;
  emailDomain: string;
  address?: string;
  phone?: string;
  deletionTime?: number;
}

export interface CreateOrganizationRequest {
  organizationName: string;
  emailDomain: string;
  privateOrganization?: boolean;
}

interface UpdateOrganizationRequest extends OrganizationRequest {
  organization: Partial<Organization>;
}

export const organizationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganization: builder.query<Organization, string | undefined>({
      query: (organizationId) => ({
        url: `/organization/${organizationId}`,
      }),
      providesTags: (_) => ["Organization"],
    }),
    createOrganization: builder.mutation<
      Organization,
      CreateOrganizationRequest
    >({
      query: (args) => ({
        url: "/organization/create-organization",
        method: "POST",
        body: {
          ...args,
          privateOrganization: args.privateOrganization ?? true,
        },
      }),
      invalidatesTags: (_) => ["Organization", "User"],
    }),
    updateOrganization: builder.mutation<void, UpdateOrganizationRequest>({
      query: ({ organization, organizationId }) => {
        const { ...patch } = organization;
        return {
          url: `/organization/${organizationId}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (_) => ["Organization", "User"],
    }),
    deleteOrganization: builder.mutation<void, string | undefined>({
      query: (organizationId) => ({
        url: `/organization/${organizationId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["Organization"],
    }),
    reactivateOrganization: builder.mutation<void, string | undefined>({
      query: (organizationId) => ({
        url: `/organization/${organizationId}/reactivate`,
        method: "POST",
      }),
      invalidatesTags: (_) => ["Organization"],
    }),
  }),
});

export const {
  useGetOrganizationQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useReactivateOrganizationMutation,
} = organizationApi;
