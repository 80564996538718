import { useSelector } from "react-redux";
import { authClient } from "../authConfig";
import { RootState } from "../redux/store";
import { useTranslation } from "react-i18next";
import { KeycloakLoginOptions } from "keycloak-js";

export const useAuth = () => {
  const {
    identity,
    technicalUser,
    client: { initialized, error },
    role,
  } = useSelector((state: RootState) => state.auth);
  const { i18n } = useTranslation();

  const localizedLogin = (options: KeycloakLoginOptions) => {
    authClient.login({ locale: i18n.language, ...options });
  };

  return {
    user: authClient.tokenParsed?.email,
    name: authClient.tokenParsed?.name,
    username: authClient.tokenParsed?.preferred_username,
    login: localizedLogin,
    logout: authClient.logout,
    register: authClient.register,
    authenticated: authClient.authenticated,
    initialized,
    roles: role.granted,
    error,
    identity,
    technicalUser,
    role: role.selected,
    preferencesLoaded: role.preferencesLoaded,
  };
};
