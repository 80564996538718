import { DialogContentText } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface UnassignGroupModalProps {
  handleCancelDialog: () => void;
  handleConfirmDialog: () => void;
  isDialogOpened: boolean;
  count: number;
}

const UnassignGroupModal: FC<UnassignGroupModalProps> = ({
  handleCancelDialog,
  handleConfirmDialog,
  isDialogOpened,
  count,
}) => {
  const { t } = useTranslation();

  const getContent = () => {
    if (count > 1) {
      return t("vehicles.unassignGroup.confirm.multiple");
    } else {
      return t("vehicles.unassignGroup.confirm");
    }
  };

  return (
    <Dialog
      open={isDialogOpened}
      onClose={handleCancelDialog}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        {t("vehicles.unassignGroup.confirm.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{getContent()}</DialogContentText>
        {count > 1 && (
          <DialogContentText>
            {`${t("selection.vehicle.count")}: ${count}`}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancelDialog}
          variant="outlined"
          size="medium"
          color="secondary"
        >
          {t("cancel")}
        </Button>
        <Button
          data-cy="modal.unassignGroup.confirmation"
          onClick={handleConfirmDialog}
          variant="contained"
          size="medium"
          color="primary"
        >
          {t("remove")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnassignGroupModal;
