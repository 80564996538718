import React, { FC, useEffect } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ActionButtonProps } from "./ActionButtonProps";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import { useLazyGetOrganizationReportDetailQuery } from "../../../redux/api/ReportsApi";
import { formatTimestamp } from "../../formatTimestamp";
import { downloadBlob } from "../../../redux/api/util";
import { exportReport } from "./exportReport";

export const DownloadButton: FC<ActionButtonProps> = ({ id }) => {
  const { t } = useTranslation();
  const label = t("reports.organization.download.tooltip");
  const organization = useSelectedOrganization();
  const [trigger, { data, isFetching }] =
    useLazyGetOrganizationReportDetailQuery();

  const onClick = () => {
    if (organization) {
      trigger({ id: id, organizationId: organization.publicId }, false);
    }
  };

  const downloadReport = (blob: Blob) => {
    const filename = `${formatTimestamp(data!.created)}.xlsx`;
    downloadBlob(blob, filename);
  };

  useEffect(() => {
    if (!isFetching && data) {
      exportReport(data).then(downloadReport);
    }
  }, [isFetching]);

  return (
    <GridActionsCellItem
      icon={
        <Tooltip title={label}>
          <FileDownloadIcon />
        </Tooltip>
      }
      label={label}
      onClick={onClick}
    />
  );
};
