import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import {
  vehiclesApprovals,
  vehiclesApprovalsHistory,
} from "../../navbar/routes";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";

const VehiclesApprovalTableToggle = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const view =
    pathname === vehiclesApprovalsHistory.path ? "history" : "vehicles";

  return (
    <ToggleButtonGroup color="primary" value={view} exclusive size="medium">
      <ToggleButton
        component={Link as any}
        value="vehicles"
        to={vehiclesApprovals.path}
      >
        {t("vehicles.approval.vehicles.tab")}
      </ToggleButton>
      <ToggleButton
        component={Link as any}
        value="history"
        to={vehiclesApprovalsHistory.path}
      >
        {t("vehicles.approval.history.tab")}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default VehiclesApprovalTableToggle;
