import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  TextField,
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseModalIconButton } from "../../CloseModalIconButton";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import {
  useGetVehicleGroupsQuery,
  VehicleGroup,
} from "../../../redux/api/VehicleGroupsApi";

interface AssignGroupModalProps {
  modalOpen: boolean;
  onGroupSelected: (group: VehicleGroup) => void;
  onClose: () => void;
}

const AssignGroupModal: FC<AssignGroupModalProps> = ({
  modalOpen,
  onGroupSelected,
  onClose,
}) => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();
  const { data: groups } = useGetVehicleGroupsQuery({
    organizationId: organization.publicId,
    sortOrder: "asc",
    sortField: "name",
  });

  const [selectedGroup, setSelectedGroup] = useState<VehicleGroup | null>(null);

  const onAssignClick = async () => {
    onGroupSelected(selectedGroup!);
  };

  return (
    <Dialog open={modalOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        {t("vehicles.group.assign")}
        <CloseModalIconButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          options={groups?.content || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option.publicId === value.publicId
          }
          onChange={(_, newValue: VehicleGroup | null) => {
            setSelectedGroup(newValue);
          }}
          renderInput={(params) => (
            <AutoCompleteTextField
              {...params}
              autoFocus
              required
              label={t("vehicles.group")}
            />
          )}
          clearText={t("autocomplete.clear")}
          noOptionsText={t("autocomplete.nooptions")}
          openText={t("autocomplete.open")}
          closeText={t("autocomplete.close")}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={!selectedGroup}
          onClick={onAssignClick}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AutoCompleteTextField = styled((props: any) => <TextField {...props} />)(
  () => ({
    marginTop: "0.5rem",
  })
);

export default AssignGroupModal;
