import { useEffect } from "react";
import { useLazyGetOrganizationBrandingQuery } from "../redux/api/OrganizationBrandingApi";
import { useApplicationColors } from "../common/ApplicationColorsContext";
import { useAuth } from "./useAuth";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { resolveBranding } from "../common/branding";

export const useOrganizationBrandingColor = () => {
  const organization = useSelector(
    (state: RootState) => state.userSettings.organization
  );
  const { identity, technicalUser, authenticated } = useAuth();
  const [trigger, { data }] = useLazyGetOrganizationBrandingQuery();

  const applicationColors = useApplicationColors();
  const primaryColorDefault = resolveBranding().primaryColor;

  useEffect(() => {
    if (
      authenticated &&
      identity === "verified" &&
      technicalUser === false &&
      organization
    ) {
      trigger(organization.publicId);
    } else {
      applicationColors.changeColors(primaryColorDefault);
      sessionStorage.setItem("OrganizationLogoFallback", "");
    }
  }, [identity, trigger, authenticated, organization]);

  useEffect(() => {
    if (data) {
      const color = data.uiColorCode ? data.uiColorCode : primaryColorDefault;
      const organizationLogoFallback = data.logoFallback
        ? data.logoFallback
        : "";
      applicationColors.changeColors(color);
      sessionStorage.setItem(
        "organizationLogoFallback",
        organizationLogoFallback
      );
    }
  }, [data]);
};
