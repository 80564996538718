import { GridApi, useGridApiContext } from "@mui/x-data-grid";
import { EditButton } from "./EditButton";
import { SaveButton } from "./SaveButton";
import { CancelButton } from "./CancelButton";
import { DeleteButton } from "./DeleteButton";
import { ViewAssociateGroupMembersButton } from "./ViewAssociateGroupMembersButton";

export const RowActions = (
  id: string,
  onDeleteClick?: (id: string) => void
) => {
  const apiRef = useGridApiContext<GridApi>();
  return apiRef.current.getRowMode(id) === "edit"
    ? [<SaveButton id={id} />, <CancelButton id={id} />]
    : [
        <EditButton id={id} />,
        <ViewAssociateGroupMembersButton groupId={id} />,
        <DeleteButton id={id} onClick={onDeleteClick} />,
      ];
};
