import { Associate } from "../../../redux/api/AssociatesApi";
import { GridRowModel, GridRowsProp } from "@mui/x-data-grid";
import { AssociateRow } from "./AssociatesTable";
import { AssociateGroup } from "../../../redux/api/AssociateGroupsApi";

export const mapToGridRow = (
  associates: Associate[]
): GridRowsProp<AssociateRow> =>
  associates?.map(
    ({ publicId, activated, externalLastLoginStatus, ...rest }) => ({
      ...rest,
      id: publicId,
      activated: activated ? "true" : "false",
      externalLastLoginResult: externalLastLoginStatus?.result,
      externalLastLoginReason: externalLastLoginStatus?.failReason,
    })
  );

export const mapGroup = (groups: AssociateGroup[]) => {
  return groups?.map((group) => {
    return { label: group.name, value: group.publicId };
  });
};

export const mapToEntity = (row: GridRowModel<AssociateRow>): Associate => ({
  publicId: row.id,
  name: row.name,
  deny: row.deny,
  activated: "true" === row.activated,
});

export const emailRegExp =
  /^(?=.{1,64}@)[A-Za-z0-9\-!#$%&'*+/=<^{|}~]+(\.[A-Za-z0-9\-!#$%&'*+/=<^{|}~]+)*@([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])*\.)*[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])*$/;
