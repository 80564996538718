import { Close as CloseIcon } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, IconButton, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/system";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Suspense, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { useGetOrganizationQuery } from "../../redux/api/OrganizationApi";
import { toggleMobileMenu } from "../../redux/slice/TourSlice";
import ThemeSwitch from "../theme-switch/ThemeSwitch";
import AccountMenu from "../account-menu/AccountMenu";
import NavigationLinks from "./NavigationLinks";
import { RootState } from "../../redux/store";
import HelpMenu from "../help/HelpMenu";

const NavbarActions = () => {
  const { user, technicalUser, identity } = useAuth();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const organization = useSelector(
    (state: RootState) => state.userSettings.organization
  );
  const { data } = useGetOrganizationQuery(
    !user || technicalUser === true || identity !== "verified" || !organization
      ? skipToken
      : organization.publicId
  );

  const [open, setOpen] = useState(false);

  const onMenuClick = () => {
    setOpen(true);
    dispatch(toggleMobileMenu(true));
  };

  const onClose = useCallback(() => {
    setOpen(false);
    dispatch(toggleMobileMenu(false));
  }, [dispatch]);

  useEffect(() => {
    if (desktop) {
      onClose();
    }
  }, [desktop, onClose]);

  return (
    <Suspense fallback={<></>}>
      <ThemeSwitch />
      {user && (
        <>
          {!data?.deletionTime && (
            <>
              <MobileMenu
                id="open-menu-button"
                aria-label="Open menu"
                onClick={onMenuClick}
              >
                <MenuIcon />
              </MobileMenu>
              <MobileDrawer
                id="mobile-drawer"
                anchor="top"
                open={open}
                onClose={onClose}
              >
                <CloseButton aria-label="Close menu" onClick={onClose}>
                  <CloseIcon />
                </CloseButton>
                <NavigationLinks onNavigate={onClose} />
              </MobileDrawer>
            </>
          )}
          <AccountMenu />
        </>
      )}
      <HelpMenu />
    </Suspense>
  );
};

const MobileMenu = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const CloseButton = styled(IconButton)(() => ({
  position: "absolute",
  right: "1rem",
  top: "1rem",
  zIndex: 1,
}));

const MobileDrawer = styled(Drawer)(() => ({
  "& .MuiPaper-root": {
    height: "100%",
    padding: "1.5rem",
  },
}));

export default NavbarActions;
