import { Box } from "@mui/material";
import { styled } from "@mui/system";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import React, { Dispatch, FC, SetStateAction } from "react";
import ExpandContainerButton from "../../../common/small-screen/ExpandContainerButton";
import ResetColumnVisibilityButton from "../../table/ResetColumnVisibilityButton";
import VehiclesApprovalTableToggle from "./VehiclesApprovalTableToggle";

interface ToolbarProps {
  setFilterButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  setDisplayAll: Dispatch<SetStateAction<boolean>>;
  displayAll: boolean;
}

const Toolbar: FC<ToolbarProps> = ({
  setFilterButtonEl,
  displayAll,
  setDisplayAll,
}) => (
  <Wrapper>
    <Box>
      <VehiclesApprovalTableToggle />
    </Box>
    <TableActions>
      <ResetColumnVisibilityButton />
      <GridToolbarFilterButton ref={setFilterButtonEl} />
      <ExpandContainerButton
        setDisplayAll={setDisplayAll}
        displayAll={displayAll}
      />
    </TableActions>
  </Wrapper>
);

const Wrapper = styled(GridToolbarContainer)(({ theme }) => ({
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  marginBottom: "1rem",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));

const TableActions = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}));

export default Toolbar;
