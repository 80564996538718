import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import { useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";
import { useCreateAssociateMutation } from "../../redux/api/AssociatesApi";
import { useGetAssociateGroupsQuery } from "../../redux/api/AssociateGroupsApi";
import { validateSpecialCharacters } from "../validateSpecialCharacters";
import { emailRegExp } from "./table/AssociateHelpers";

interface FormValues {
  name: string;
  emailAddress: string;
  groupId?: string;
}

const CreateAssociate = () => {
  const { t } = useTranslation();
  const router = useNavigate();
  const [createAssociate] = useCreateAssociateMutation();
  const organization = useSelectedOrganization();
  const { data } = useGetAssociateGroupsQuery({
    organizationId: organization.publicId,
    sortOrder: "asc",
    sortField: "name",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm<FormValues>({
    defaultValues: { groupId: "" },
  });

  const onSubmit = async ({ emailAddress, name, groupId }: FormValues) => {
    try {
      await createAssociate({
        associate: {
          emailAddress,
          name,
        },
        groupId: groupId,
        organizationId: organization.publicId,
      }).unwrap();
      router("/associates");
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (errorResponse: any) => {
    if (errorResponse.status === 409) {
      setError("emailAddress", {
        type: "custom",
        message: t(`${errorResponse.data.message}`),
      });
    }
  };

  const cancelOnClick = () => {
    router("/associates");
  };

  const groups = useMemo(() => data?.content || [], [data]);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const input = inputRef.current;
    if (!input) return;

    const defaultValue = `@${organization.emailDomain}`;
    let oldValue = defaultValue;

    const handleInput = () => {
      const inputValue = input.value;
      const newValue = inputValue.endsWith(defaultValue)
        ? inputValue
        : inputValue
        ? oldValue
        : defaultValue;
      oldValue = newValue;
      input.value = newValue;

      const caretPosition = Math.min(
        input.selectionStart!,
        newValue.length - defaultValue.length
      );
      input.setSelectionRange(caretPosition, caretPosition);
    };
    handleInput();
    input.addEventListener("input", handleInput);
    return () => input.removeEventListener("input", handleInput);
  }, [inputRef]);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            required
            margin="normal"
            fullWidth
            label={t("associate.name")}
            type="text"
            autoComplete="name"
            error={!!errors.name}
            autoFocus
            multiline
            helperText={errors.name?.message}
            {...register("name", {
              validate: validateSpecialCharacters("name"),
              required: { value: true, message: t("validation.name.blank") },
            })}
            inputProps={{ maxLength: 255 }}
          />
          <TextField
            required
            margin="normal"
            fullWidth
            id="email"
            label={t("associate.email")}
            autoComplete="email"
            error={!!errors.emailAddress}
            helperText={errors.emailAddress?.message}
            multiline
            {...register("emailAddress", {
              required: {
                value: true,
                message: t("validation.email.blank"),
              },
              pattern: {
                value: emailRegExp,
                message: t("validation.email.invalid"),
              },
            })}
            inputProps={{ maxLength: 255 }}
            inputRef={inputRef}
          />
          <Controller
            name="groupId"
            control={control}
            render={({ field }) => (
              <FormControl error={!!errors.groupId} fullWidth margin="normal">
                <InputLabel id="group-label">{t("associate.group")}</InputLabel>
                <Select
                  id="group-select"
                  labelId="group-label"
                  label={t("associate.group")}
                  {...field}
                >
                  <MenuItem value="">{t("none")}</MenuItem>
                  {groups.map(({ publicId, name }) => (
                    <MenuItem key={publicId} value={publicId}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.groupId?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("create")}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 0, mb: 2 }}
            onClick={cancelOnClick}
          >
            {t("cancel")}
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default CreateAssociate;
