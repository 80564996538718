import { useState } from "react";
import { ComputedDatum, ResponsivePie } from "@nivo/pie";
import { useTranslation } from "react-i18next";
import { byActivatedTheme, useNivoTheme } from "../../../../common/theme";
import AssociatesModal from "./AssociatesModal";
import { CountByActivated } from "../../../../common/pagination";
import { Box } from "@mui/material";

interface AssociateByActivatedChartDatum {
  id: string;
  label: string;
  value: number;
  activated: boolean;
}

const AssociatesByActivated = (props: { data?: CountByActivated }) => {
  const { t } = useTranslation();
  const titleActive = t("dashboard.associates.activation.active");
  const titleAuto = t("dashboard.associates.activation.auto");
  const countActive = props.data?.active ?? 0;
  const countAuto = props.data?.auto ?? 0;

  const chartData: AssociateByActivatedChartDatum[] = [
    {
      id: titleActive,
      label: `${titleActive} (${countActive})`,
      value: countActive,
      activated: true,
    },
    {
      id: titleAuto,
      label: `${titleAuto} (${countAuto})`,
      value: countAuto,
      activated: false,
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalActive, setModalActive] = useState(false);

  const openModal = (activated: boolean) => {
    setModalActive(activated);
    setModalOpen(true);
  };

  return (
    <>
      <Box textAlign={"center"}>
        {t("dashboard.associates.activation.title")}
      </Box>
      <ResponsivePie
        margin={{ top: 10, right: 0, bottom: 80, left: 0 }}
        data={chartData}
        animate={true}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        activeInnerRadiusOffset={6}
        layers={["arcs", "legends"]}
        colors={byActivatedTheme.palette.pie}
        theme={useNivoTheme()}
        onClick={(datum: ComputedDatum<AssociateByActivatedChartDatum>) => {
          openModal(datum.data.activated);
        }}
        legends={[
          {
            anchor: "bottom-left",
            direction: "column",
            symbolShape: "circle",
            itemWidth: 100,
            itemHeight: 20,
            translateY: 50,
            translateX: 30,
            onClick: (d) => {
              openModal(d.id === titleActive);
            },
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 0.5,
                },
              },
            ],
          },
        ]}
      />
      {modalOpen && (
        <AssociatesModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          subTitle={modalActive ? titleActive : titleAuto}
          filterField="activated"
          filterValue={modalActive.toString()}
        />
      )}
    </>
  );
};

export default AssociatesByActivated;
