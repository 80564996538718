import { FC } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { Article as DetailIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ActionButtonProps } from "./ActionButtonProps";

export const DetailButton: FC<ActionButtonProps> = ({ id }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const label = t("detail");

  const onClick = () => {
    navigate(`/reports/detail/${id}`);
  };

  return (
    <GridActionsCellItem
      icon={
        <Tooltip title={label}>
          <DetailIcon />
        </Tooltip>
      }
      label={label}
      onClick={onClick}
    />
  );
};
