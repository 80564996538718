import Keycloak, { KeycloakError } from "keycloak-js";
import {
  OAUTH_PROVIDER_URL,
  OAUTH_PROVIDER_REALM,
  OAUTH_PROVIDER_CLIENT_ID,
} from "./common/constants";
import {
  authSuccess,
  clientError,
  clientReady,
  clientStateChange,
  resetAuth,
} from "./redux/slice/AuthSlice";
import { store } from "./redux/store";
import { baseApi } from "./redux/api/baseApi";
import { enqueueSnackbar } from "notistack";
import { getI18n } from "react-i18next";
import { resolveBranding } from "./common/branding";

export type AuthClientEvent =
  | "onReady"
  | "onInitError"
  | "onAuthSuccess"
  | "onAuthError"
  | "onAuthRefreshSuccess"
  | "onAuthRefreshError"
  | "onAuthLogout"
  | "onTokenExpired";

export const authClient = new Keycloak({
  url: OAUTH_PROVIDER_URL,
  realm: OAUTH_PROVIDER_REALM || "master",
  clientId: OAUTH_PROVIDER_CLIENT_ID || "",
});
const origCreateLoginUrl = authClient.createLoginUrl;
authClient.createLoginUrl = function (options) {
  let url = origCreateLoginUrl.apply(this, [options]);

  const branding = resolveBranding();
  if (branding.name) {
    url += "&brand=" + encodeURIComponent(branding.name);
  }

  return url;
};

const isUserAuthenticated = (authClient: Keycloak) => {
  return !!authClient.idToken && !!authClient.token;
};

export const onAuthEvent =
  (eventType: AuthClientEvent) => (args?: KeycloakError | boolean) => {
    try {
      switch (eventType) {
        case "onInitError":
          const payload = typeof args === "object" ? args?.error : undefined;
          store.dispatch(clientError(payload));
          break;

        case "onReady":
          store.dispatch(clientReady(authClient.tokenParsed?.roles));
          break;

        case "onAuthSuccess":
          store.dispatch(authSuccess(authClient.tokenParsed?.roles));
          break;

        case "onAuthRefreshError":
          // The redirect to the Login screen is handled by the OAuth client lib + ProtectedRoute
          // Plus, we reset the store if the re-authentication failed
          store.dispatch(resetAuth());
          store.dispatch(baseApi.util.resetApiState());
          enqueueSnackbar(getI18n().t("authentication.expired"), {
            preventDuplicate: true,
            variant: "error",
          });
          break;

        case "onTokenExpired":
          // Refresh Keycloak token
          authClient.updateToken(5);
          break;

        default:
          break;
      }

      store.dispatch(
        clientStateChange({
          initialized: eventType !== "onInitError",
          // Check if user is authenticated
          authenticated: isUserAuthenticated(authClient),
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

// Attach Keycloak listeners
authClient.onReady = onAuthEvent("onReady");
authClient.onAuthSuccess = onAuthEvent("onAuthSuccess");
authClient.onAuthError = onAuthEvent("onAuthError");
authClient.onAuthRefreshSuccess = onAuthEvent("onAuthRefreshSuccess");
authClient.onAuthRefreshError = onAuthEvent("onAuthRefreshError");
authClient.onAuthLogout = onAuthEvent("onAuthLogout");
authClient.onTokenExpired = onAuthEvent("onTokenExpired");

export const initAuthClient = () => {
  authClient
    .init({
      onLoad: "check-sso",
      pkceMethod: "S256",
    })
    .catch(onAuthEvent("onInitError"));
};
