import { People as PeopleIcon } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { associatesRoute } from "../../../navbar/routes";
import { Associate } from "../../../../redux/api/AssociatesApi";
import { AssociatesContext } from "../../AssociatesComponent";

interface ViewGroupMembersButtonProps {
  groupId: string;
}

export const ViewAssociateGroupMembersButton: FC<
  ViewGroupMembersButtonProps
> = ({ groupId }) => {
  const { t } = useTranslation();
  const label = t("associates.groups.viewMembers");
  const router = useNavigate();
  const [queryOptions, setQueryOptions] = useOutletContext<AssociatesContext>();

  const onClick = () => {
    router(`${associatesRoute.path}`);
    setQueryOptions({
      ...queryOptions,
      filterField: "groupId" as keyof Associate,
      filterOperator: "is",
      filterValue: groupId,
    });
  };

  return (
    <GridActionsCellItem
      icon={
        <Tooltip title={label}>
          <PeopleIcon />
        </Tooltip>
      }
      label={label}
      onClick={onClick}
    />
  );
};
