import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Routing from "./components/Routing";
import { CssBaseline } from "@mui/material";
import "./assets/styles/robotoFonts.scss";
import "./assets/styles/skodaNextFonts.scss";
import "./assets/styles/audiTypeFonts.scss";
import "./i18n";
import Tour from "./components/tour/Tour";
import ApplicationColorsProvider from "./common/ApplicationColorsContext";
import { initAuthClient } from "./authConfig";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApplicationColorsProvider>
        <CssBaseline />
        <BrowserRouter>
          <Suspense fallback={<></>}>
            <Tour />
          </Suspense>
          <Routing />
        </BrowserRouter>
      </ApplicationColorsProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

initAuthClient();
