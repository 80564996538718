import React, { useEffect, useMemo, useState } from "react";
import { Operator, Page, PageRequest } from "../../../common/pagination";
import {
  Admin,
  useDeleteAdminByIdMutation,
  useDeleteAdminByIdNoInvalidationMutation,
  useGetAdminsQuery,
} from "../../../redux/api/AdminsApi";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  getGridStringOperators,
  GridActionsCellItem,
  GridColumns,
  GridFilterModel,
  GridRowParams,
  GridRowsProp,
  GridSortModel,
} from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { LinearProgress, Tooltip } from "@mui/material";
import {
  DeleteForever as DeleteIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { tableComponentProps } from "../../../common/styles";
import Pagination from "../../pagination/Pagination";
import DeleteModal from "./DeleteModal";
import Toolbar from "./Toolbar";
import { getTableLocaleText } from "../../table/getTableLocaleText";
import { useAuth } from "../../../hooks/useAuth";
import { resetAuth } from "../../../redux/slice/AuthSlice";
import { baseApi } from "../../../redux/api/baseApi";
import { useAppDispatch } from "../../../redux/store";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";

const pageSizeStorageKey = "adminsTablePageSize";
const defaultPageSize = 10;

interface AdminsTableProps {
  admins?: Page<Admin[]>;
}

export interface AdminRow {
  id: string;
  name?: string;
  emailAddress?: string;
}

const AdminsTable: React.FC<AdminsTableProps> = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();

  const { user, logout } = useAuth();
  const dispatch = useAppDispatch();
  const [queryOptions, setQueryOptions] = useState<PageRequest<Admin>>(() => {
    const preferredPageSize = Number(localStorage.getItem(pageSizeStorageKey));
    const size =
      isNaN(defaultPageSize) || preferredPageSize === 0
        ? defaultPageSize
        : preferredPageSize;
    return {
      page: 0,
      size,
      organizationId: organization.publicId,
    };
  });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<AdminRow | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const [deleteAdmin] = useDeleteAdminByIdMutation();
  const [deleteAdminNoInvalidation] =
    useDeleteAdminByIdNoInvalidationMutation();

  const { data, isFetching, isLoading } = useGetAdminsQuery(queryOptions);

  const mapDataToTable = (admins: Admin[]): GridRowsProp<AdminRow> =>
    admins?.map(({ publicId, email, ...rest }) => ({
      ...rest,
      emailAddress: email,
      id: publicId,
    }));

  const onDeleteClick = () => {
    setDeleteModalOpen(true);
  };

  const onConfirmDelete = async () => {
    setDeleteModalOpen(false);
    try {
      if (selectedRow) {
        const id = selectedRow.id;
        if (user === selectedRow.emailAddress) {
          await deleteAdminNoInvalidation({
            id,
            organizationId: organization.publicId,
          }).unwrap();
          logout({ redirectUri: `${window.location.origin}` }).then(() => {
            dispatch(resetAuth());
            dispatch(baseApi.util.resetApiState());
          });
        } else {
          await deleteAdmin({
            id,
            organizationId: organization.publicId,
          }).unwrap();
        }
      }
    } catch (error: any) {
      enqueueSnackbar(t(error.data.message), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
    setSelectedRow(null);
  };

  const onCancelDialog = () => {
    setDeleteModalOpen(false);
  };

  const renderActionsSection = (params: GridRowParams) => {
    const deleteLabel = t("delete");

    return [
      <GridActionsCellItem
        icon={
          <Tooltip title={deleteLabel}>
            <DeleteIcon onClick={() => onDeleteClick()} />
          </Tooltip>
        }
        label={deleteLabel}
        onClick={() => {
          setDeleteModalOpen(!deleteModalOpen);
          setSelectedRow(params.row);
        }}
        color="primary"
      />,
    ];
  };

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "name",
        headerName: t("admin.name"),
        flex: 1,
        minWidth: 100,
        filterOperators: getGridStringOperators().filter(
          (op) => op.value === "contains"
        ),
      },
      {
        field: "emailAddress",
        headerName: t("admin.email"),
        flex: 1,
        minWidth: 100,
        filterOperators: getGridStringOperators().filter(
          (op) => op.value === "contains"
        ),
      },
      {
        field: "actions",
        headerName: t("table.columns.actions"),
        type: "actions",
        minWidth: 120,
        getActions: renderActionsSection,
      },
    ],
    [t]
  );

  const rows = useMemo(() => {
    return data ? mapDataToTable(data?.content) : [];
  }, [data]);

  const localeText = getTableLocaleText();

  const onPageChange = (page: number) => {
    setQueryOptions({ ...queryOptions, page });
  };

  const onPageSizeChange = (size: number) => {
    setQueryOptions({ ...queryOptions, size });
    localStorage.setItem(pageSizeStorageKey, `${size}`);
  };

  const onSortModelChange = (sortModel: GridSortModel) => {
    const sort = sortModel.length === 0 ? undefined : sortModel[0];
    setQueryOptions({
      ...queryOptions,
      sortField: sort?.field as keyof Admin,
      sortOrder: sort?.sort,
    });
  };

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    const filter =
      filterModel.items.length === 0 ? undefined : filterModel.items[0];
    setQueryOptions({
      ...queryOptions,
      filterField: filter?.columnField as keyof Admin,
      filterOperator: filter?.operatorValue as Operator,
      filterValue: filter?.value,
    });
  };

  const sortModel = useMemo(() => {
    return queryOptions.sortField
      ? [
          {
            sort: queryOptions.sortOrder,
            field: queryOptions.sortField,
          },
        ]
      : [];
  }, [queryOptions.sortField, queryOptions.sortOrder]);

  const filterModel = useMemo(() => {
    const filter = queryOptions.filterField
      ? [
          {
            columnField: queryOptions.filterField,
            operatorValue: queryOptions.filterOperator,
            value: queryOptions.filterValue,
          },
        ]
      : [];
    return {
      items: filter,
    };
  }, [
    queryOptions.filterField,
    queryOptions.filterOperator,
    queryOptions.filterValue,
  ]);

  useEffect(() => {
    if (organization) {
      setQueryOptions({
        ...queryOptions,
        organizationId: organization.publicId,
      });
    }
  }, [organization]);

  return (
    <>
      <DataGrid
        autoHeight={true}
        localeText={localeText}
        rows={rows}
        rowCount={data?.totalElements || 0}
        columns={columns}
        page={queryOptions.page}
        onPageChange={onPageChange}
        pageSize={queryOptions.size}
        onPageSizeChange={onPageSizeChange}
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        sortingMode="server"
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        filterMode="server"
        components={{
          Toolbar,
          LoadingOverlay: LinearProgress,
          Pagination,
          OpenFilterButtonIcon: SearchIcon,
        }}
        loading={isFetching || isLoading}
        disableSelectionOnClick
        componentsProps={tableComponentProps}
      />
      <DeleteModal
        isDialogOpened={deleteModalOpen}
        handleCancelDialog={onCancelDialog}
        handleDeleteDialog={onConfirmDelete}
      />
    </>
  );
};

export default AdminsTable;
