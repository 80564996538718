import { ReactComponent as FirstImage } from "../../../assets/infographics/business-process/first.svg";
import { ReactComponent as SecondImage } from "../../../assets/infographics/business-process/second.svg";
import { ReactComponent as ThirdImage } from "../../../assets/infographics/business-process/third.svg";
import { ReactComponent as ArrowImage } from "../../../assets/infographics/business-process/arrow.svg";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Container, useTheme } from "@mui/material";

const BusinessSection = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Wrapper>
      <Container maxWidth="md">
        <BusinessWrapper>
          <BusinessStep>
            <FirstStepImage color={theme.palette.primary.main} />
            <Title>{t("landing.business.registration.title")}</Title>
            <Description>
              {t("landing.business.registration.description")}
            </Description>
          </BusinessStep>

          <Separator>
            <FirstArrow />
          </Separator>

          <BusinessStep>
            <SecondStepImage color={theme.palette.primary.main} />
            <Title>{t("landing.business.import.title")}</Title>
            <Description>
              {t("landing.business.import.description")}
            </Description>
          </BusinessStep>

          <Separator>
            <SecondArrow />
          </Separator>

          <BusinessStep>
            <ThirdStepImage color={theme.palette.primary.main} />
            <Title>{t("landing.business.manage.title")}</Title>
            <Description>
              {t("landing.business.manage.description")}
            </Description>
          </BusinessStep>
        </BusinessWrapper>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled("article")(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  textAlign: "center",
  flex: "1 0 70vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  isolation: "isolate",
  position: "relative",
  zIndex: 1,

  "&:before": {
    content: "''",
    position: "absolute",
    inset: 0,
    zIndex: -1,
    backgroundImage:
      "url('https://skoda-brand.s3.amazonaws.com/2019/w21/getting-started-background-small.png')",
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    pointerEvents: "none",
    [theme.breakpoints.up("sm")]: {
      backgroundImage:
        "url('https://skoda-brand.s3.amazonaws.com/2019/w21/getting-started-background-medium.png')",
    },
    [theme.breakpoints.up("md")]: {
      backgroundImage:
        "url('https://skoda-brand.s3.amazonaws.com/2019/w21/getting-started-background-large.png')",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage:
        "url('https://skoda-brand.s3.amazonaws.com/2019/w21/getting-started-background.png')",
    },
  },
}));

const BusinessWrapper = styled("div")(({ theme }) => ({
  margin: "5rem 0",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
}));

const BusinessStep = styled("div")(() => ({
  flex: 1,
}));

const Separator = styled("div")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Title = styled("h2")(() => ({
  fontSize: "clamp(1.5rem, 10vw - 1.25rem, 1.7rem)",
  fontWeight: "bold",
  lineHeight: "1.3",
  textTransform: "uppercase",
  margin: "1.5rem 0 1rem",
}));

const Description = styled("p")(() => ({
  fontSize: "1.2rem",
  lineHeight: "1.4",
  fontWeight: 300,
}));

const FirstArrow = styled(ArrowImage)(({ theme }) => ({
  flexBasis: "216px",
}));

const SecondArrow = styled(ArrowImage)(({ theme }) => ({
  flexBasis: "216px",
}));

const FirstStepImage = styled(FirstImage)(() => ({
  filter: "drop-shadow(0 0 10px hsla(0 0% 100% / 0.4))",
  width: "200px",
}));
const SecondStepImage = styled(SecondImage)(() => ({
  filter: "drop-shadow(0 0 10px hsla(0 0% 100% / 0.4))",
  width: "200px",
}));
const ThirdStepImage = styled(ThirdImage)(() => ({
  filter: "drop-shadow(0 0 10px hsla(0 0% 100% / 0.4))",
  width: "200px",
}));

export default BusinessSection;
