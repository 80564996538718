import { FC } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { CloseModalIconButton } from "../CloseModalIconButton";
import { DialogContentText } from "@mui/material";

interface LicenseModalProps {
  licenseName: string;
  licenseText: string;
  isOpen: boolean;
  onClose: () => void;
}

const LicenseModal: FC<LicenseModalProps> = ({
  licenseName,
  licenseText,
  isOpen,
  onClose,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {licenseName}
        <CloseModalIconButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ whiteSpace: "pre-wrap" }}>
          {licenseText}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default LicenseModal;
