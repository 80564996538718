import { styled } from "@mui/system";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import Link from "@mui/material/Link";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { resolveBranding } from "../../common/branding";
import { useMemo } from "react";

export function Phone() {
  const { i18n } = useTranslation();
  const branding = resolveBranding();
  const phone = useMemo(
    () => branding.contacts(i18n.language).phone,
    [i18n.language]
  );

  return phone ? (
    <Wrapper>
      <Grid item>
        <PhoneIcon sx={{ color: "primary.main", display: "flex" }} />
      </Grid>
      <Grid item>
        <ContactLink href={`tel:${phone.replace(/\s+/g, "")}`}>
          {phone}
        </ContactLink>
      </Grid>
    </Wrapper>
  ) : (
    <></>
  );
}

export function Email() {
  const { i18n } = useTranslation();
  const branding = resolveBranding();
  const email = useMemo(
    () => branding.contacts(i18n.language).email,
    [i18n.language]
  );

  return email ? (
    <Wrapper>
      <Grid item>
        <EmailIcon sx={{ color: "primary.main", display: "flex" }} />
      </Grid>
      <Grid item>
        <ContactLink href={`mailto:${email}`}>{email}</ContactLink>
      </Grid>
    </Wrapper>
  ) : (
    <></>
  );
}

const ContactLink = styled((props: any) => <Link {...props} />)(() => ({
  color: "white",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const Wrapper = styled((props: any) => (
  <Grid container spacing={1} {...props} />
))(() => ({
  flexDirection: "row",
  alignItems: "center",
  wrap: "nowrap",
}));
