import {
  DeleteForever as DeleteIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { IconButton, LinearProgress, Tooltip } from "@mui/material";
import {
  DataGrid,
  getGridStringOperators,
  GridColumns,
  GridFilterModel,
  GridRenderEditCellParams,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Operator, Page, PageRequest } from "../../../../common/pagination";
import Pagination from "../../../pagination/Pagination";
import { getTableLocaleText } from "../../../table/getTableLocaleText";
import { useSelectedOrganization } from "../../../../hooks/useSelectedOrganization";
import {
  AssociateGroup,
  AssociateGroupListing,
  useDeleteAssociateGroupsByIdsMutation,
  useGetAssociateGroupsQuery,
  useUpdateAssociateGroupMutation,
} from "../../../../redux/api/AssociateGroupsApi";
import Toolbar from "./Toolbar";
import {
  tableComponentProps,
  TableContentWrapper,
} from "../../../../common/styles";
import { enqueueSnackbar } from "notistack";
import { RowActions } from "./RowActions";
import ConfirmUpdateModal from "../../../confirm-update-modal/ConfirmUpdateModal";
import { TableTextEditComponent } from "../../../table/TableTextEditComponent";
import DeleteModal from "./DeleteModal";
import { validateSpecialCharacters } from "../../../validateSpecialCharacters";
import InputErrorModal from "../../../modal/InputErrorModal";

const pageSizeStorageKey = "associateGroupsTablePageSize";
const defaultPageSize = 10;

interface GroupsTableProps {
  groups?: Page<AssociateGroup[]>;
}

export interface GroupRow {
  id: string;
  name: string;
  count: number;
}

export interface ActionButtonProps {
  id: string;
  onClick?: (id: string) => void;
}

interface UpdatePromise {
  newRow: GridRowModel<GroupRow>;
  oldRow: GridRowModel<GroupRow>;
  reject: (value: GridRowModel<GroupRow>) => void;
  resolve: (value: GridRowModel<GroupRow>) => void;
}

class InputError extends Error {}

const AssociateGroupsTable: React.FC<GroupsTableProps> = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();

  const [updatePromise, setUpdatePromiseArguments] = useState<UpdatePromise>();
  const [updateGroup] = useUpdateAssociateGroupMutation();
  const [deleteGroups] = useDeleteAssociateGroupsByIdsMutation();
  const [inputError, setInputError] = useState(false);
  const [inputErrorModalOpen, setInputErrorModalOpen] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [queryOptions, setQueryOptions] = useState<PageRequest<AssociateGroup>>(
    () => {
      const preferredPageSize = Number(
        localStorage.getItem(pageSizeStorageKey)
      );
      const size =
        isNaN(defaultPageSize) || preferredPageSize === 0
          ? defaultPageSize
          : preferredPageSize;
      return {
        page: 0,
        size,
        organizationId: organization.publicId,
      };
    }
  );

  const { data, isFetching, isLoading } =
    useGetAssociateGroupsQuery(queryOptions);

  const mapToEntity = (row: GridRowModel<GroupRow>): AssociateGroup => ({
    publicId: row.id,
    name: row.name,
  });

  const specialCharactersValidator = validateSpecialCharacters("name");
  const processRowUpdate = (
    newRow: GridRowModel<GroupRow>,
    oldRow: GridRowModel<GroupRow>
  ) =>
    new Promise<GridRowModel<GroupRow>>((resolve, reject) => {
      const validationResult = specialCharactersValidator(newRow.name);
      if (validationResult === true) {
        setInputError(false);
      } else {
        throw new InputError(validationResult);
      }
      setUpdatePromiseArguments({ resolve, reject, newRow, oldRow });
    });
  const onProcessRowUpdateError = (error: any) => {
    if (error instanceof InputError) {
      setInputError(true);
      setInputErrorMessage(error.message);
      setInputErrorModalOpen(true);
    }
  };

  const onDeleteClick = (id?: string) => {
    setDeleteModalOpen(true);
    if (id) {
      setSelectionModel([id]);
    }
  };

  const onConfirmDelete = () => {
    setDeleteModalOpen(false);
    try {
      deleteGroups({
        ids: selectionModel,
        organizationId: organization.publicId,
      }).unwrap();
    } catch (error: any) {
      enqueueSnackbar(t("delete.fail"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
    setSelectionModel([]);
  };

  const onCancelDelete = () => {
    setDeleteModalOpen(false);
  };

  const onConfirmUpdate = async () => {
    const { newRow, oldRow, reject, resolve } = updatePromise!;
    newRow.name = newRow.name.trim();
    try {
      await updateGroup({
        group: mapToEntity(newRow),
        organizationId: organization.publicId,
      }).unwrap();
      resolve(newRow);
    } catch (error: any) {
      if (error.status === 409) {
        const message = t(error.data.message, { name: newRow.name });
        setInputError(true);
        setInputErrorMessage(message);
        setInputErrorModalOpen(true);
      } else {
        enqueueSnackbar(t("table.failure.update"), {
          variant: "error",
          preventDuplicate: true,
          key: error.status,
        });
      }
      reject(oldRow);
    } finally {
      setUpdatePromiseArguments(undefined);
    }
  };

  const onCancelUpdate = () => {
    const { oldRow, resolve } = updatePromise!;
    resolve(oldRow);
    setUpdatePromiseArguments(undefined);
  };

  const getActions = useCallback(
    (params: GridRowParams) => RowActions(params.row.id, onDeleteClick),
    []
  );

  const renderTableActions = () => {
    return (
      <>
        {selectionModel.length > 0 && (
          <Tooltip title={t("bulkDelete")}>
            <IconButton onClick={() => onDeleteClick()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  };

  const columns: GridColumns = [
    {
      field: "name",
      headerName: t("name"),
      flex: 1,
      minWidth: 100,
      editable: true,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
      renderEditCell: (props: GridRenderEditCellParams) => (
        <TableTextEditComponent renderParams={props} maxLength={255} />
      ),
    },
    {
      field: "count",
      type: "number",
      headerName: t("associates.groups.associates"),
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "actions",
      headerName: t("table.columns.actions"),
      type: "actions",
      minWidth: 120,
      getActions,
      renderHeader: renderTableActions,
    },
  ];

  const mapToGridRow = (
    groups: AssociateGroupListing[]
  ): GridRowsProp<GroupRow> =>
    groups?.map(({ publicId, itemsCount, ...rest }) => ({
      ...rest,
      id: publicId,
      count: itemsCount,
    }));

  const rows = useMemo(() => (data ? mapToGridRow(data?.content) : []), [data]);

  const localeText = getTableLocaleText();

  const onPageChange = (page: number) => {
    setQueryOptions({ ...queryOptions, page });
  };

  const onPageSizeChange = (size: number) => {
    setQueryOptions({ ...queryOptions, size });
    localStorage.setItem(pageSizeStorageKey, `${size}`);
  };

  const onSortModelChange = (sortModel: GridSortModel) => {
    const sort = sortModel.length === 0 ? undefined : sortModel[0];
    setQueryOptions({
      ...queryOptions,
      sortField: sort?.field as keyof AssociateGroup,
      sortOrder: sort?.sort,
    });
  };

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    const filter =
      filterModel.items.length === 0 ? undefined : filterModel.items[0];
    setQueryOptions({
      ...queryOptions,
      filterField: filter?.columnField as keyof AssociateGroup,
      filterOperator: filter?.operatorValue as Operator,
      filterValue: filter?.value,
    });
  };

  const sortModel = useMemo(() => {
    return queryOptions.sortField
      ? [
          {
            sort: queryOptions.sortOrder,
            field: queryOptions.sortField,
          },
        ]
      : [];
  }, [queryOptions.sortField, queryOptions.sortOrder]);

  const filterModel = useMemo(() => {
    const filter = queryOptions.filterField
      ? [
          {
            columnField: queryOptions.filterField,
            operatorValue: queryOptions.filterOperator,
            value: queryOptions.filterValue,
          },
        ]
      : [];
    return {
      items: filter,
    };
  }, [
    queryOptions.filterField,
    queryOptions.filterOperator,
    queryOptions.filterValue,
  ]);

  useEffect(() => {
    if (organization) {
      setQueryOptions({
        ...queryOptions,
        organizationId: organization.publicId,
      });
    }
  }, [organization]);

  return (
    <TableContentWrapper>
      <DataGrid
        localeText={localeText}
        editMode="row"
        rows={rows}
        rowCount={data?.totalElements || 0}
        columns={columns}
        page={queryOptions.page}
        onPageChange={onPageChange}
        pageSize={queryOptions.size}
        onPageSizeChange={onPageSizeChange}
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        sortingMode="server"
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        filterMode="server"
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar,
          LoadingOverlay: LinearProgress,
          Pagination,
          OpenFilterButtonIcon: SearchIcon,
        }}
        loading={isFetching || isLoading}
        componentsProps={tableComponentProps}
        disableSelectionOnClick
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={setSelectionModel}
        keepNonExistentRowsSelected
      />
      <DeleteModal
        isDialogOpened={deleteModalOpen}
        count={selectionModel.length}
        handleCancelDialog={onCancelDelete}
        handleDeleteDialog={onConfirmDelete}
      />
      <ConfirmUpdateModal
        open={!!updatePromise && !inputError}
        onClose={onCancelUpdate}
        onConfirm={onConfirmUpdate}
      />
      <InputErrorModal
        open={inputErrorModalOpen}
        onClose={() => setInputErrorModalOpen(false)}
        message={inputErrorMessage}
      />
    </TableContentWrapper>
  );
};

export default AssociateGroupsTable;
