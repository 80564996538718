import { baseApi, OrganizationRequest } from "./baseApi";

export interface OrganizationBranding {
  logoURL?: string;
  uiColorCode?: string;
  logoFallback?: string;
}

export interface OrganizationBrandingColor extends OrganizationRequest {
  uiColorCode?: string;
}

interface UploadLogoRequest extends OrganizationRequest {
  file: File;
}

export const organizationBrandingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadLogo: builder.mutation<void, UploadLogoRequest>({
      query: ({ file, organizationId }) => {
        const formData = new FormData();
        formData.append("logo", file);
        return {
          url: `/organization/${organizationId}/logo`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (_) => ["OrganizationBranding"],
    }),
    deleteLogo: builder.mutation<void, OrganizationRequest>({
      query: ({ organizationId }) => ({
        url: `/organization/${organizationId}/logo`,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["OrganizationBranding"],
    }),
    setOrganizationBranding: builder.mutation<void, OrganizationBrandingColor>({
      query: ({ organizationId, ...organizationBrandingInput }) => ({
        url: `/organization/${organizationId}/branding`,
        method: "PUT",
        body: organizationBrandingInput,
      }),
      invalidatesTags: (_) => ["OrganizationBranding"],
    }),
    getOrganizationBranding: builder.query<OrganizationBranding, string>({
      query: (organizationId) => ({
        url: `/organization/${organizationId}/branding`,
      }),
      providesTags: (_) => ["OrganizationBranding"],
    }),
  }),
});

export const {
  useUploadLogoMutation,
  useDeleteLogoMutation,
  useSetOrganizationBrandingMutation,
  useGetOrganizationBrandingQuery,
  useLazyGetOrganizationBrandingQuery,
} = organizationBrandingApi;
