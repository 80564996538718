import { useTranslation } from "react-i18next";
import ChartViewWrapper from "./ChartViewWrapper";
import {
  useCountAssociatesByActivatedQuery,
  useCountAssociatesByDenyQuery,
  useCountAssociatesQuery,
} from "../../../redux/api/AssociatesApi";
import AssociatesByDeny from "./associates/AssociatesByDeny";
import AssociatesByActivated from "./associates/AssociatesByActivated";
import { Grid, Typography } from "@mui/material";
import AssociatesByAccess from "./associates/AssociatesByAccess";
import { useGetAssociatesAccessStatisticsQuery } from "../../../redux/api/StatisticsApi";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";

const AssociatesView = () => {
  const organization = useSelectedOrganization();
  const { data: dataAll } = useCountAssociatesQuery(organization.publicId);
  const { data: dataByDeny } = useCountAssociatesByDenyQuery(
    organization.publicId
  );
  const { data: dataByActive } = useCountAssociatesByActivatedQuery(
    organization.publicId
  );
  const { data: dataByAccess } = useGetAssociatesAccessStatisticsQuery(
    organization.publicId
  );
  const { t } = useTranslation();
  const title = `${t("dashboard.associates.title")}: ${
    dataAll ? dataAll.count : "-"
  }`;

  return (
    <ChartViewWrapper title={title} align="center">
      {dataAll && dataAll?.count > 0 ? (
        <Grid container spacing={4} justifyContent="center">
          <Grid item lg={4} md={12} sx={{ height: 250 }}>
            <AssociatesByDeny data={dataByDeny} />
          </Grid>
          <Grid item lg={4} md={12} sx={{ height: 250 }}>
            <AssociatesByActivated data={dataByActive} />
          </Grid>
          <Grid item lg={4} md={12} sx={{ height: 250 }}>
            <AssociatesByAccess data={dataByAccess} />
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h1" align="center">
          {t("dashboard.nodata")}
        </Typography>
      )}
    </ChartViewWrapper>
  );
};

export default AssociatesView;
