import dependencies from "../../data/licenses.json";

interface InputDependencies {
  [dependency: string]: InputDependency;
}

interface InputDependency {
  publisher?: string;
  copyright?: string;
  licenses: string;
  licenseText?: string;
}

export interface Dependency {
  name: string;
  version: string;
  publisher?: string;
  copyright?: string;
  licenses: string;
  licenseText?: string;
}

export const dependenciesByLicenses = Object.entries(
  dependencies as InputDependencies
)
  .map(createDependencyObject)
  .sort(compareLicensesAndName)
  .reduce(groupByLicenses, {} as Record<string, Dependency[]>);

function createDependencyObject(entry: [string, InputDependency]) {
  const [dependency, rest] = entry;
  const separatorPosition = dependency.lastIndexOf("@");
  const name = dependency.substring(0, separatorPosition);
  const version = dependency.substring(separatorPosition + 1);
  const result: Dependency = {
    name: name,
    version: version,
    publisher: rest.publisher,
    copyright: rest.copyright,
    licenses: rest.licenses,
    licenseText: rest.licenseText,
  };
  return result;
}

function compareLicensesAndName(dep1: Dependency, dep2: Dependency) {
  if (dep1.licenses > dep2.licenses) {
    return 1;
  }
  if (dep1.licenses < dep2.licenses) {
    return -1;
  }
  if (dep1.name > dep2.name) {
    return 1;
  }
  if (dep1.name < dep2.name) {
    return -1;
  }
  return 0;
}

function groupByLicenses(
  array: Record<string, Dependency[]>,
  dependency: Dependency
) {
  const licenses = dependency.licenses;
  array[licenses] = array[licenses] ?? [];
  array[licenses].push(dependency);
  return array;
}
