export const organizationAdmin = "ROLE_organization_admin";
export const associate = "ROLE_ASSOCIATE";
export const approver = "ROLE_approver";

type AllowedRole = Role | "*";

export type Role =
  | typeof organizationAdmin
  | typeof associate
  | typeof approver;
export const allRoles: Role[] = [organizationAdmin, associate, approver];

interface RoutesConfig {
  page: string;
  path: string;
  allowedRoles: AllowedRole[];
  navMenuItem: boolean;
}

export const dashboardRoute: RoutesConfig = {
  page: "dashboard",
  path: "/dashboard",
  allowedRoles: [organizationAdmin, associate],
  navMenuItem: true,
};

export const associatesRoute: RoutesConfig = {
  page: "associates",
  path: "/associates",
  allowedRoles: [organizationAdmin],
  navMenuItem: true,
};

export const associatesGroupsRoute: RoutesConfig = {
  page: "groups",
  path: "/associates/groups",
  allowedRoles: [organizationAdmin],
  navMenuItem: false,
};

export const vehiclesRoute: RoutesConfig = {
  page: "vehicles",
  path: "/vehicles",
  allowedRoles: [organizationAdmin],
  navMenuItem: true,
};

export const vehiclesGroupsRoute: RoutesConfig = {
  page: "groups",
  path: "/vehicles/groups",
  allowedRoles: [organizationAdmin],
  navMenuItem: false,
};

export const vehiclesApprovals: RoutesConfig = {
  page: "vehicles",
  path: "/vehicles/approvals",
  allowedRoles: [approver],
  navMenuItem: true,
};

export const vehiclesApprovalsHistory: RoutesConfig = {
  page: "vehicles",
  path: "/vehicles/approvals/history",
  allowedRoles: [approver],
  navMenuItem: false,
};

export const accountRoute: RoutesConfig = {
  page: "account",
  path: "/account",
  allowedRoles: ["*"],
  navMenuItem: false,
};

export const deactivatedRoute: RoutesConfig = {
  page: "deactivated",
  path: "/deactivated",
  allowedRoles: [organizationAdmin, associate],
  navMenuItem: false,
};

export const authorizationRoute: RoutesConfig = {
  page: "authorization",
  path: "/authorization",
  allowedRoles: ["*"],
  navMenuItem: false,
};

export const licensesRoute: RoutesConfig = {
  page: "licenses",
  path: "/licenses",
  allowedRoles: ["*"],
  navMenuItem: false,
};

export const reportsRoute: RoutesConfig = {
  page: "reports",
  path: "/reports",
  allowedRoles: [organizationAdmin],
  navMenuItem: true,
};

// The order of the entries matters: more specific routes should come first. See the 'isPathAllowed' for more details.
export const routes: RoutesConfig[] = [
  dashboardRoute,
  associatesGroupsRoute,
  associatesRoute,
  vehiclesApprovalsHistory,
  vehiclesApprovals,
  vehiclesGroupsRoute,
  vehiclesRoute,
  reportsRoute,
  {
    page: "settings",
    path: "/organization-settings",
    allowedRoles: [organizationAdmin, associate],
    navMenuItem: true,
  },
  accountRoute,
  deactivatedRoute,
  authorizationRoute,
  licensesRoute,
];

export const isTechnicalUser = (userRole?: Role) => userRole === approver;

export const isAdminUser = (userRole?: Role) => userRole === organizationAdmin;

const hasRole = (route: RoutesConfig, userRole?: Role) =>
  route.allowedRoles.find((role) => userRole === role || role === "*");

export const getRoutesByRole = (userRole?: Role) =>
  routes.filter((route) => hasRole(route, userRole));

export const getMenuRoutesByRole = (userRole?: Role) =>
  getRoutesByRole(userRole).filter((route) => route.navMenuItem);

/**
 * Checks if the given role has access to the path: First, it validates the route and looks it up from the managed routes dictionary.
 * If its valid then it will try to see if the given role is allowed.
 * Note: the order of the route definition matters: if the least specific route comes first then it may match incorrectly
 * @param path a path to check
 * @param role to role to access with
 * @returns true if allowed, false otherwise
 */
export const isPathAllowed = (path: string, role?: Role) => {
  const route = routes.find((r) => path.startsWith(r.path));
  return route && hasRole(route, role);
};
