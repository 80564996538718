import { VehiclesReport } from "../../../../redux/api/ReportsApi";
import Grid from "@mui/material/Grid";
import React from "react";
import TrendComponent from "../TrendComponent";
import { useTranslation } from "react-i18next";
import GridItemLabel from "../GridItemLabel";

const VehiclesReportComponent = (props: { data: VehiclesReport }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <GridItemLabel xs={6} label={t("reports.organization.vehicles.total")} />
      <Grid item xs={6}>
        {props.data.total}
        {!!props.data.previousTotal && (
          <TrendComponent
            current={props.data.total}
            previous={props.data.previousTotal}
          />
        )}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.vehicles.created")}
      />
      <Grid item xs={6}>
        {props.data.created}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.vehicles.deleted")}
      />
      <Grid item xs={6}>
        {props.data.deleted}
      </Grid>
      <GridItemLabel xs={6} label={t("reports.organization.vehicles.active")} />
      <Grid item xs={6}>
        {props.data.active}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.vehicles.pending")}
      />
      <Grid item xs={6}>
        {props.data.pendingNoTC}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.vehicles.pending5days")}
      />
      <Grid item xs={6}>
        {props.data.pendingNoTC5Days}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.vehicles.pending30days")}
      />
      <Grid item xs={6}>
        {props.data.pendingNoTC30Days}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.vehicles.declined")}
      />
      <Grid item xs={6}>
        {props.data.declined}
      </Grid>
      <GridItemLabel
        xs={6}
        label={t("reports.organization.vehicles.allowed")}
      />
      <Grid item xs={6}>
        {props.data.allowed}
        {!!props.data.previousAllowed && (
          <TrendComponent
            current={props.data.allowed}
            previous={props.data.previousAllowed}
          />
        )}
      </Grid>
      <GridItemLabel xs={6} label={t("reports.organization.vehicles.denied")} />
      <Grid item xs={6}>
        {props.data.denied}
      </Grid>
    </Grid>
  );
};

export default VehiclesReportComponent;
