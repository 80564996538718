import { Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { TablePageWrapper } from "../../common/styles";
import { PageRequest } from "../../common/pagination";
import { Associate } from "../../redux/api/AssociatesApi";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";

const pageSizeStorageKey = "associateTablePageSize";
const defaultPageSize = 10;

export type AssociatesContext = [
  PageRequest<Associate>,
  (options: PageRequest<Associate>) => void
];

const AssociatesComponent = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();

  const [queryOptions, setQueryOptions] = useState<PageRequest<Associate>>(
    () => {
      const preferredPageSize = Number(
        localStorage.getItem(pageSizeStorageKey)
      );
      const size =
        isNaN(defaultPageSize) || preferredPageSize === 0
          ? defaultPageSize
          : preferredPageSize;
      return {
        page: 0,
        size,
        organizationId: organization.publicId,
      };
    }
  );

  const onQueryOptionsChange = (options: PageRequest<Associate>) => {
    setQueryOptions(options);
    localStorage.setItem(pageSizeStorageKey, `${options.size}`);
  };

  return (
    <TablePageWrapper data-testid={"associates"}>
      <Typography variant="h1">{t("associates")}</Typography>
      <Outlet context={[queryOptions, onQueryOptionsChange]} />
    </TablePageWrapper>
  );
};

export default AssociatesComponent;
