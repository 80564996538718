import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { TableTextField } from "../../common/styles";

interface TableTextEditComponentProps {
  renderParams: GridRenderEditCellParams;
  maxLength: number;
}

export const TableTextEditComponent: FC<TableTextEditComponentProps> = ({
  renderParams,
  maxLength,
}) => {
  const { id, value: valueProp, field } = renderParams;
  const [value, setValue] = useState(valueProp);
  const apiRef = useGridApiContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue,
      debounceMs: 200,
    });
    setValue(newValue);
  };

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  return (
    <TableTextField
      type="text"
      fullWidth
      value={value}
      onChange={handleChange}
      autoFocus
      inputProps={{ maxLength: maxLength }}
    />
  );
};
