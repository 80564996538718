import HelpIcon from "@mui/icons-material/Help";
import { IconButton, Menu, Tooltip } from "@mui/material";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import LicensesSection from "./LicensesSection";

const id = "help-button";
const menuId = "help-menu";

const HelpMenu = () => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const menuOpen = Boolean(anchorEl);

  const onCloseMenu = () => {
    setAnchorEl(undefined);
  };

  const onOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Tooltip title={t("header.help.tooltip")}>
        <IconButton
          id={id}
          aria-controls={menuOpen ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
          onClick={onOpenMenu}
        >
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={onCloseMenu}
        MenuListProps={{
          "aria-labelledby": id,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <LicensesSection onClick={onCloseMenu} />
      </Menu>
    </>
  );
};

export default HelpMenu;
