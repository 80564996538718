import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { vehiclesGroupsRoute } from "../navbar/routes";

const VehiclesTableToggle = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const view = pathname === "/vehicles" ? "vehicles" : "groups";

  return (
    <ToggleButtonGroup color="primary" value={view} exclusive size="medium">
      <ToggleButton component={Link as any} value="vehicles" to="/vehicles">
        {t("vehicles")}
      </ToggleButton>
      <ToggleButton
        component={Link as any}
        value="groups"
        to={vehiclesGroupsRoute.path}
      >
        {t("vehicles.groups")}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default VehiclesTableToggle;
