import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import { Box, Button, TextField } from "@mui/material";
import { validateSpecialCharacters } from "../../validateSpecialCharacters";
import { useCreateVehicleGroupMutation } from "../../../redux/api/VehicleGroupsApi";
import { vehiclesGroupsRoute } from "../../navbar/routes";

interface FormValues {
  name: string;
}

const CreateVehicleGroup = () => {
  const { t } = useTranslation();
  const router = useNavigate();
  const [createVehicleGroup] = useCreateVehicleGroupMutation();
  const organization = useSelectedOrganization();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>();

  const onSubmit = async (data: FormValues) => {
    const trimmedGroupName = data.name.trim();
    try {
      await createVehicleGroup({
        group: { name: trimmedGroupName },
        organizationId: organization.publicId,
      }).unwrap();
      router(vehiclesGroupsRoute.path);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (errorResponse: any) => {
    if (errorResponse.status === 409) {
      setError("name", {
        type: "custom",
        message: t("vehicles.groups.create.name.exists"),
      });
    }
  };

  const cancelOnClick = () => {
    router(vehiclesGroupsRoute.path);
  };

  return (
    <>
      <Container component={"main"} maxWidth={"xs"}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            required
            margin="normal"
            fullWidth
            label={t("name")}
            type="text"
            error={!!errors.name}
            autoFocus
            multiline
            helperText={errors.name?.message}
            {...register("name", {
              validate: validateSpecialCharacters("name"),
              required: { value: true, message: t("validation.name.blank") },
            })}
            inputProps={{ maxLength: 255 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("create")}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 0, mb: 2 }}
            onClick={cancelOnClick}
          >
            {t("cancel")}
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default CreateVehicleGroup;
