import {
  Check as CheckIcon,
  DeleteForever as DeleteIcon,
  Flaky as DenyAllIcon,
  Link as AssignGroupIcon,
  LinkOff as UnassignGroupIcon,
  Pending as BulkMenuIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  Grow,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridCellParams,
  GridColumns,
  GridFilterModel,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowModel,
  GridRowParams,
  GridSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  PrintableSwitch,
  tableComponentProps,
  TableContentWrapper,
} from "../../../common/styles";
import { Operator, Page } from "../../../common/pagination";
import {
  Associate,
  useActivateAssociateMutation,
  useActivateAssociatesMutation,
  useAssignAssociateGroupMutation,
  useDeleteAssociateByIdMutation,
  useDeleteAssociatesByIdsMutation,
  useDenyAssociateMutation,
  useDenyAssociatesMutation,
  useGetAssociatesQuery,
  useUnassignAssociateGroupMutation,
  useUpdateAssociateMutation,
} from "../../../redux/api/AssociatesApi";
import Pagination from "../../pagination/Pagination";
import { mapGroup, mapToEntity, mapToGridRow } from "./AssociateHelpers";
import DeleteModal from "./DeleteModal";
import { RowActions } from "./RowActions";
import Toolbar from "./Toolbar";
import { AssociatesContext } from "../AssociatesComponent";
import { useOutletContext } from "react-router-dom";
import ConfirmUpdateModal from "../../confirm-update-modal/ConfirmUpdateModal";
import {
  ExternalAuthorizeEventResult,
  ExternalAuthorizeEventStatusReason,
} from "../../../redux/api/ExternalAuthorizeEvent";
import { TableTextEditComponent } from "../../table/TableTextEditComponent";
import { getTableLocaleText } from "../../table/getTableLocaleText";
import useSmallScreenProperties from "../../../common/small-screen/useSmallScreenProperties";
import { reducedAssociatesVisibilityModel } from "../../../common/small-screen/visibilityModel";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import { renderStatusColumn } from "../../table/renderStatusColumn";
import { validateSpecialCharacters } from "../../validateSpecialCharacters";
import InputErrorModal from "../../modal/InputErrorModal";
import {
  AssociateGroup,
  useGetAssociateGroupsQuery,
} from "../../../redux/api/AssociateGroupsApi";
import AssociateGroupSelector from "../groups/table/AssociateGroupSelector";
import UnassignGroupModal from "./UnassignGroupModal";
import AssignGroupModal from "./AssignGroupModal";
import DenyModal from "./DenyModal";
import ActivateModal from "./ActivateModal";
import Button from "@mui/material/Button";

interface AssociatesTableProps {
  associates?: Page<Associate[]>;
}

export interface AssociateRow {
  id: string;
  name?: string;
  emailAddress?: string;
  deny?: boolean;
  activated?: "true" | "false";
  externalLastLoginResult?: ExternalAuthorizeEventResult;
  externalLastLoginReason?: ExternalAuthorizeEventStatusReason;
  group?: AssociateGroup;
}

export interface ActionButtonProps {
  id: string;
  onClick?: (id: string) => void;
  emailAddress?: string;
  name?: string;
}

interface UpdatePromise {
  newRow: GridRowModel<AssociateRow>;
  oldRow: GridRowModel<AssociateRow>;
  reject: (value: GridRowModel<AssociateRow>) => void;
  resolve: (value: GridRowModel<AssociateRow>) => void;
}

class InputError extends Error {}

const bulkMenuButtonId = "bulk-menu-button";
const bulkMenuId = "bulk-menu";

const AssociatesTable: React.FC<AssociatesTableProps> = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();

  const [
    displayAll,
    setDisplayAll,
    columnVisibilityModel,
    setColumnVisibilityModel,
  ] = useSmallScreenProperties(reducedAssociatesVisibilityModel);

  const [updatePromise, setUpdatePromiseArguments] = useState<UpdatePromise>();
  const [activateModalOpen, setActivateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [assignGroupModalOpen, setAssignGroupModalOpen] = useState(false);
  const [unassignGroupModalOpen, setUnassignGroupModalOpen] = useState(false);
  const [denyModalOpen, setDenyModalOpen] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [inputErrorModalOpen, setInputErrorModalOpen] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [queryOptions, setQueryOptions] = useOutletContext<AssociatesContext>();

  const [updateAssociate] = useUpdateAssociateMutation();
  const [activateAssociate] = useActivateAssociateMutation();
  const [activateAssociates] = useActivateAssociatesMutation();
  const [deleteAssociate] = useDeleteAssociateByIdMutation();
  const [deleteAssociates] = useDeleteAssociatesByIdsMutation();
  const [denyAssociate] = useDenyAssociateMutation();
  const [denyAssociates] = useDenyAssociatesMutation();
  const [assignGroup] = useAssignAssociateGroupMutation();
  const [unassignGroup] = useUnassignAssociateGroupMutation();

  const { data, isFetching, isLoading } = useGetAssociatesQuery(queryOptions);

  const { data: groups } = useGetAssociateGroupsQuery({
    organizationId: organization.publicId,
    sortOrder: "asc",
    sortField: "name",
  });

  const onDenyChange =
    (id: string) =>
    async (e: ChangeEvent<HTMLInputElement>, allowAccessChecked: boolean) => {
      await denyAssociate({
        publicId: id,
        deny: !allowAccessChecked,
        organizationId: organization.publicId,
      });
    };

  const specialCharactersValidator = validateSpecialCharacters("name");

  const processRowUpdate = (
    newRow: GridRowModel<AssociateRow>,
    oldRow: GridRowModel<AssociateRow>
  ) =>
    new Promise<GridRowModel<AssociateRow>>((resolve, reject) => {
      const validationResult = newRow.name
        ? specialCharactersValidator(newRow.name)
        : t("validation.name.blank");
      if (validationResult === true) {
        setInputError(false);
      } else {
        throw new InputError(validationResult);
      }
      setUpdatePromiseArguments({ resolve, reject, newRow, oldRow });
    });

  const onProcessRowUpdateError = (error: any) => {
    if (error instanceof InputError) {
      setInputError(true);
      setInputErrorMessage(error.message);
      setInputErrorModalOpen(true);
    }
  };

  const onDeleteClick = (id?: string) => {
    onBulkMenuClose();
    setDeleteModalOpen(true);
    if (id) {
      setSelectionModel([id]);
    } else {
      onBulkMenuClose();
    }
  };

  const onActivateClick = (id?: string) => {
    onBulkMenuClose();
    setActivateModalOpen(true);
    if (id) {
      setSelectionModel([id]);
    } else {
      onBulkMenuClose();
    }
  };

  const onAssignGroupClick = () => {
    onBulkMenuClose();
    setAssignGroupModalOpen(true);
  };

  const onUnassignGroupClick = () => {
    onBulkMenuClose();
    setUnassignGroupModalOpen(true);
  };

  const onConfirmDelete = () => {
    setDeleteModalOpen(false);
    try {
      if (selectionModel.length === 1) {
        deleteAssociate({
          id: `${selectionModel[0]}`,
          organizationId: organization.publicId,
        }).unwrap();
      } else if (selectionModel.length > 1) {
        deleteAssociates({
          ids: selectionModel,
          organizationId: organization.publicId,
        }).unwrap();
      }
    } catch (error: any) {
      enqueueSnackbar(t("delete.fail"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
    setSelectionModel([]);
  };

  const onConfirmUnassignGroup = () => {
    setUnassignGroupModalOpen(false);
    try {
      unassignGroup({
        ids: selectionModel as string[],
        organizationId: organization.publicId,
      }).unwrap();
      enqueueSnackbar(
        t("associates.unassignGroup.success", { count: selectionModel.length }),
        {
          variant: "success",
        }
      );
    } catch (error: any) {
      enqueueSnackbar(t("associates.unassignGroup.fail"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
    setSelectionModel([]);
  };

  const onCancelDialog = () => {
    setDeleteModalOpen(false);
  };

  const onCancelUnassignGroup = () => {
    setUnassignGroupModalOpen(false);
  };

  const onConfirmUpdate = async () => {
    const { newRow, oldRow, reject, resolve } = updatePromise!;
    newRow.name = newRow?.name?.trim();
    try {
      if (newRow.name !== oldRow.name) {
        await updateAssociate({
          associate: mapToEntity(newRow),
          organizationId: organization.publicId,
        }).unwrap();
      }

      if (newRow.group !== oldRow.group) {
        if (newRow.group) {
          await assignGroup({
            organizationId: organization.publicId,
            groupId: newRow.group.publicId,
            ids: [newRow.id],
          }).unwrap();
        } else {
          await unassignGroup({
            organizationId: organization.publicId,
            ids: [newRow.id],
          }).unwrap();
        }
      }

      resolve(newRow);
    } catch (error: any) {
      reject(oldRow);
      enqueueSnackbar(t("table.failure.update"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    } finally {
      setUpdatePromiseArguments(undefined);
    }
  };

  const onCancelUpdate = () => {
    const { oldRow, resolve } = updatePromise!;
    resolve(oldRow);
    setUpdatePromiseArguments(undefined);
  };

  const renderDenyToggle = (
    params: GridRenderCellParams<boolean, AssociateRow>
  ) => {
    const tooltip = t(
      params.value
        ? "associates.access.denied.tooltip"
        : "associates.access.allowed.tooltip"
    );
    return (
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <PrintableSwitch aria-checked={!params.value}>
          <Tooltip title={tooltip}>
            <Switch
              checked={!params.value}
              onChange={onDenyChange(params.row.id)}
            />
          </Tooltip>
        </PrintableSwitch>
      </Box>
    );
  };

  const getActions = useCallback(
    (params: GridRowParams) => RowActions(params.row.id, onDeleteClick),
    []
  );

  const onGroupSelected = async (group: AssociateGroup) => {
    setAssignGroupModalOpen(false);
    try {
      await assignGroup({
        organizationId: organization.publicId,
        groupId: group.publicId,
        ids: selectionModel as string[],
      }).unwrap();
      enqueueSnackbar(
        t("associates.group.assign.success", { count: selectionModel.length }),
        {
          variant: "success",
        }
      );
      setSelectionModel([]);
    } catch (error: any) {
      enqueueSnackbar(t("associates.group.assign.error"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
  };

  const onDenyAllClick = () => {
    onBulkMenuClose();
    setDenyModalOpen(true);
  };

  const onDenySelected = async (deny: boolean) => {
    setDenyModalOpen(false);
    try {
      await denyAssociates({
        organizationId: organization.publicId,
        ids: selectionModel as string[],
        deny: deny,
      }).unwrap();
      enqueueSnackbar(
        t("associates.deny.set.success", { count: selectionModel.length }),
        {
          variant: "success",
        }
      );
      setSelectionModel([]);
    } catch (error: any) {
      enqueueSnackbar(t("associates.deny.set.error"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
  };

  const onConfirmActivate = () => {
    setActivateModalOpen(false);
    try {
      if (selectionModel.length === 1) {
        activateAssociate({
          id: `${selectionModel[0]}`,
          organizationId: organization.publicId,
        }).unwrap();
      } else if (selectionModel.length > 1) {
        activateAssociates({
          ids: selectionModel,
          organizationId: organization.publicId,
        }).unwrap();
      }
    } catch (error: any) {
      enqueueSnackbar(t("associates.activation.failure"), {
        variant: "error",
        preventDuplicate: true,
        key: error.status,
      });
    }
    setSelectionModel([]);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const bulkMenuOpen = Boolean(anchorEl);
  const onBulkMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onBulkMenuClose = () => {
    setAnchorEl(undefined);
  };

  const groupOptions = useMemo(
    () => (groups ? mapGroup(groups?.content) : []),
    [groups]
  );

  const renderTableActions = () => {
    return (
      <>
        <Grow in={selectionModel.length > 0}>
          <Tooltip title={t("table.bulkActions")}>
            <IconButton
              id={bulkMenuButtonId}
              aria-controls={bulkMenuOpen ? bulkMenuId : undefined}
              aria-haspopup="true"
              aria-expanded={bulkMenuOpen ? "true" : undefined}
              onClick={onBulkMenuClick}
            >
              <BulkMenuIcon />
            </IconButton>
          </Tooltip>
        </Grow>
        <Menu
          id={bulkMenuId}
          anchorEl={anchorEl}
          open={bulkMenuOpen}
          onClose={onBulkMenuClose}
          MenuListProps={{
            "aria-labelledby": bulkMenuButtonId,
          }}
        >
          <MenuItem onClick={() => onActivateClick()}>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText>{t("associates.activation.activate")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={onDenyAllClick}>
            <ListItemIcon>
              <DenyAllIcon />
            </ListItemIcon>
            <ListItemText>{t("associates.access.set")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={onAssignGroupClick}>
            <ListItemIcon>
              <AssignGroupIcon />
            </ListItemIcon>
            <ListItemText>{t("associates.group.assign")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={onUnassignGroupClick}>
            <ListItemIcon>
              <UnassignGroupIcon />
            </ListItemIcon>
            <ListItemText>{t("bulkUnassignGroup")}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => onDeleteClick()}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>{t("bulkDelete")}</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  };

  const renderActivationStatus = ({
    row: { id, activated },
  }: GridCellParams<unknown, AssociateRow>) => {
    if (activated === "true") {
      return (
        <Chip
          label={t("associate.activatedStatus.true")}
          size="small"
          icon={<CheckIcon />}
        />
      );
    } else {
      return (
        <Tooltip title={t("associates.activation.title.single")}>
          <Button
            variant="contained"
            size="small"
            onClick={() => onActivateClick(id)}
            sx={{
              minWidth: 0,
              minHeight: 0,
            }}
          >
            {t("associates.activation.activate")}
          </Button>
        </Tooltip>
      );
    }
  };

  const columns: GridColumns = [
    {
      field: "name",
      headerName: t("associate.name"),
      flex: 1,
      minWidth: 100,
      editable: true,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
      renderEditCell: (props: GridRenderEditCellParams) => (
        <TableTextEditComponent renderParams={props} maxLength={255} />
      ),
    },
    {
      field: "emailAddress",
      headerName: t("associate.email"),
      flex: 1,
      minWidth: 100,
      filterOperators: getGridStringOperators().filter(
        (op) => op.value === "contains"
      ),
    },
    {
      field: "groupId",
      headerName: t("associates.group"),
      flex: 1,
      minWidth: 100,
      editable: true,
      filterOperators: getGridSingleSelectOperators().filter(
        (op) => op.value === "is"
      ),
      valueOptions: groupOptions,
      valueGetter: (params) => params.row.group,
      valueSetter: (params) => {
        const group = params.value;
        return { ...params.row, group };
      },
      valueFormatter: (params) => params.value?.name,
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <AssociateGroupSelector {...params} />;
      },
    },
    {
      field: "activated",
      headerName: t("associate.activated"),
      flex: 0.5,
      minWidth: 50,
      valueOptions: [
        { label: t("associate.activatedStatus.true"), value: true },
        { label: t("associate.activatedStatus.false"), value: false },
      ],
      filterOperators: getGridSingleSelectOperators().filter(
        (op) => op.value === "is"
      ),
      type: "singleSelect",
      renderCell: renderActivationStatus,
    },
    {
      field: "status",
      headerName: t("associates.last.evaluation.result"),
      flex: 0.6,
      editable: false,
      renderCell: renderStatusColumn,
      filterable: true,
      valueOptions: Object.values(ExternalAuthorizeEventResult),
      valueFormatter: ({ value }) =>
        t("associates.external.access.state." + value),
      filterOperators: getGridSingleSelectOperators().filter(
        (s) => s.value === "is"
      ),
      sortable: false,
    },
    {
      field: "deny",
      headerName: t("associates.allowAccess"),
      flex: 0.5,
      renderCell: renderDenyToggle,
      valueOptions: ["false", "true"],
      valueFormatter: ({ value }) =>
        value === "true"
          ? t("associates.allowAccess.denied")
          : t("associates.allowAccess.allowed"),
      filterOperators: getGridSingleSelectOperators().filter(
        (s) => s.value === "is"
      ),
    },
    {
      field: "actions",
      headerName: t("table.columns.actions"),
      type: "actions",
      getActions,
      renderHeader: renderTableActions,
    },
  ];

  const rows = useMemo(() => (data ? mapToGridRow(data?.content) : []), [data]);

  const localeText = getTableLocaleText();

  const onPageChange = (page: number) => {
    setQueryOptions({ ...queryOptions, page });
  };

  const onPageSizeChange = (size: number) => {
    setQueryOptions({ ...queryOptions, size });
  };

  const onSortModelChange = (sortModel: GridSortModel) => {
    const sort = sortModel.length === 0 ? undefined : sortModel[0];
    setQueryOptions({
      ...queryOptions,
      sortField: sort?.field as keyof Associate,
      sortOrder: sort?.sort,
    });
  };

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    const filter =
      filterModel.items.length === 0 ? undefined : filterModel.items[0];
    setQueryOptions({
      ...queryOptions,
      filterField: filter?.columnField as keyof Associate,
      filterOperator: filter?.operatorValue as Operator,
      filterValue: filter?.value,
    });
  };

  const sortModel = useMemo(() => {
    return queryOptions.sortField
      ? [
          {
            sort: queryOptions.sortOrder,
            field: queryOptions.sortField,
          },
        ]
      : [];
  }, [queryOptions.sortField, queryOptions.sortOrder]);

  const filterModel = useMemo(() => {
    const filter = queryOptions.filterField
      ? [
          {
            columnField: queryOptions.filterField,
            operatorValue: queryOptions.filterOperator,
            value: queryOptions.filterValue,
          },
        ]
      : [];
    return {
      items: filter,
    };
  }, [
    queryOptions.filterField,
    queryOptions.filterOperator,
    queryOptions.filterValue,
  ]);

  useEffect(() => {
    if (organization) {
      setQueryOptions({
        ...queryOptions,
        organizationId: organization.publicId,
      });
    }
  }, [organization]);

  return (
    <TableContentWrapper displayAll={displayAll}>
      <DataGrid
        localeText={localeText}
        editMode="row"
        rows={rows}
        rowCount={data?.totalElements || 0}
        columns={columns}
        page={queryOptions.page}
        onPageChange={onPageChange}
        pageSize={queryOptions.size}
        onPageSizeChange={onPageSizeChange}
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        sortingMode="server"
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        filterMode="server"
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar,
          LoadingOverlay: LinearProgress,
          Pagination,
          OpenFilterButtonIcon: SearchIcon,
        }}
        loading={isFetching || isLoading}
        disableSelectionOnClick
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={setSelectionModel}
        componentsProps={{
          ...tableComponentProps,
          toolbar: { displayAll, setDisplayAll },
        }}
        keepNonExistentRowsSelected
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
      />
      <DeleteModal
        isDialogOpened={deleteModalOpen}
        count={selectionModel.length}
        handleCancelDialog={onCancelDialog}
        handleDeleteDialog={onConfirmDelete}
      />
      <AssignGroupModal
        modalOpen={assignGroupModalOpen}
        onGroupSelected={onGroupSelected}
        onClose={() => setAssignGroupModalOpen(false)}
      />
      <UnassignGroupModal
        isDialogOpened={unassignGroupModalOpen}
        count={selectionModel.length}
        handleCancelDialog={onCancelUnassignGroup}
        handleConfirmDialog={onConfirmUnassignGroup}
      />
      <ConfirmUpdateModal
        open={!!updatePromise && !inputError}
        onClose={onCancelUpdate}
        onConfirm={onConfirmUpdate}
      />
      <InputErrorModal
        open={inputErrorModalOpen}
        onClose={() => setInputErrorModalOpen(false)}
        message={inputErrorMessage}
      />
      <DenyModal
        modalOpen={denyModalOpen}
        onDenySelected={onDenySelected}
        onClose={() => setDenyModalOpen(false)}
      />
      <ActivateModal
        isModalOpen={activateModalOpen}
        itemsCount={selectionModel.length}
        onConfirm={onConfirmActivate}
        onClose={() => setActivateModalOpen(false)}
      />
    </TableContentWrapper>
  );
};

export default AssociatesTable;
