import { ResponsivePie } from "@nivo/pie";
import { useTranslation } from "react-i18next";
import { byDenyTheme, useNivoTheme } from "../../../../common/theme";
import { CountByDeny } from "../../../../common/pagination";
import { Box } from "@mui/material";

interface AssociateByDenyChartDatum {
  id: string;
  label: string;
  value: number;
  deny: boolean;
}

const AssociatesByDeny = (props: { data?: CountByDeny }) => {
  const { t } = useTranslation();
  const titleAllowed = t("reports.organization.associates.deny.allowed");
  const titleDenied = t("reports.organization.associates.deny.denied");
  const countAllowed = props.data?.allowed ?? 0;
  const countDenied = props.data?.denied ?? 0;

  const chartData: AssociateByDenyChartDatum[] = [
    {
      id: titleAllowed,
      label: `${titleAllowed} (${countAllowed})`,
      value: countAllowed,
      deny: false,
    },
    {
      id: titleDenied,
      label: `${titleDenied} (${countDenied})`,
      value: countDenied,
      deny: true,
    },
  ];

  return (
    <>
      <Box textAlign={"center"}>
        {t("reports.organization.associates.deny.title")}
      </Box>
      <ResponsivePie
        margin={{ top: 10, right: 0, bottom: 80, left: 0 }}
        data={chartData}
        animate={true}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        activeInnerRadiusOffset={6}
        layers={["arcs", "legends"]}
        colors={byDenyTheme.palette.pie}
        theme={useNivoTheme()}
        legends={[
          {
            anchor: "bottom-left",
            direction: "column",
            symbolShape: "circle",
            itemWidth: 100,
            itemHeight: 20,
            translateY: 50,
            translateX: 30,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 0.5,
                },
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default AssociatesByDeny;
