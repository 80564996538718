import {
  Autocomplete,
  Box,
  InputAdornment,
  SvgIcon,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import {
  FunctionComponent,
  HTMLAttributes,
  SyntheticEvent,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CzechIcon } from "../../assets/flags/CZ.svg";
import { ReactComponent as EnglishIcon } from "../../assets/flags/EN.svg";

interface LangOptionType {
  code: string;
  label: string;
  icon: FunctionComponent;
}

const languages: LangOptionType[] = [
  { code: "en-GB", label: "English", icon: EnglishIcon },
  { code: "cs-CZ", label: "Čeština", icon: CzechIcon },
];

const renderLanguageOption = (
  props: HTMLAttributes<HTMLLIElement>,
  language: LangOptionType
) => (
  <Option {...props}>
    <SvgIcon component={language.icon} inheritViewBox />
    <Typography>{language.label}</Typography>
  </Option>
);

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const currentLang = useMemo(
    () =>
      languages.find((value) => value.code.includes(i18n.language)) ||
      languages[0],
    [i18n.language]
  );
  const onLanguageChange = (
    _: SyntheticEvent,
    value: LangOptionType | null
  ) => {
    i18n.changeLanguage(value?.code);
  };

  return (
    <Wrapper>
      <Autocomplete
        value={currentLang}
        size="small"
        options={languages}
        getOptionLabel={({ label }) => label}
        onChange={onLanguageChange}
        renderOption={renderLanguageOption}
        disableClearable
        renderInput={(params) => (
          <Input
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputIcon position="start">
                  <SvgIcon component={currentLang.icon} inheritViewBox />
                </InputIcon>
              ),
            }}
            label={t("language.search")}
          />
        )}
        clearText={t("autocomplete.clear")}
        noOptionsText={t("autocomplete.nooptions")}
        openText={t("autocomplete.open")}
        closeText={t("autocomplete.close")}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Box)(() => ({
  ".MuiAutocomplete-root": {
    marginLeft: "auto",
    width: "200px",
  },
}));

const Option = styled("li")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
}));

const Input = styled(TextField)(({ theme }) => ({
  ".MuiInputBase-input": {
    color: theme.palette.common.white,
  },
}));

const InputIcon = styled(InputAdornment)(({ theme }) => ({
  marginLeft: theme.spacing(1.25),
}));

export default LanguageSelector;
