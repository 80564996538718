import { Typography, Box, Link } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableContentWrapper, TablePageWrapper } from "../../common/styles";
import { dependenciesByLicenses, Dependency } from "./dependencies";
import { styled } from "@mui/system";
import LicenseModal from "./LicenseModal";

const Licenses = () => {
  const { t } = useTranslation();
  const [licenseModalOpen, setLicenseModalOpen] = useState(false);
  const [licenseName, setLicenseName] = useState<string>("");
  const [licenseText, setLicenseText] = useState<string>("");

  const displayLicense = (licenseName: string, licenseText: string) => {
    setLicenseName(licenseName);
    setLicenseText(licenseText);
    setLicenseModalOpen(true);
  };

  return (
    <TablePageWrapper>
      <Typography variant="h1">{t("header.help.licenses")}</Typography>
      <TableContentWrapper>
        {Object.entries(dependenciesByLicenses).map((entry) => {
          const [licenses, dependencies] = entry;
          return (
            <>
              <Typography variant="h2">{licenses}</Typography>
              {dependencies.map((dependency) => {
                return (
                  <DependencyComponent
                    key={dependency.name}
                    dependency={dependency}
                    displayLicense={displayLicense}
                  />
                );
              })}
            </>
          );
        })}
      </TableContentWrapper>
      <LicenseModal
        licenseName={licenseName}
        licenseText={licenseText}
        isOpen={licenseModalOpen}
        onClose={() => setLicenseModalOpen(false)}
      />
    </TablePageWrapper>
  );
};

interface DependencyComponentProps {
  dependency: Dependency;
  displayLicense: (licenseName: string, licenseText: string) => void;
}

const DependencyComponent: FC<DependencyComponentProps> = ({
  dependency,
  displayLicense,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Typography variant="h3">{dependency.name}</Typography>
      <Typography>{dependency.version}</Typography>
      {dependency.publisher && <Typography>{dependency.publisher}</Typography>}
      {dependency.copyright && <Typography>{dependency.copyright}</Typography>}
      {dependency.licenseText && (
        <Link
          component="button"
          variant="body1"
          onClick={() =>
            displayLicense(dependency.licenses, dependency.licenseText!!)
          }
        >
          {t("licenses.license")}
        </Link>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Box)(() => ({
  marginBottom: "2.5rem",
}));

export default Licenses;
