import { useTranslation } from "react-i18next";
import ChartViewWrapper from "./ChartViewWrapper";
import {
  useCountVehiclesByDenyQuery,
  useCountVehiclesByStateQuery,
  useCountVehiclesQuery,
} from "../../../redux/api/VehiclesApi";
import VehiclesByDeny from "./vehicles/VehiclesByDeny";
import { Grid, Typography } from "@mui/material";
import VehiclesByState from "./vehicles/VehiclesByState";
import { useGetVehiclesAccessStatisticsQuery } from "../../../redux/api/StatisticsApi";
import VehiclesByAccess from "./vehicles/VehiclesByAccess";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";

const VehiclesView = () => {
  const organization = useSelectedOrganization();
  const { data: dataAll } = useCountVehiclesQuery(organization.publicId);
  const { data: dataByDeny } = useCountVehiclesByDenyQuery(
    organization.publicId
  );
  const { data: dataByState } = useCountVehiclesByStateQuery(
    organization.publicId
  );
  const { data: dataByAccess } = useGetVehiclesAccessStatisticsQuery(
    organization.publicId
  );
  const { t } = useTranslation();
  const title = `${t("dashboard.vehicles.title")}: ${
    dataAll ? dataAll.count : "-"
  }`;

  return (
    <ChartViewWrapper title={title} align="center">
      {dataAll && dataAll?.count > 0 ? (
        <Grid container spacing={4} justifyContent="center">
          <Grid item lg={4} md={12} sx={{ height: 250 }}>
            <VehiclesByDeny data={dataByDeny} />
          </Grid>
          <Grid item lg={4} md={12} sx={{ height: 250 }}>
            <VehiclesByState data={dataByState} />
          </Grid>
          <Grid item lg={4} md={12} sx={{ height: 250 }}>
            <VehiclesByAccess data={dataByAccess} />
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h1" align="center">
          {t("dashboard.nodata")}
        </Typography>
      )}
    </ChartViewWrapper>
  );
};

export default VehiclesView;
