import { useParams } from "react-router-dom";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import { useGetOrganizationReportDetailQuery } from "../../../redux/api/ReportsApi";
import { Grid, styled, Typography } from "@mui/material";
import React from "react";
import { formatTimestamp } from "../../formatTimestamp";
import AssociatesReportComponent from "./associates/AssociatesReportComponent";
import VehiclesReportComponent from "./vehicles/VehiclesReportComponent";
import AuthorizationReportComponent from "./authorization/AuthorizationReportComponent";
import {
  CountByActivated,
  CountByDeny,
  CountByState,
} from "../../../common/pagination";
import AuthorizationBySuccess, {
  CountByAuthorizationSuccess,
} from "./authorization/AuthorizationBySuccess";
import VehiclesByState from "./vehicles/VehiclesByState";
import VehiclesByDeny from "./vehicles/VehiclesByDeny";
import AssociatesByActivated from "./associates/AssociatesByActivated";
import AssociatesByDeny from "./associates/AssociatesByDeny";
import { useTranslation } from "react-i18next";

const ReportDetail = () => {
  const { t } = useTranslation();
  const params = useParams();
  const organization = useSelectedOrganization();
  const { data } = useGetOrganizationReportDetailQuery({
    id: params.reportId as string,
    organizationId: organization.publicId,
  });

  if (data) {
    const dataAssociatesByDeny: CountByDeny = {
      allowed: data.associates.allowed,
      denied: data.associates.denied,
    };
    const dataAssociatesByActivated: CountByActivated = {
      active: data.associates.active,
      auto: data.associates.automaticallyCreated,
    };

    const dataVehiclesByDeny: CountByDeny = {
      allowed: data.vehicles.allowed,
      denied: data.vehicles.denied,
    };
    const dataVehiclesByState: CountByState = {
      active: data.vehicles.active,
      declined: data.vehicles.declined,
      pending: data.vehicles.pendingNoTC,
    };

    const dataAuthorizationBySuccess: CountByAuthorizationSuccess = {
      authorized: data.externalAuthorization.authorized,
      rejected: data.externalAuthorization.rejected,
    };

    return (
      <>
        <Typography variant="h2">{formatTimestamp(data.created)}</Typography>
        <Section>
          <Typography variant="h3">
            {t("reports.organization.associates.title")}
          </Typography>
          <Content>
            <Grid container spacing={4}>
              <Grid item lg={4} xs={12}>
                <AssociatesReportComponent data={data.associates} />
              </Grid>
              {data.associates.total > 0 && (
                <>
                  <Grid item lg={4} sm={6} xs={12} sx={{ height: 250 }}>
                    {data.associates.total > 0 && (
                      <AssociatesByDeny data={dataAssociatesByDeny} />
                    )}
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12} sx={{ height: 250 }}>
                    {data.associates.total > 0 && (
                      <AssociatesByActivated data={dataAssociatesByActivated} />
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Content>
        </Section>
        <Section>
          <Typography variant="h3">
            {t("reports.organization.vehicles.title")}
          </Typography>
          <Content>
            <Grid container spacing={4}>
              <Grid item lg={4} xs={12}>
                <VehiclesReportComponent data={data.vehicles} />
              </Grid>
              {data.vehicles.total > 0 && (
                <>
                  <Grid item lg={4} sm={6} xs={12} sx={{ height: 250 }}>
                    <VehiclesByDeny data={dataVehiclesByDeny} />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12} sx={{ height: 250 }}>
                    <VehiclesByState data={dataVehiclesByState} />
                  </Grid>
                </>
              )}
            </Grid>
          </Content>
        </Section>
        <Section>
          <Typography variant="h3">
            {t("reports.organization.authorization.title")}
          </Typography>
          <Content>
            <Grid container spacing={4}>
              <Grid item lg={4} xs={12}>
                <AuthorizationReportComponent
                  data={data.externalAuthorization}
                />
              </Grid>
              <Grid item lg={4} sm={6} xs={12} sx={{ height: 250 }}>
                {data.externalAuthorization.total > 0 && (
                  <AuthorizationBySuccess data={dataAuthorizationBySuccess} />
                )}
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                {" "}
              </Grid>
            </Grid>
          </Content>
        </Section>
      </>
    );
  } else {
    return <></>;
  }
};

const Section = styled("div")(() => ({
  marginBlock: "3.5rem",
}));

const Content = styled("div")(() => ({
  marginBlock: "2rem",
}));

export default ReportDetail;
