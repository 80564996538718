import React, { useCallback, useEffect, useMemo } from "react";
import { Operator, Page } from "../../../common/pagination";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  getGridDateOperators,
  GridColumns,
  GridFilterModel,
  GridRowParams,
  GridRowsProp,
  GridSortModel,
} from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { tableComponentProps } from "../../../common/styles";
import Pagination from "../../pagination/Pagination";
import { getTableLocaleText } from "../../table/getTableLocaleText";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import {
  OrganizationReportListing,
  ReportPeriod,
  useGetOrganizationReportsQuery,
} from "../../../redux/api/ReportsApi";
import { useOutletContext } from "react-router-dom";
import { OrganizationReportsContext } from "./ReportsComponent";
import Toolbar from "./Toolbar";
import { formatTimestamp } from "../../formatTimestamp";
import { RowActions } from "./RowActions";

interface ReportsTableProps {
  reports?: Page<OrganizationReportListing[]>;
}

interface OrganizationReportRow {
  id: string;
  created: number;
  period: ReportPeriod;
}

const ReportsTable: React.FC<ReportsTableProps> = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();
  const [queryOptions, setQueryOptions] =
    useOutletContext<OrganizationReportsContext>();

  const { data, isFetching, isLoading } =
    useGetOrganizationReportsQuery(queryOptions);

  const mapDataToTable = (
    reports: OrganizationReportListing[]
  ): GridRowsProp<OrganizationReportRow> => {
    return reports.map((report) => {
      return {
        ...report,
      };
    });
  };

  const getActions = useCallback(
    (params: GridRowParams) => RowActions(params.row.id),
    []
  );

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "created",
        type: "date",
        headerName: t("reports.organization.created"),
        flex: 1,
        valueFormatter: (params) => formatTimestamp(params.value),
        filterOperators: getGridDateOperators(false).filter(
          (op) => op.value === "is"
        ),
      },
      {
        field: "period",
        type: "singleSelect",
        headerName: t("reports.organization.period"),
        minWidth: 100,
        filterable: false,
        sortable: false,
        renderCell: (params) =>
          t(`reports.organization.period.${params.value}`),
      },
      {
        field: "actions",
        headerName: t("table.columns.actions"),
        type: "actions",
        getActions,
        renderHeader: () => <></>,
      },
    ],
    [t]
  );

  const rows = useMemo(() => {
    return data ? mapDataToTable(data?.content) : [];
  }, [data]);

  const localeText = getTableLocaleText();

  const onPageChange = (page: number) => {
    setQueryOptions({ ...queryOptions, page });
  };

  const onPageSizeChange = (size: number) => {
    setQueryOptions({ ...queryOptions, size });
  };

  const onSortModelChange = (sortModel: GridSortModel) => {
    const sort = sortModel.length === 0 ? undefined : sortModel[0];
    setQueryOptions({
      ...queryOptions,
      sortField: sort?.field as keyof OrganizationReportListing,
      sortOrder: sort?.sort,
    });
  };

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    const filter =
      filterModel.items.length === 0 ? undefined : filterModel.items[0];
    setQueryOptions({
      ...queryOptions,
      filterField: filter?.columnField as keyof OrganizationReportListing,
      filterOperator: filter?.operatorValue as Operator,
      filterValue: filter?.value,
    });
  };

  const sortModel = useMemo(() => {
    return queryOptions.sortField
      ? [
          {
            sort: queryOptions.sortOrder,
            field: queryOptions.sortField,
          },
        ]
      : [];
  }, [queryOptions.sortField, queryOptions.sortOrder]);

  const filterModel = useMemo(() => {
    const filter = queryOptions.filterField
      ? [
          {
            columnField: queryOptions.filterField,
            operatorValue: queryOptions.filterOperator,
            value: queryOptions.filterValue,
          },
        ]
      : [];
    return {
      items: filter,
    };
  }, [
    queryOptions.filterField,
    queryOptions.filterOperator,
    queryOptions.filterValue,
  ]);

  useEffect(() => {
    if (organization) {
      setQueryOptions({
        ...queryOptions,
        organizationId: organization.publicId,
      });
    }
  }, [organization]);

  return (
    <>
      <DataGrid
        autoHeight={true}
        localeText={localeText}
        rows={rows}
        rowCount={data?.totalElements || 0}
        columns={columns}
        page={queryOptions.page}
        onPageChange={onPageChange}
        pageSize={queryOptions.size}
        onPageSizeChange={onPageSizeChange}
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        sortingMode="server"
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        filterMode="server"
        components={{
          Toolbar,
          LoadingOverlay: LinearProgress,
          Pagination,
          OpenFilterButtonIcon: SearchIcon,
        }}
        loading={isFetching || isLoading}
        disableSelectionOnClick
        componentsProps={tableComponentProps}
      />
    </>
  );
};

export default ReportsTable;
