import { styled } from "@mui/system";
import { Stack, Tooltip } from "@mui/material";
import Link from "@mui/material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useTranslation } from "react-i18next";
import { resolveBranding } from "../../common/branding";
import { useMemo } from "react";

export function SocialMedia() {
  const { t, i18n } = useTranslation();
  const branding = resolveBranding();
  const contacts = useMemo(
    () => branding.contacts(i18n.language),
    [i18n.language]
  );

  return (
    <Stack direction="row" spacing={1}>
      <SocialLink href={contacts.facebook}>
        <Tooltip title={t("footer.facebook.tooltip")}>
          <FacebookIcon />
        </Tooltip>
      </SocialLink>
      <SocialLink href={contacts.twitter}>
        <Tooltip title={t("footer.twitter.tooltip")}>
          <TwitterIcon />
        </Tooltip>
      </SocialLink>
      <SocialLink href={contacts.youtube}>
        <Tooltip title={t("footer.youtube.tooltip")}>
          <YouTubeIcon />
        </Tooltip>
      </SocialLink>
      <SocialLink href={contacts.instagram}>
        <Tooltip title={t("footer.instagram.tooltip")}>
          <InstagramIcon />
        </Tooltip>
      </SocialLink>
    </Stack>
  );
}

const SocialLink = styled((props: any) => (
  <Link target="_blank" rel="noreferrer" {...props} />
))(() => ({}));
