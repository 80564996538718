import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import useFileUpload from "../../../hooks/useFileUpload";
import {
  useImportVehiclesMutation,
  VehicleValidationErrors,
} from "../../../redux/api/VehiclesApi";
import * as React from "react";
import ImportErrorDialog from "../../modal/ImportErrorDialog";
import { useSelectedOrganization } from "../../../hooks/useSelectedOrganization";
import UploadTooltip from "../../associates/UploadTooltip";

const UploadButton = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [importVehicles, { isLoading }] = useImportVehiclesMutation();
  const organization = useSelectedOrganization();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [validationErrors, setValidationErrors] =
    React.useState<VehicleValidationErrors>({});

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const title = t("upload.tooltip");

  const onFileSelected = async (files: File[] | FileList) => {
    try {
      const vehicles = await importVehicles({
        file: files[0],
        organizationId: organization.publicId,
      }).unwrap();
      enqueueSnackbar(
        t("upload.successful", {
          count: vehicles.length,
        }),
        {
          variant: "success",
        }
      );
    } catch (err: any) {
      let message = "upload.failed";
      if (err.data?.message) {
        if (err.data?.validationErrors) {
          const errors: VehicleValidationErrors = err.data?.validationErrors;
          setValidationErrors(errors);
          setModalOpen(true);
        }
        message = err.data?.message;
      }
      enqueueSnackbar(t(message), {
        variant: "error",
        key: err.status,
        preventDuplicate: true,
      });
    }
  };

  const { openDialog } = useFileUpload({
    inputRef: fileInputRef,
    onFileSelected,
  });

  return (
    <div id="upload-vehicles-button">
      <UploadTooltip>
        <LoadingButton
          variant="outlined"
          size="medium"
          onClick={openDialog}
          startIcon={<FileUploadIcon />}
          loading={isLoading}
          loadingPosition="start"
          color="secondary"
        >
          {title}
        </LoadingButton>
      </UploadTooltip>
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv,.ldif"
        multiple={false}
        hidden
      />
      <ImportErrorDialog
        onClose={handleModalClose}
        open={modalOpen}
        entries={validationErrors}
      />
    </div>
  );
};

export default UploadButton;
